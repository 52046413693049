// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IItem,
    ICreateItemDTO,
    ILinkCounts,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
} from '@data/schema';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ItemService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IItem[]> {
        return this.apiService.get<{ data: IItem[] }>('/items/all').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IItem>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IItem[]; totalItems: number }>('/items', params).pipe(
            map((res) => ({
                data: !res.data[0]?.['data'].length ? [] : res.data[0]?.['data'],
                totalItems: res.data[0]?.['data'].length,
                totalFilteredItems: !res.data[0]?.['data'].length ? 0 : res.data[0]?.['total'],
            })),
        );
    }

    getById(id: string): Observable<IItem> {
        return this.apiService.get<{ data: IItem }>(`/items/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateItemDTO): Observable<IItem> {
        return this.apiService.post<{ data: IItem }, ICreateItemDTO>(`/items`, context).pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateItemDTO): Observable<IItem> {
        return this.apiService
            .put<{ data: IItem }, ICreateItemDTO>(`/items/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IItem }>(`/items/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IItem[] }, { ids: string[] }>(`/items`, { ids });
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService.get<{ data: ILinkCounts }>(`/items/${id}/linkCounts`).pipe(map((res) => res.data));
    }

    getByCompanyIdNType(id: string, type: string): Observable<IItem[]> {
        return this.apiService
            .get<{ data: IItem[] }>(`/items/company/${id}/companyType/${type}`)
            .pipe(map((res) => res.data));
    }

    getByCompanyIdNTypeSSR(
        id: string,
        type: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IItem>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IItem[]; totalItems: number }>(`/items/company/${id}/companyType/${type}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByIssueId(id: string): Observable<IItem[]> {
        return this.apiService.get<{ data: IItem[] }>(`/items/issue/${id}`).pipe(map((res) => res.data));
    }

    getByIssueIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IItem>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IItem[]; totalItems: number }>(`/items/issue/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByServiceId(id: string): Observable<IItem[]> {
        return this.apiService.get<{ data: IItem[] }>(`/items/service/${id}`).pipe(map((res) => res.data));
    }

    getByServiceIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IItem>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IItem[]; totalItems: number }>(`/items/service/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    createWithIssueLink(id: string, context: ICreateItemDTO): Observable<IItem> {
        return this.apiService
            .post<{ data: IItem }, ICreateItemDTO>(`/items/issue/${id}`, context)
            .pipe(map((res) => res.data));
    }

    createWithServiceLink(id: string, context: ICreateItemDTO): Observable<IItem> {
        return this.apiService
            .post<{ data: IItem }, ICreateItemDTO>(`/items/service/${id}`, context)
            .pipe(map((res) => res.data));
    }

    getByContractId(contractId: string): Observable<IItem[]> {
        return this.apiService.get<{ data: IItem[] }>(`/items/contract/${contractId}`).pipe(map((res) => res.data));
    }

    getByContractIdSSR(
        contractId: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IItem>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IItem[]; totalItems: number }>(`/items/contract/${contractId}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    createWithContractLink(contractId: string, context: ICreateItemDTO): Observable<IItem> {
        return this.apiService
            .post<{ data: IItem }, ICreateItemDTO>(`/items/contract/${contractId}`, context)
            .pipe(map((res) => res.data));
    }

    getLinkStatus(ids: string[]): Observable<string> {
        let params = new HttpParams();

        ids.forEach((id: string) => {
            params = params.append(`Ids`, id);
        });

        return this.apiService
            .get<{ data: string }>(`/linkValidation/Items/linkStatus`, params)
            .pipe(map((res) => res.data));
    }
    unlinkFromCompany(unlinkObject): Observable<IItem> {
        return this.apiService
            .deleteBatch<{ data: IItem }, ICreateItemDTO>(`/items/company/unlink`, unlinkObject)
            .pipe(map((res) => res.data));
    }
}
