// schemas
import { ICheckListItem } from '@data/schema';

export class GetCheckList {
    static readonly type = '[CHECK-LIST] GET CHECK LIST';
    constructor(public id: string) {}
}

export class CreateCheckListItem {
    static readonly type = '[CHECK-LIST] CREATE CHECK-LIST-ITEM';
    constructor(public payload: ICheckListItem) {}
}

export class CreateCheckListTemplate {
    static readonly type = '[CHECK-LIST] CREATE CHECK-LIST-TEMPLATE';
    constructor(public name: string) {}
}

export class UpdateCheckListTemplate {
    static readonly type = '[CHECK-LIST] UPDATE CHECK-LIST-TEMPLATE';
    constructor(public id: string, public name: string) {}
}

export class UpdateCheckListLayout {
    static readonly type = '[CHECK-LIST] UPDATE CHECK-LIST LAYOUT';
    constructor(public payload: ICheckListItem[]) {}
}

export class UpdateCheckListItem {
    static readonly type = '[CHECK-LIST] UPDATE CHECK-LIST-ITEM';
    constructor(public index: number | string, public payload: ICheckListItem) {}
}

export class DeleteCheckListTemplate {
    static readonly type = '[CHECK-LIST] DELETE CHECK-LIST-TEMPLATE';
    constructor(public id: string) {}
}

export class RemoveCheckListItem {
    static readonly type = '[CHECK-LIST] REMOVE CHECK-LIST-ITEM';
    constructor(public index: number | string) {}
}

export class ResetCheckList {
    static readonly type = '[CHECK-LIST] RESET CHECK-LIST';
    constructor() {}
}
