// core
import { Injectable } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { of } from 'rxjs';

// actions
import {
    CreateCompany,
    CreateNoteForCompany,
    DeleteCompanies,
    DeleteCompany,
    GetCompanies,
    GetCompany,
    GetLinkCountsForCompany,
    GetLinkedDataForCompany,
    GetManufacturers,
    GetNotesForCompany,
    GetTableLayoutForCompany,
    GetSuppliers,
    UpdateCompany,
    UpdateTableLayoutForCompany,
    CreateLinkedItemForCompany,
    UnlinkLinkedItemsForCompany,
    ResetCompany,
    ResetNotesCompany,
    GetAllCompanies,
    GetAllIsTenantCompanies,
} from './company.actions';

// services
import {
    CompanyService,
    ContactService,
    ContractService,
    FileService,
    HistoryService,
    IssueService,
    ItemService,
    TableLayoutService,
} from '@data/service';

// schemas
import { ILinkModel } from '@data/schema';

// states
import { AuthState, CompanyStateModel, LinkedItemsStateModel } from '@data/state';

// enums
import { CompanyDataTableCellType, DataTableCellType, LinkedEntityType } from '@data/enum';

@State<CompanyStateModel>({
    name: 'companies',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        { data: 'companyName', title: 'Company Name', type: CompanyDataTableCellType.NAME_CELL },
                        { data: 'address1', title: 'Address 1' },
                        { data: 'address2', title: 'Address 2' },
                        { data: 'cityOrTown', title: 'City/Town' },
                        { data: 'stateOrRegion', title: 'State/Region' },
                        { data: 'country', title: 'Country' },
                    ],
                },
                isDefault: true,
            },
        },
        linkCounts: null,
        linkedItems: null,
    },
})
@Injectable()
export class CompanyState {
    constructor(
        private store: Store,
        private companyService: CompanyService,
        private contactService: ContactService,
        private contractService: ContractService,
        private itemService: ItemService,
        private issueService: IssueService,
        private historyService: HistoryService,
        private tableLayoutService: TableLayoutService,
        private fileService: FileService,
    ) {}

    @Selector()
    static getCompanyList(state: CompanyStateModel) {
        return state.items.data;
    }

    @Selector()
    static getCompanyListLoading(state: CompanyStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentCompany(state: CompanyStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getManufacturersList(state: CompanyStateModel) {
        return state.manufacturers;
    }

    @Selector()
    static getSuppliersList(state: CompanyStateModel) {
        return state.suppliers;
    }

    @Selector()
    static getLinkCounts(state: CompanyStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getLinkedData(state: CompanyStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.data;
        };
    }

    @Selector()
    static getLinkedDataLoading(state: CompanyStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.loading;
        };
    }

    @Selector()
    static getTableLayout(state: CompanyStateModel): DataTables.ColumnSettings[] {
        return state.items?.tableLayout?.data?.layout;
    }

    @Selector()
    static getNotes(state: CompanyStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: CompanyStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetCompanies)
    getCompanies({ getState, patchState }: StateContext<CompanyStateModel>) {
        const state = getState();

        return this.companyService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetAllCompanies)
    getAllCompanies({ getState, patchState }: StateContext<CompanyStateModel>) {
        const state = getState();

        return this.companyService.getAll().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetAllIsTenantCompanies)
    GetAllIsTenantCompanies({ getState, patchState }: StateContext<CompanyStateModel>) {
        const state = getState();

        return this.companyService.getAllIsTenant().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetCompany)
    getCompany({ getState, setState, patchState }: StateContext<CompanyStateModel>, { id }: GetCompany) {
        this.store.dispatch(new ResetCompany());
        this.resetLinkedData({ patchState });
        const state = getState();

        return this.companyService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetManufacturers)
    getManufacturers({ patchState }: StateContext<CompanyStateModel>) {
        return this.companyService.getManufacturers().pipe(
            tap((response) => {
                patchState({
                    manufacturers: response,
                });
            }),
        );
    }

    @Action(GetSuppliers)
    GetSuppliers({ patchState }: StateContext<CompanyStateModel>) {
        return this.companyService.getSuppliers().pipe(
            tap((response) => {
                patchState({
                    suppliers: response,
                });
            }),
        );
    }

    @Action(GetLinkCountsForCompany)
    GetLinkCountsForCompany({ patchState }: StateContext<CompanyStateModel>, { id }: GetLinkCountsForCompany) {
        return this.companyService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetLinkedDataForCompany)
    GetLinkedDataForCompany(
        { getState, patchState }: StateContext<CompanyStateModel>,
        { id, serviceType, dataType, itemType }: GetLinkedDataForCompany,
    ) {
        const state = getState();

        if (state?.linkedItems?.[dataType]?.retrievedAt) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                [dataType]: {
                    ...state.linkedItems?.[dataType],
                    loading: true,
                },
            },
        });

        return (
            itemType
                ? this?.[serviceType + 'Service'].getByCompanyIdNType(id, itemType)
                : this?.[serviceType + 'Service'].getByCompanyId(id)
        ).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        [dataType]: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetNotesForCompany)
    GetNotesForCompany(
        { getState, patchState }: StateContext<CompanyStateModel>,
        { id, skip, limit }: GetNotesForCompany,
    ) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetTableLayoutForCompany)
    GetTableLayoutForCompany(
        { getState, patchState }: StateContext<CompanyStateModel>,
        { page }: GetTableLayoutForCompany,
    ) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                {
                                    data: 'companyName',
                                    title: 'Company Name',
                                    type: CompanyDataTableCellType.NAME_CELL,
                                },
                                { data: 'address1', title: 'Address 1' },
                                { data: 'address2', title: 'Address 2' },
                                { data: 'cityOrTown', title: 'City/Town' },
                                { data: 'stateOrRegion', title: 'State/Region' },
                                { data: 'country', title: 'Country' },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(CreateLinkedItemForCompany)
    CreateLinkedItemForCompany(
        { getState, patchState }: StateContext<CompanyStateModel>,
        { id, payload, serviceType, dataType, countType }: CreateLinkedItemForCompany,
    ) {
        const state = getState();

        return this?.[serviceType + 'Service'].create(payload).pipe(
            tap((response: any) => {
                patchState({
                    linkCounts: {
                        ...state.linkCounts,
                        [countType]: state.linkCounts?.[countType] + 1,
                    },
                });
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(CreateNoteForCompany)
    createNoteForCompany({ getState, patchState }: StateContext<CompanyStateModel>, { payload }: CreateNoteForCompany) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateCompany)
    createCompany({ getState, patchState }: StateContext<CompanyStateModel>, { payload, files }: CreateCompany) {
        return this.companyService.create(payload).pipe(
            switchMap((response) => {
                if (files) {
                    return this.fileService.companyLogoUpdate(response._id, files);
                }
                return of(response);
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateCompany)
    updateCompany(
        { getState, setState, patchState }: StateContext<CompanyStateModel>,
        { id, payload, files }: UpdateCompany,
    ) {
        return this.companyService.edit(id, payload).pipe(
            switchMap((response) => {
                if (files) {
                    return this.fileService.companyLogoUpdate(response._id, files);
                }
                return of(response);
            }),
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });

                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                });

                this.store.dispatch(new ResetNotesCompany());
            }),
        );
    }

    @Action(UpdateTableLayoutForCompany)
    updateTableLayoutForCompany(
        { getState, patchState }: StateContext<CompanyStateModel>,
        { payload, page }: UpdateTableLayoutForCompany,
    ) {
        const state = getState();
        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(DeleteCompany)
    deleteCompany({ getState, setState }: StateContext<CompanyStateModel>, { id }: DeleteCompany) {
        return this.companyService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteCompanies)
    deleteCompanies({ getState, setState, patchState }: StateContext<CompanyStateModel>, { ids }: DeleteCompanies) {
        return this.companyService.deleteBatch(ids);
    }

    @Action(UnlinkLinkedItemsForCompany)
    unlinkItemsForCompany(
        { getState, setState, patchState }: StateContext<CompanyStateModel>,
        { ids, serviceType, dataType, countType }: UnlinkLinkedItemsForCompany,
    ) {
        const state = getState();

        const unlinkObject = {
            ids: ids,
            dataType: dataType,
        };
        return this?.[serviceType + 'Service'].unlinkFromCompany(unlinkObject).pipe(
            tap(() => {
                setState({
                    ...state,
                    linkCounts: {
                        ...state.linkCounts,
                        [countType]: state.linkCounts?.[countType] - ids.length,
                    },
                });
            }),
        );
    }

    @Action(ResetCompany)
    resetCompany({ getState, patchState }: StateContext<CompanyStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    @Action(ResetNotesCompany)
    resetNotes({ getState, patchState }: StateContext<CompanyStateModel>) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    data: [],
                    loading: true,
                },
            },
        });
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
            linkedItems: null,
        });
    }
}
