<div class="container-fluid bg-white nav-web px-3 position-sticky">
    <div class="row d-flex justify-content-between align-items-center py-2">
        <div class="col-3 col-md-1 px-2 pe-md-0">
            <div class="sidebar-toggle">
                <a class="cursor-pointer d-none d-lg-block fs-26 ms-md-2" (click)="onNavToggleClick()">
                    <i class="bi bi-list"></i>
                </a>
                <img
                    *ngIf="(tenantId$ | async) === null"
                    src="/assets/img/common/favicon.ico"
                    width="32px"
                    alt=""
                    class="cursor-pointer d-lg-none d-block ms-1"
                    (click)="onNavToggleClick()"
                />
                <div *ngIf="logoUrl">
                    <img
                        *ngIf="tenantId$ | async"
                        alt=""
                        width="32px"
                        class="cursor-pointer d-lg-none d-block ms-1"
                        [src]="logoUrl"
                        (click)="onNavToggleClick()"
                    />
                </div>
            </div>
        </div>
        <div class="col col-md-4 col-lg-3 p-0">
            <app-global-search
                [list$]="list$"
                [labelField]="'name'"
                [isLoading]="isSearchDataLoading"
                [searchInput$]="searchInput$"
                [customClasses]="'border ng-custom'"
                [minLengthTerm]="minLengthTerm"
                [placeholder]="'    Search'"
                [groupBy]="'type'"
                (onTypeheadClear)="onSearchBoxClear()"
            >
                <!-- Option group list override -->
                <ng-template appTypeheadInputOptionGroupTmp let-item="item" let-index="index">
                    <div class="row px-2 my-1" [ngSwitch]="item.type">
                        <p class="text-primary m-0 fs-16 fw-medium" *ngSwitchCase="'Company'">{{ item.type }}</p>
                        <p class="text-warning m-0 fs-16 fw-medium" *ngSwitchCase="'Contact'">{{ item.type }}</p>
                    </div>
                </ng-template>
                <!-- //. Option group list override -->

                <!-- Option list override -->
                <ng-template appTypeheadInputOptionTmp let-item="item" let-index="index" let-search="search">
                    <div>
                        <div class="row my-1" [ngOptionHighlight]="search">
                            <div class="col align-self-center" [ngSwitch]="item.type">
                                <p class="text-muted m-0 fs-14" *ngSwitchCase="'Company'">
                                    <i class="bi bi-briefcase me-2 text-primary option-icon"></i>{{ item.name }}
                                </p>
                                <p class="text-muted m-0 fs-14" *ngSwitchCase="'Contact'">
                                    <i class="bi bi-file-earmark-medical me-2 text-warning option-icon"></i
                                    >{{ item.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- //. Option list override -->
            </app-global-search>
        </div>

        <div class="col-2 col-sm-1 col-md me-md-2 ms-0 ms-sm-4 ms-md-0">
            <div class="d-flex align-items-center justify-content-end">
                <div class="btn-group ms-3 d-none d-md-block" dropdown container="body">
                    <button class="btn btn-transparent fs-14" dropdownToggle>
                        <i class="bi bi-info-circle me-2"></i>About
                    </button>

                    <ul *dropdownMenu class="dropdown-menu about dropdown-menu-right" role="menu">
                        <li role="menuitem">
                            <a class="dropdown-item cursor-pointer" [attr.href]="null"> About </a>
                            <a class="dropdown-item cursor-pointer" [attr.href]="null"> Privacy </a>
                            <a class="dropdown-item cursor-pointer" [attr.href]="null"> T&C </a>
                            <a class="dropdown-item cursor-pointer" [attr.href]="null"> Purchase </a>
                            <a class="dropdown-item cursor-pointer" [attr.href]="null"> Support </a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group ms-3" dropdown container="body" *ngIf="currentUser$ | async as user">
                    <ngx-avatars
                        [name]="user?.user?.firstName + ' ' + user?.user?.lastName"
                        [initialsSize]="2"
                        size="32"
                        src="{{ avatarUrl$ | async }}"
                        class="cursor-pointer"
                        dropdownToggle
                    ></ngx-avatars>

                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right profile p-4" role="menu">
                        <div class="row">
                            <div class="col-8">
                                <p class="fs-24 fw-medium mb-0">
                                    {{ user?.user?.firstName }} {{ user?.user?.lastName }}
                                </p>
                            </div>
                            <div class="col-4">
                                <ngx-avatars
                                    [name]="user?.user?.firstName + ' ' + user?.user?.lastName"
                                    [initialsSize]="2"
                                    size="32"
                                    class="cursor-pointer float-end"
                                ></ngx-avatars>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="fs-16 mb-0">{{ user?.user?.email }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="fs-16 fw-medium text-capitalize">{{ separateRole(user?.user?.role) }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button
                                    *ngIf="
                                        (userRole$ | async) !== userRoleType.TENANT_ADMIN &&
                                        (userRole$ | async) !== userRoleType.TENANT_VIEWER
                                    "
                                    class="btn btn-primary btn-sm me-3"
                                    [routerLink]="'/auth/tenant'"
                                >
                                    {{ tenantId === null ? 'Visit Company' : 'Change Company' }}
                                </button>
                                <button class="btn btn-primary btn-sm" [routerLink]="'/profile'">View Profile</button>
                                <button class="ms-3 btn btn-outline-danger btn-sm" (click)="logout()">
                                    <i class="bi bi-box-arrow-in-left me-2"></i>Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="off-overlayy d-lg-none d-block"
        [class.d-block]="layoutService.isSidebarExpanded$ | async"
        [class.d-none]="(layoutService.isSidebarExpanded$ | async) === false"
        (click)="onOverlayClose()"
    ></div>
</div>
