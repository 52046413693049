// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, skipWhile, Subject, switchMap, take, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import {
    CompanyState,
    CreateEquipmentCategory,
    CreateEquipmentType,
    CreateItem,
    CreateLinkedItemForCompany,
    CreateLinkedItemForContract,
    CreateLinkedItemForIssue,
    CreateLinkedItemForService,
    CreateLocation,
    EquipmentCategoryState,
    EquipmentTypeState,
    GetCompanies,
    GetEquipmentCategories,
    GetEquipmentTypes,
    GetLocations,
    GetManufacturers,
    GetSuppliers,
    LocationState,
} from '@data/state';

// schemas
import { IButtonCustomClass, ICompany, IEquipmentCategory, IEquipmentType, ILocation } from '@data/schema';

// services
import { DataTableService, SweetAlertService } from '@app/service';
import { SequenceService } from '@data/service';

// enums
import { LinkedCountType, LinkedEntityType, ServiceType } from '@data/enum';

@Component({
    selector: 'app-item-create-modal',
    templateUrl: './item-create-modal.component.html',
    styleUrls: ['./item-create-modal.component.scss'],
})
export class ItemCreateModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany[]>;
    @Select(CompanyState.getManufacturersList) manufacturers$: Observable<ICompany[]>;
    @Select(CompanyState.getSuppliersList) suppliers$: Observable<ICompany[]>;
    @Select(EquipmentCategoryState.getEquipmentCategoryList) equipmentCategories$: Observable<IEquipmentCategory[]>;
    @Select(EquipmentTypeState.getEquipmentTypeList) equipmentTypes$: Observable<IEquipmentType[]>;
    @Select(LocationState.getLocationList) location$: Observable<ILocation[]>;
    equipmentCategoriesSource$: Observable<IEquipmentCategory[]>;
    equipmentTypesSource$: Observable<IEquipmentType[]>;
    locationsSource$: Observable<ILocation[]>;

    form: FormGroup;
    isFormProcessing = false;
    isNumberGenerating = false;

    itemStatus = [{ name: 'Active' }, { name: 'Inactive' }];

    serviceType: ServiceType;
    linkedItemId: string;
    entityType: LinkedEntityType;

    linkedEntityType = LinkedEntityType;

    onDestroy$: Subject<void> = new Subject();

    @Output() actionButtonEvent = new EventEmitter();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    generateButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm w-100 mt-4',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private sequenceService: SequenceService,
        private dataTableService: DataTableService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        this.store.dispatch([
            new GetCompanies(),
            new GetEquipmentCategories(),
            new GetEquipmentTypes(),
            new GetLocations(),
            new GetManufacturers(),
            new GetSuppliers(),
        ]);

        this.equipmentTypesSource$ = this.equipmentTypes$.pipe(
            skipWhile((items) => !items || items.length === 0),
            take(1),
        );

        this.equipmentCategoriesSource$ = this.equipmentCategories$.pipe(
            skipWhile((items) => !items || items.length === 0),
            take(1),
        );

        this.locationsSource$ = this.location$.pipe(
            skipWhile((items) => !items || items.length === 0),
            take(1),
        );

        this.setFormInit();
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            itemNumber: [null, Validators.required],
            manufacturer: [null],
            supplier: [null],
            purchaser: [null],
            category: [null],
            type: [null],
            factoryBuildDate: [null],
            factoryLeftDate: [null],
            location: [null],
            locationMap: [null],
            serialNumber: [null],
            description: [null],
        });
    }

    get f() {
        return this.form.controls;
    }

    create() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { id, ...rest } = this.form.getRawValue();

        this.isFormProcessing = true;

        if (!this.serviceType) {
            this.store
                .dispatch(
                    new CreateItem({
                        ...rest,
                    }),
                )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        this.isFormProcessing = false;
                        this.sweetAlertService.success('Successfully create the record');
                        this.bsModalRef.hide();
                        this.dataTableService.dataTableRerenderer.next(true);
                    },
                    error: () => {
                        this.isFormProcessing = false;
                    },
                });
        } else {
            switch (this.serviceType) {
                case ServiceType.CONTRACT:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForContract(
                                this.linkedItemId,
                                rest,
                                ServiceType.ITEM,
                                LinkedEntityType.ITEMS,
                                LinkedCountType.LINKED_ITEM_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.ITEM:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForCompany(
                                this.linkedItemId,
                                rest,
                                ServiceType.ITEM,
                                this.entityType,
                                LinkedCountType.LINKED_ITEM_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.ISSUE:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForIssue(
                                this.linkedItemId,
                                rest,
                                ServiceType.ITEM,
                                LinkedEntityType.ITEMS,
                                LinkedCountType.LINKED_ITEM_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.SERVICE:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForService(
                                this.linkedItemId,
                                rest,
                                ServiceType.ITEM,
                                LinkedEntityType.ITEMS,
                                LinkedCountType.LINKED_ITEM_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                                this.actionButtonEvent.emit();
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
            }
        }
    }

    reset() {
        this.form.reset();
    }

    async generate() {
        this.isNumberGenerating = true;

        try {
            const response = await firstValueFrom(this.sequenceService.get(ServiceType.ITEM));
            this.form.patchValue({
                itemNumber: response.nextNumber,
            });
        } catch (error) {
            console.error(error);
        }

        this.isNumberGenerating = false;
    }

    addType = (name: string) => {
        return this.store
            .dispatch(new CreateEquipmentType({ name }))
            .pipe(switchMap(() => this.store.select(EquipmentTypeState.getNewEquipmentType)));
    };

    addCategory = (name: string) => {
        return this.store
            .dispatch(new CreateEquipmentCategory({ name }))
            .pipe(switchMap(() => this.store.select(EquipmentCategoryState.getNewEquipmentCategory)));
    };

    addLocation = (name: string) => {
        return this.store
            .dispatch(new CreateLocation({ name }))
            .pipe(switchMap(() => this.store.select(LocationState.getNewLocation)));
    };

    setFormInit() {
        switch (this.entityType) {
            case LinkedEntityType.PURCHASED_ITEMS:
                this.form.controls['purchaser'].setValue(this.linkedItemId);
                break;
            case LinkedEntityType.SUPPLIED_ITEMS:
                this.form.controls['supplier'].setValue(this.linkedItemId);
                break;
            case LinkedEntityType.MANUFACTURED_ITEMS:
                this.form.controls['manufacturer'].setValue(this.linkedItemId);
                break;
        }
    }
}
