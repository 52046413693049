// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import { CompanyState, CreateLinkedItemForContract, CreateService, GetCompanies, GetDashboardData } from '@data/state';

// schemas
import { IButtonCustomClass, ICompany, IDataTableFilterValues } from '@data/schema';

// services
import { SweetAlertService } from '@app/service';
import { SequenceService } from '@data/service';

// enums
import { ServiceType } from '@data/enum';

@Component({
    selector: 'app-filter-layout-modal',
    templateUrl: './filter-layout-modal.component.html',
    styleUrls: ['./filter-layout-modal.component.scss'],
})
export class FilterLayoutModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany>;

    @Output() actionButtonEvent = new EventEmitter();
    serviceType: ServiceType;
    linkedItemId: string;
    filterValues: IDataTableFilterValues;

    form: FormGroup;
    isFormProcessing = false;
    isNumberGenerating = false;

    serviceStatus = [
        { name: 'Pending', value: 'pending' },
        { name: 'Completed', value: 'completed' },
        { name: 'Cancelled', value: 'cancelled' },
    ];
    contractStatus = [
        { name: 'Active', value: 'Active' },
        { name: 'Inactive', value: 'Inactive' },
    ];

    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    generateButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm w-100 mt-4',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private sequenceService: SequenceService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        this.setForm();
        this.store.dispatch([new GetCompanies()]);
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            serviceStatus: [['pending']],
            recipient: [null],
            provider: [null],
            contractStatus: [null],
        });
    }

    get f() {
        return this.form.controls;
    }

    public setForm(): void {
        this.form.setValue({
            serviceStatus: this.filterValues?.serviceStatus,
            recipient: this.filterValues?.recipient,
            provider: this.filterValues?.provider,
            contractStatus: this.filterValues?.contractStatus,
        });
    }

    apply() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { provider, recipient, serviceStatus, contractStatus } = this.form.getRawValue();

        this.actionButtonEvent.emit({
            provider,
            recipient,
            serviceStatus,
            contractStatus,
        });
    }

    reset() {
        this.form.reset();
    }

    resetToDefault() {
        this.form.setValue({
            serviceStatus: ['pending'],
            recipient: null,
            provider: null,
            contractStatus: null,
        });

        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { provider, recipient, serviceStatus, contractStatus } = this.form.getRawValue();
        const deafult = true;

        this.actionButtonEvent.emit({
            deafult,
            provider,
            recipient,
            serviceStatus,
            contractStatus,
        });
    }
}
