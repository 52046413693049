<ng-select
    [items]="list$ | async"
    [bindValue]="bindField"
    [bindLabel]="labelField"
    [isOpen]="isOpen"
    [minTermLength]="minLengthTerm"
    [loading]="isLoading"
    [typeahead]="searchInput$"
    [ngClass]="customClasses"
    [groupBy]="groupBy"
    [placeholder]="placeholder"
    typeToSearchText=""
    notFoundText="Not Found"
    (clear)="onClear()"
>
    <!-- option group template -->
    <ng-template ng-optgroup-tmp *ngIf="optionGroupTemplate" let-item="item" let-index="index">
        <ng-container
            [ngTemplateOutlet]="optionGroupTemplate"
            [ngTemplateOutletContext]="{
                item: item,
                index: index
            }"
        ></ng-container
    ></ng-template>
    <!-- //. option group template -->

    <!-- option list template -->
    <ng-template *ngIf="optionTemplate" ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <ng-container
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{
                item: item,
                index: index,
                search: search
            }"
        ></ng-container>
    </ng-template>
    <!-- //. option list template -->
</ng-select>
