// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, tap } from 'rxjs';

//states
import { GetItems, ItemState, ServiceState } from '@data/state';

// enums
import { DataTableActionType, DataTableCellType, ItemDataTableCellType } from '@data/enum';

// schemas
import { IFormCustomClass, IButtonCustomClass, IDataTableInfo, IItem, IDataTablePaginationDTO } from '@data/schema';
import { ResizeService } from '@app/service';
import { ItemService } from '@data/service';

@Component({
    selector: 'app-link-item-modal',
    templateUrl: './link-item-modal.component.html',
    styleUrls: ['./link-item-modal.component.scss'],
})
export class LinkItemModalComponent implements OnInit {
    dataTableSource$: Observable<IItem[]>;
    isDataLoading$ = new BehaviorSubject<boolean>(false);

    dataTableCols: DataTables.ColumnSettings[] = [
        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
        { data: '_id', title: 'System UUID', visible: false },
        { data: 'itemNumber', title: 'Item Number', type: ItemDataTableCellType.ITEM_NUMBER_CELL },
        { data: 'manufacturer.companyName', title: 'Manufacturer', type: ItemDataTableCellType.MANUFACTURER_NAME_CELL },
        { data: 'supplier.companyName', title: 'Supplier', type: ItemDataTableCellType.SUPPLIER_NAME_CELL },
        { data: 'purchaser.companyName', title: 'Purchaser', type: ItemDataTableCellType.PURCHASER_NAME_CELL },
        { data: 'category.name', title: 'Category' },
        { data: 'type.name', title: 'Type' },
        { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
    ];

    dataTableLengthMenu = [
        [5, 10, 20, 50, 100],
        ['5 / page', '10 / page', '20 / page', '50 / page', '100 / page'],
    ];

    dataTableForm: FormGroup;

    dataTableCellType = DataTableCellType;
    dataTableActionType = DataTableActionType;
    itemDataTableCellType = ItemDataTableCellType;

    isFormProcessing = false;

    dataTableInfo: IDataTableInfo = {
        buttonTitle: 'Create New',
        infoLabel: 'items',
    };

    checkboxCustomClasses: IFormCustomClass = {
        formGroup: 'form-group m-0',
        label: 'font-weight-bold text-muted small mb-0',
        input: '',
    };

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    @Output() actionButtonEvent = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private itemService: ItemService,
        public resizeService: ResizeService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {}

    dataTableServerSideCallbackFn = (pagination: IDataTablePaginationDTO) => {
        this.isDataLoading$.next(true);
        return this.itemService.getSSR(pagination).pipe(
            tap((response) => {
                this.addFormControls(response.data);
                this.isDataLoading$.next(false);
                this.isDataLoading$.complete();
            }),
        );
    };

    private initializeForm(): void {
        this.dataTableForm = this.formBuilder.group({
            items: this.formBuilder.array([]),
        });
    }

    private addFormControls(items: IItem[]): void {
        this.p.clear();

        items.forEach((item) => {
            this.p.push(this.createItemField(item));
        });
    }

    private createItemField(item: IItem): FormGroup {
        const itemField = this.formBuilder.group({
            _id: item._id,
            isSelected: false,
        });

        return itemField;
    }

    get f() {
        return this.dataTableForm.controls;
    }

    get p() {
        return this.f['items'] as FormArray;
    }

    onSelectAllCheckBoxValueChanges(flag: boolean) {
        const { items } = this.dataTableForm.getRawValue();

        items.forEach((formField, i) => {
            if (flag && !this.p.controls[i].get('isSelected').disabled) {
                this.p.controls[i].patchValue({ isSelected: true });
            } else {
                this.p.controls[i].patchValue({ isSelected: false });
            }
        });
    }

    onActionButtonClick($event) {
        const { items } = this.dataTableForm.getRawValue();
        const selectedItems = items.filter((item) => item.isSelected).map((item) => item._id);
        this.actionButtonEvent.emit({
            selectedIds: selectedItems,
            ...$event,
        });
    }
}
