<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Upload</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>

<div class="modal-body">
    <div class="m-4">
        <ngx-dropzone
            (change)="onSelect($event)"
            class="border bg-secondary bg-opacity-10 overflow-hidden mb-5 pb-2"
            [maxFileSize]="maxFileSize"
            [processDirectoryDrop]="true"
        >
            <ngx-dropzone-label>
                <p class="fs-80 mb-0 text-primary"><i class="bi bi-inbox"></i></p>
                <p class="fs-20 mb-0 text-black">Click or drag file to this area to upload</p>
                <p class="text-secondary">Support for a single or bulk upload. Maximum file size 2MB.</p>
            </ngx-dropzone-label>
        </ngx-dropzone>

        <div *ngFor="let f of files; let i = index" class="border d-flex my-2 py-2 px-3 bg-secondary bg-opacity-10">
            <p class="mb-0 align-self-center">{{ f.name }}</p>
            <p
                class="text-danger cursor-pointer mb-0 ms-auto align-self-center"
                *ngIf="!fileUploadingStatus[i]"
                (click)="onRemove(f)"
            >
                <i class="bi bi-x-lg"></i>
            </p>
            <p *ngIf="fileUploadingStatus[i]" class="mb-0 ms-auto align-self-center text-primary">
                <i class="spinner-border spinner-border-sm"></i>
            </p>
        </div>
    </div>
</div>

<div class="modal-footer">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Upload Files"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="upload()"
                    [isDisabled]="files.length <= 0"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
            </div>
        </div>
    </div>
</div>
