// core
import { Injectable } from '@angular/core';
import { IPasswordStrengthMeterService } from 'angular-password-strength-meter';
import { DiversityType, passwordStrength, Result } from 'check-password-strength';

@Injectable()
export class PasswordStrengthService extends IPasswordStrengthMeterService {
    score(password: string): number {
        const strengthResponse: Result<string> = passwordStrength(password);
        return this.mapStrengthToScore(strengthResponse.value);
    }

    scoreWithFeedback(password: string): {
        score: number;
        feedback: { warning: string; suggestions: string[] };
    } {
        const strengthResponse: Result<string> = passwordStrength(password);
        return this.mapStrengthToScoreWithFeedback(strengthResponse);
    }

    mapStrengthToScore(strength: string): number {
        switch (strength) {
            case 'Strong':
                return 4;

            case 'Medium':
                return 3;

            case 'Weak':
                return 1;

            default:
                return 0;
        }
    }

    mapStrengthToScoreWithFeedback(strength: Result<string>): {
        score: number;
        feedback: { warning: string; suggestions: string[] };
    } {
        const characterSpecs: DiversityType[] = ['lowercase', 'uppercase', 'number', 'symbol'];

        const feedbackObj = {
            score: 0,
            feedback: { warning: 'Password is too weak', suggestions: [] },
        };

        const nonContainingSpecs = characterSpecs
            .filter((spec) => !strength.contains.includes(spec))
            .concat(strength.contains.filter((diversity) => !characterSpecs.includes(diversity)));

        switch (strength.value) {
            case 'Strong':
                feedbackObj.score = 4;
                feedbackObj.feedback.warning = 'Password is perfect!';
                feedbackObj.feedback.suggestions = [];
                break;

            case 'Medium':
                feedbackObj.score = 3;
                feedbackObj.feedback.warning = 'Little more to go to make your password perfect!';
                feedbackObj.feedback.suggestions = [];
                break;

            case 'Weak':
                feedbackObj.score = 1;
                feedbackObj.feedback.warning = 'Password is weak. You can do better.';
                feedbackObj.feedback.suggestions = [];
                break;

            default:
                feedbackObj.score = 0;
                feedbackObj.feedback.warning = 'Password is too weak. Follow the suggestions to make it perfect.';
                feedbackObj.feedback.suggestions = [];
                break;
        }

        return feedbackObj;
    }
}
