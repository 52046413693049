// core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// shared
import { CoreModule } from '@app/core.module';
import { SharedModule } from '@shared/shared.module';
import { DataModule } from '@data/data.module';

// layouts
import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { SideNavComponent } from '@layout/side-nav/side-nav.component';
import { HeaderNavComponent } from '@layout/header-nav/header-nav.component';
import { SideNavMobileComponent } from './layout/side-nav-mobile/side-nav-mobile.component';

// components
import { AppComponent } from './app.component';

// routing
import { AppRoutingModule } from './app-routing.module';

@NgModule({
    declarations: [
        // layouts
        AuthLayoutComponent,
        ContentLayoutComponent,
        SideNavComponent,
        SideNavMobileComponent,
        HeaderNavComponent,

        // components
        AppComponent,
    ],
    imports: [
        // core
        BrowserModule,
        BrowserAnimationsModule,

        // shared
        CoreModule,
        DataModule,
        SharedModule,

        // routing
        AppRoutingModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
