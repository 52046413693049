export enum ServiceType {
    COMPANY = 'company',
    CONTACT = 'contact',
    CONTRACT = 'contract',
    INSTALLATION = 'installation',
    ISSUE = 'issue',
    ITEM = 'item',
    SERVICE = 'service',
    CHECK_LIST = 'checkList',
}
