import { IContract, IHistory, IIssue, IItem, ILinkCounts, IService, IServiceCheckList } from '@data/schema';
import { GenericStateModel, LinkedItemsStateModel } from '@data/state';

export interface ServiceStateModel extends GenericStateModel<IService> {
    linkCounts?: ILinkCounts;
    linkedItems?: {
        purchasedItems?: LinkedItemsStateModel<IItem>;
        suppliedItems?: LinkedItemsStateModel<IItem>;
        manufacturedItems?: LinkedItemsStateModel<IItem>;
        items?: LinkedItemsStateModel<IItem>;
        contracts?: LinkedItemsStateModel<IContract>;
        issuesAsItemCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsContractCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsServiceId?: LinkedItemsStateModel<IIssue>;
        notes: LinkedItemsStateModel<IHistory>;
    };
    checkList: { data: IServiceCheckList[] };
}
