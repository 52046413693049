<div class="bg-white shadow-sm py-3 h-100">
    <div class="row px-4 mb-3">
        <div class="col-4 d-flex align-self-center"><h6 class="mb-0">Uploads</h6></div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-primary"
                    (click)="downloadSelected()"
                >
                    <i class="bi bi-download"></i>
                    Download
                </button>
            </div>
        </div>
        <div
            class="col-2"
            *ngIf="
                userRole !== userRoleType.APPLICATION_VIEWER &&
                userRole !== userRoleType.TENANT_VIEWER &&
                userRole !== userRoleType.COMPANY_APPLICATION_ADMIN &&
                userRole !== userRoleType.COMPANY_APPLICATION_VIEWER &&
                userRole !== userRoleType.COMPANY_TENANT_VIEWER
            "
        >
            <div class="d-flex justify-content-end">
                <app-spinner-button
                    buttonText="Upload"
                    [isProcessing]="isProcessing"
                    [customClasses]="buttonCustomClasses"
                    (onButtonClick)="upload()"
                ></app-spinner-button>
            </div>
        </div>
    </div>

    <div class="border border-start-0 border-end-0">
        <div class="row mx-1 py-2">
            <div class="col-2 col-lg-1 d-flex align-self-center">
                <app-checkbox-input
                    [(ngModel)]="isAllFilesSelected"
                    (onCheckBoxChange)="onChange(true, $event)"
                ></app-checkbox-input>
            </div>
            <div class="col-4"><p class="mb-0 fw-semibold">File</p></div>
            <div class="col-6"><p class="mb-0 fw-semibold">Uploaded Date</p></div>
            <div class="col-2"></div>
        </div>
    </div>
    <div class="row mx-1 py-2" *ngFor="let upload of uploads; let i = index">
        <div class="col-2 col-lg-1 d-flex align-self-center">
            <app-checkbox-input
                [(ngModel)]="isChecked[i]"
                (onCheckBoxChange)="onChange(false, $event, upload, i)"
            ></app-checkbox-input>
        </div>
        <div class="col">
            <p class="mb-0">{{ upload?.fileName }}</p>
        </div>
        <div class="col">
            <p class="mb-0">{{ upload?.createdDate | date : 'yyyy-MM-dd' }}</p>
        </div>
        <div class="col-2 col-sm-3">
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-primary"
                    (click)="download(upload?._id)"
                >
                    <i class="bi bi-download"></i>
                </button>

                <button
                    *ngIf="
                        userRole !== userRoleType.APPLICATION_VIEWER &&
                        userRole !== userRoleType.TENANT_VIEWER &&
                        userRole !== userRoleType.COMPANY_APPLICATION_ADMIN &&
                        userRole !== userRoleType.COMPANY_APPLICATION_VIEWER &&
                        userRole !== userRoleType.COMPANY_TENANT_VIEWER
                    "
                    class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-danger"
                    (click)="delete(upload?._id, upload?.fileName)"
                >
                    <i class="bi bi-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>
