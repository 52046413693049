// schemas
import { ICreateContactDTO, ICreateHistoryDTO, ICreateTableLayoutDTO } from '@data/schema';

export class GetContacts {
    static readonly type = '[CONTACT] GET CONTACTS';
}

export class GetContact {
    static readonly type = '[CONTACT] GET CONTACT';
    constructor(public id: string) {}
}

export class GetLinkCountsForContact {
    static readonly type = '[CONTACT] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetNotesForContact {
    static readonly type = '[CONTACT] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class GetTableLayoutForContact {
    static readonly type = '[CONTACT] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class CreateNoteForContact {
    static readonly type = '[CONTACT] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class UpdateTableLayoutForContact {
    static readonly type = '[CONTACT] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class CreateContact {
    static readonly type = '[CONTACT] CREATE CONTACT';
    constructor(public payload: ICreateContactDTO) {}
}

export class UpdateContact {
    static readonly type = '[CONTACT] UPDATE CONTACT';
    constructor(public id: string, public payload: ICreateContactDTO) {}
}

export class DeleteContact {
    static readonly type = '[CONTACT] DELETE CONTACT';
    constructor(public id: string) {}
}

export class DeleteContacts {
    static readonly type = '[CONTACT] DELETE CONTACTS';
    constructor(public ids: string[]) {}
}

export class ResetContact {
    static readonly type = '[CONTACT] RESET CONTACT';
    constructor() {}
}
