// core
import { Component, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

// schemas
import { IFormCustomClass } from '@data/schema';

@Component({
    selector: 'app-radio-toggle-input',
    templateUrl: './radio-toggle-input.component.html',
    styleUrls: ['./radio-toggle-input.component.scss'],
})
export class RadioToggleInputComponent {
    @Input() label: string;
    @Input() name: string;
    @Input() testAutomationSelectorName: string;
    @Input() values: string[];

    @Input() customClasses: IFormCustomClass = {
        formGroup: 'form-group mb-0',
        label: 'btn btn-outline-primary btn-sm mb-0',
        input: '',
    };

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    writeValue(obj: any): void {}

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}
}
