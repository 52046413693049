// schemas
import { ICreateEquipmentCategoryDTO } from '@data/schema';

export class GetEquipmentCategories {
    static readonly type = '[EQUIPMENT-CATEGORY] GET EQUIPMENT-CATEGORIES';
}

export class GetEquipmentCategory {
    static readonly type = '[EQUIPMENT-CATEGORY] GET EQUIPMENT-CATEGORY';
    constructor(public id: string) {}
}

export class CreateEquipmentCategory {
    static readonly type = '[EQUIPMENT-CATEGORY] CREATE EQUIPMENT-CATEGORY';
    constructor(public payload: ICreateEquipmentCategoryDTO) {}
}

export class UpdateEquipmentCategory {
    static readonly type = '[EQUIPMENT-CATEGORY] UPDATE EQUIPMENT-CATEGORY';
    constructor(public id: string, public payload: ICreateEquipmentCategoryDTO) {}
}

export class DeleteEquipmentCategory {
    static readonly type = '[EQUIPMENT-CATEGORY] DELETE EQUIPMENT-CATEGORY';
    constructor(public id: string) {}
}
