// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateInstallation,
    CreateNoteForInstallation,
    DeleteInstallation,
    DeleteInstallations,
    GetInstallation,
    GetInstallations,
    GetLinkCountsForInstallation,
    GetLinkedDataForInstallation,
    GetNotesForInstallation,
    GetTableLayoutForInstallation,
    ResetInstallations,
    ResetNotesInstallation,
    UpdateInstallation,
    UpdateTableLayoutForInstallation,
} from './installation.actions';

// services
import { HistoryService, InstallationService, TableLayoutService } from '@data/service';

// schemas
import { InstallationStateModel } from './installation-state.model';
import { LinkedEntityType } from '@data/enum/linked-entity-type';
import { LinkedItemsStateModel } from '../linked-items/linked-items-state.model';
import { ILinkModel } from '@data/schema';
import { DataTableCellType, InstallationDataTableCellType } from '@data/enum';

@State<InstallationStateModel>({
    name: 'installations',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        {
                            data: 'installationNumber',
                            title: 'Installation Number',
                            type: InstallationDataTableCellType.INSTALLATION_NUMBER_CELL,
                        },
                        { data: 'itemType.name', title: 'Item Type' },
                        {
                            data: 'itemManufacturer.companyName',
                            title: 'Item Manufacturer',
                            type: InstallationDataTableCellType.ITEM_MANUFACTURER_CELL,
                        },
                        {
                            data: 'itemSupplier.companyName',
                            title: 'Item Supplier',
                            type: InstallationDataTableCellType.ITEM_SUPPLIER_CELL,
                        },
                        { data: 'startDate', title: 'Start Date', type: DataTableCellType.DATE_CELL },
                        { data: 'endDate', title: 'End Date', type: DataTableCellType.DATE_CELL },
                    ],
                },
                isDefault: true,
            },
        },
    },
})
@Injectable()
export class InstallationState {
    constructor(
        private installationService: InstallationService,
        private historyService: HistoryService,
        private tableLayoutService: TableLayoutService,
        private store: Store,
    ) {}

    @Selector()
    static getInstallationList(state: InstallationStateModel) {
        return state.items.data;
    }

    @Selector()
    static getInstallationListLoading(state: InstallationStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentInstallation(state: InstallationStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getLinkCounts(state: InstallationStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getTableLayout(state: InstallationStateModel) {
        return state.items?.tableLayout?.data?.layout;
    }

    @Selector()
    static getLinkedData(state: InstallationStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.data;
        };
    }

    @Selector()
    static getLinkedDataLoading(state: InstallationStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.loading;
        };
    }

    @Selector()
    static getNotes(state: InstallationStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: InstallationStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetInstallations)
    getInstallations({ getState, patchState }: StateContext<InstallationStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.installationService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetTableLayoutForInstallation)
    GetTableLayoutForInstallation(
        { getState, patchState }: StateContext<InstallationStateModel>,
        { page }: GetTableLayoutForInstallation,
    ) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                {
                                    data: 'installationNumber',
                                    title: 'Installation Number',
                                    type: InstallationDataTableCellType.INSTALLATION_NUMBER_CELL,
                                },
                                { data: 'itemType.name', title: 'Item Type' },
                                {
                                    data: 'itemManufacturer.companyName',
                                    title: 'Item Manufacturer',
                                    type: InstallationDataTableCellType.ITEM_MANUFACTURER_CELL,
                                },
                                {
                                    data: 'itemSupplier.companyName',
                                    title: 'Item Supplier',
                                    type: InstallationDataTableCellType.ITEM_SUPPLIER_CELL,
                                },
                                { data: 'startDate', title: 'Start Date', type: DataTableCellType.DATE_CELL },
                                { data: 'endDate', title: 'End Date', type: DataTableCellType.DATE_CELL },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(GetInstallation)
    getInstallation({ getState, setState }: StateContext<InstallationStateModel>, { id }: GetInstallation) {
        this.store.dispatch(new ResetInstallations());
        const state = getState();

        return this.installationService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetLinkCountsForInstallation)
    GetLinkCountsForInstallation(
        { patchState }: StateContext<InstallationStateModel>,
        { id }: GetLinkCountsForInstallation,
    ) {
        return this.installationService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetLinkedDataForInstallation)
    GetLinkedDataForInstallation(
        { getState, patchState }: StateContext<InstallationStateModel>,
        { id, serviceType, dataType, itemType }: GetLinkedDataForInstallation,
    ) {
        const state = getState();

        if (state?.linkedItems?.[dataType]?.retrievedAt) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                [dataType]: {
                    ...state.linkedItems?.[dataType],
                    loading: true,
                },
            },
        });

        return (
            itemType
                ? this?.[serviceType + 'Service'].getByIssueIdNType(id, itemType)
                : this?.[serviceType + 'Service'].getByIssueId(id)
        ).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        [dataType]: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetNotesForInstallation)
    GetNotesForInstallation(
        { getState, patchState }: StateContext<InstallationStateModel>,
        { id, skip, limit }: GetNotesForInstallation,
    ) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(CreateNoteForInstallation)
    createNoteForInstallation(
        { getState, patchState }: StateContext<InstallationStateModel>,
        { payload }: CreateNoteForInstallation,
    ) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateInstallation)
    createInstallation(
        { getState, patchState }: StateContext<InstallationStateModel>,
        { payload }: CreateInstallation,
    ) {
        const state = getState();

        return this.installationService.create(payload).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateInstallation)
    updateInstallation(
        { getState, setState, patchState }: StateContext<InstallationStateModel>,
        { id, payload }: UpdateInstallation,
    ) {
        return this.installationService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });

                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                });

                this.store.dispatch(new ResetNotesInstallation());
            }),
        );
    }

    @Action(UpdateTableLayoutForInstallation)
    updateTableLayoutForContract(
        { getState, patchState }: StateContext<InstallationStateModel>,
        { payload, page }: UpdateTableLayoutForInstallation,
    ) {
        const state = getState();

        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(DeleteInstallation)
    deleteInstallation({ getState, setState }: StateContext<InstallationStateModel>, { id }: DeleteInstallation) {
        return this.installationService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteInstallations)
    deleteInstallations(
        { getState, setState, patchState }: StateContext<InstallationStateModel>,
        { ids }: DeleteInstallations,
    ) {
        return this.installationService.deleteBatch(ids);
    }

    @Action(ResetInstallations)
    resetInstallations({ getState, patchState }: StateContext<InstallationStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    @Action(ResetNotesInstallation)
    resetNotes({ getState, patchState }: StateContext<InstallationStateModel>) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    data: [],
                    loading: true,
                },
            },
        });
    }
}
