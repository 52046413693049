<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center mb-5">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Edit Layout</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <div class="col-12">
        <p class="text-secondary fs-14">
            Select the fields you wish to display then click / hold and drag to set order
        </p>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <div class="mt-5 pt-5 px-2">
        <div cdkDropList class="drag-n-drop-list" (cdkDropListDropped)="drop($event)">
            <div class="drag-n-drop-box mt-2 p-2" cdkDrag *ngFor="let column of checkBoxRemovedCols; let i = index">
                <div class="form-check form-switch form-group ps-0">
                    <label class="form-check-label fs-18 cursor-move" [for]="'flexSwitchCheck' + i">
                        <i class="bi bi-arrow-down-up me-3"></i>{{ column?.title }}
                    </label>
                    <input
                        [checked]="column.visible === undefined || column.visible"
                        (change)="onToggleVisibility($event, column?.title)"
                        class="form-check-input float-end cursor-pointer toggle-primary"
                        type="checkbox"
                        role="switch"
                        [id]="'flexSwitchCheck' + i"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: apply -->
                <app-spinner-button
                    buttonText="Apply"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="apply()"
                ></app-spinner-button>
                <!-- //. form-button: apply -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
