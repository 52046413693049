// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IContract,
    ICreateContractDTO,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
    ILinkCounts,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class ContractService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IContract[]> {
        return this.apiService.get<{ data: IContract[] }>('/contracts').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IContract>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IContract[]; totalItems: number }>('/contracts', params).pipe(
            map((res) => ({
                data: !res.data[0]?.['data'].length ? [] : res.data[0]?.['data'],
                totalItems: res.data[0]?.['data'].length,
                totalFilteredItems: !res.data[0]?.['data'].length ? 0 : res.data[0]?.['total'],
            })),
        );
    }

    getById(id: string): Observable<IContract> {
        return this.apiService.get<{ data: IContract }>(`/contracts/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateContractDTO): Observable<IContract> {
        return this.apiService
            .post<{ data: IContract }, ICreateContractDTO>(`/contracts`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateContractDTO): Observable<IContract> {
        return this.apiService
            .put<{ data: IContract }, ICreateContractDTO>(`/contracts/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IContract }>(`/contracts/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IContract[] }, { ids: string[] }>(`/contracts`, { ids });
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService.get<{ data: ILinkCounts }>(`/contracts/${id}/linkCounts`).pipe(map((res) => res.data));
    }

    getLinkStatus(ids: string[]): Observable<string> {
        let params = new HttpParams();

        ids.forEach((id: string) => {
            params = params.append(`Ids`, id);
        });

        return this.apiService
            .get<{ data: string }>(`/linkValidation/Contracts/linkStatus`, params)
            .pipe(map((res) => res.data));
    }

    getByCompanyIdNType(id: string, type: string): Observable<IContract[]> {
        return this.apiService
            .get<{ data: IContract[] }>(`/contracts/company/${id}/companyType/${type}`)
            .pipe(map((res) => res.data));
    }

    getByCompanyIdNTypeSSR(
        id: string,
        type: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IContract>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IContract[]; totalItems: number }>(`/contracts/company/${id}/companyType/${type}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByIssueId(id: string): Observable<IContract[]> {
        return this.apiService.get<{ data: IContract[] }>(`/contracts/issue/${id}`).pipe(map((res) => res.data));
    }

    getByIssueIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IContract>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IContract[]; totalItems: number }>(`/contracts/issue/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByServiceId(id: string): Observable<IContract[]> {
        return this.apiService.get<{ data: IContract[] }>(`/contracts/service/${id}`).pipe(map((res) => res.data));
    }

    getByServiceIdSSR(
        id: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IContract>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IContract[]; totalItems: number }>(`/contracts/service/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    createWithServiceLink(id: string, context: ICreateContractDTO): Observable<IContract> {
        return this.apiService
            .post<{ data: IContract }, ICreateContractDTO>(`/contracts/service/${id}`, context)
            .pipe(map((res) => res.data));
    }

    createWithIssueLink(id: string, context: ICreateContractDTO): Observable<IContract> {
        return this.apiService
            .post<{ data: IContract }, ICreateContractDTO>(`/contracts/issue/${id}`, context)
            .pipe(map((res) => res.data));
    }

    createWithItemLink(id: string, context: ICreateContractDTO): Observable<IContract> {
        return this.apiService
            .post<{ data: IContract }, ICreateContractDTO>(`/contracts/item/${id}`, context)
            .pipe(map((res) => res.data));
    }

    getByItemId(itemId: string): Observable<IContract[]> {
        return this.apiService.get<{ data: IContract[] }>(`/contracts/item/${itemId}`).pipe(map((res) => res.data));
    }

    getByItemIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IContract>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IContract[]; totalItems: number }>(`/contracts/item/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    unlinkFromCompany(unlinkObject): Observable<IContract> {
        return this.apiService
            .deleteBatch<{ data: IContract }, ICreateContractDTO>(`/contracts/company/unlink`, unlinkObject)
            .pipe(map((res) => res.data));
    }
}
