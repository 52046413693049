export class GetDashboardData {
    static readonly type = '[DASHBOARD] GET SERVICE DASHBOARD';

    constructor(
        public startDate: string,
        public endDate: string,
        public serviceStatus: string[],
        public provider: string[],
        public recipient: string[],
        public contractStatus: string[],
    ) {}
}

export class GetDashboardSummaries {
    static readonly type = '[DASHBOARD] GET DASHBOARD SUMMARIES';
}
