<div class="container-fluid">
    <div class="row no-gutters min-vh-100">
        <div
            class="col-lg-8 col-md-6 d-none d-md-block auth-side-img"
            [ngStyle]="{ 'background-image': 'url(' + imgSource + ')' }"
        ></div>

        <div class="col-lg-4 col-md-6 bg-white">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
