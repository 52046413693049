// schemas
import { ICreateHistoryDTO, ICreateInstallationDTO, ICreateTableLayoutDTO } from '@data/schema';

// enums
import { ServiceType, LinkedEntityType } from '@data/enum';

export class GetInstallations {
    static readonly type = '[INSTALLATION] GET INSTALLATIONS';
}

export class GetInstallation {
    static readonly type = '[INSTALLATION] GET INSTALLATION';
    constructor(public id: string) {}
}

export class GetLinkCountsForInstallation {
    static readonly type = '[INSTALLATION] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetLinkedDataForInstallation {
    static readonly type = '[INSTALLATION] GET LINKED DATA';
    constructor(
        public id: string,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public itemType?: string,
    ) {}
}

export class GetNotesForInstallation {
    static readonly type = '[INSTALLATION] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class GetTableLayoutForInstallation {
    static readonly type = '[INSTALLATION] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class CreateNoteForInstallation {
    static readonly type = '[INSTALLATION] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class CreateInstallation {
    static readonly type = '[INSTALLATION] CREATE INSTALLATION';
    constructor(public payload: ICreateInstallationDTO) {}
}

export class UpdateInstallation {
    static readonly type = '[INSTALLATION] UPDATE INSTALLATION';
    constructor(public id: string, public payload: ICreateInstallationDTO) {}
}

export class UpdateTableLayoutForInstallation {
    static readonly type = '[INSTALLATION] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class DeleteInstallation {
    static readonly type = '[INSTALLATION] DELETE INSTALLATION';
    constructor(public id: string) {}
}

export class DeleteInstallations {
    static readonly type = '[INSTALLATION] DELETE INSTALLATIONS';
    constructor(public ids: string[]) {}
}

export class ResetNotesInstallation {
    static readonly type = '[INSTALLATION] RESET NOTES';
    constructor() {}
}

export class ResetInstallations {
    static readonly type = '[INSTALLATION] RESET INSTALLATIONS';
    constructor() {}
}