// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { ILocation, ICreateLocationDTO } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(private apiService: ApiService) {}

    get(): Observable<ILocation[]> {
        return this.apiService.get<{ data: ILocation[] }>('/locations').pipe(map((res) => res.data));
    }

    getById(id: string): Observable<ILocation> {
        return this.apiService.get<{ data: ILocation }>(`/locations/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateLocationDTO): Observable<ILocation> {
        return this.apiService
            .post<{ data: ILocation }, ICreateLocationDTO>(`/locations`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateLocationDTO): Observable<ILocation> {
        return this.apiService
            .put<{ data: ILocation }, ICreateLocationDTO>(`/locations/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: ILocation }>(`/locations/${id}`);
    }

    getAssignedById(id: string): Observable<ILocation> {
        return this.apiService.get(`/locations/${id}/assignedLocation`);
    }
}
