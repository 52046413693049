// core
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

// services
import { SweetAlertService } from '@app/service';
import { IButtonCustomClass } from '@data/schema';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';
import { CreateUpload } from '@data/state';

@Component({
    selector: 'app-file-upload-modal',
    templateUrl: './file-upload-modal.component.html',
    styleUrls: ['./file-upload-modal.component.scss'],
})
export class FileUploadModalComponent implements OnInit, OnDestroy {
    files: File[] = [];
    fileUploadingStatus: boolean[] = [];
    maxFileSize = '2000000';
    isFormProcessing = false;
    id: string;
    entityType: string;
    isUploadFailed: boolean;
    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        public sweetAlertService: SweetAlertService,
        private store: Store,
    ) {}

    ngOnInit() {}

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onSelect($event) {
        if ($event.rejectedFiles.length > 0) {
            if ($event.rejectedFiles[0].reason === 'size') {
                this.sweetAlertService.error('File size should be less than 2 MB');
            }
        }
        this.files.push(...$event.addedFiles);
        this.fileUploadingStatus.push(false);
    }

    onRemove($event) {
        this.files.splice(this.files.indexOf($event), 1);
    }

    async upload() {
        this.files.forEach((file, i) => {
            this.fileUploadingStatus[i] = true;
            this.store
                .dispatch(new CreateUpload(this.entityType, this.id, file))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        this.isUploadFailed = false;
                        this.isFormProcessing = false;
                        this.fileUploadingStatus[i] = false;
                    },
                    error: () => {
                        this.isUploadFailed = true;
                        this.isFormProcessing = false;
                    },
                });
        });
        
        if(this.isUploadFailed) {
            this.sweetAlertService.error('Error uploading files');
        }
        else {
            this.sweetAlertService.success('Successfully uploaded the files');
        }

        this.bsModalRef.hide();
    }
}
