<div class="d-flex justify-content-center">
    <div class="">
        <webcam
            *ngIf="showWebcam"
            (initError)="handleInitError($event)"
            [trigger]="triggerObservable"
            [width]="500"
            [height]="500"
            (imageCapture)="handleImage($event)"
            [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable"
            [imageQuality]="1"
        ></webcam>
        <div class="col d-flex justify-content-center">
            <button class="btn btn-primary btn-sm m-3" (click)="triggerSnapshot()">Take A Snapshot</button>
            <button
                class="btn btn-primary btn-sm m-3"
                (click)="showNextWebcam(true)"
                [disabled]="!multipleWebcamsAvailable"
            >
                Next Webcam
            </button>
        </div>
    </div>
</div>
