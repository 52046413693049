// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    ICreateIssueDTO,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
    IIssue,
    ILinkCounts,
} from '@data/schema';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class IssueService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IIssue[]> {
        return this.apiService.get<{ data: IIssue[] }>('/issues').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IIssue>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IIssue[]; totalItems: number }>('/issues', params).pipe(
            map((res) => ({
                data: !res.data[0]?.['data'].length ? [] : res.data[0]?.['data'],
                totalItems: res.data[0]?.['data'].length,
                totalFilteredItems: !res.data[0]?.['data'].length ? 0 : res.data[0]?.['total'],
            })),
        );
    }

    getById(id: string): Observable<IIssue> {
        return this.apiService.get<{ data: IIssue }>(`/issues/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateIssueDTO): Observable<IIssue> {
        return this.apiService.post<{ data: IIssue }, ICreateIssueDTO>(`/issues`, context).pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateIssueDTO): Observable<IIssue> {
        return this.apiService
            .put<{ data: IIssue }, ICreateIssueDTO>(`/issues/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IIssue }>(`/issues/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IIssue[] }, { ids: string[] }>(`/issues`, { ids });
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService.get<{ data: ILinkCounts }>(`/issues/${id}/linkCounts`).pipe(map((res) => res.data));
    }

    getByCompanyIdNType(id: string, type: string): Observable<IIssue[]> {
        return this.apiService
            .get<{ data: IIssue[] }>(`/issues/company/${id}/companyType/${type}`)
            .pipe(map((res) => res.data));
    }

    getByCompanyIdNTypeSSR(
        id: string,
        type: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IIssue>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IIssue[]; totalItems: number }>(`/issues/company/${id}/companyType/${type}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByItemId(id: string): Observable<IIssue[]> {
        return this.apiService.get<{ data: IIssue[] }>(`/issues/item/${id}`).pipe(map((res) => res.data));
    }

    getByItemIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IIssue>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IIssue[]; totalItems: number }>(`/issues/item/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByContractId(id: string): Observable<IIssue[]> {
        return this.apiService.get<{ data: IIssue[] }>(`/issues/contract/${id}`).pipe(map((res) => res.data));
    }

    getByContractIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IIssue>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IIssue[]; totalItems: number }>(`/issues/contract/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByServiceId(id: string): Observable<IIssue[]> {
        return this.apiService.get<{ data: IIssue[] }>(`/issues/service/${id}`).pipe(map((res) => res.data));
    }

    getByServiceIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IIssue>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IIssue[]; totalItems: number }>(`/issues/service/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    createWithContractLink(id: string, context: ICreateIssueDTO): Observable<IIssue> {
        return this.apiService
            .post<{ data: IIssue }, ICreateIssueDTO>(`/issues/contract/${id}`, context)
            .pipe(map((res) => res.data));
    }

    createWithServiceLink(id: string, context: ICreateIssueDTO): Observable<IIssue> {
        return this.apiService
            .post<{ data: IIssue }, ICreateIssueDTO>(`/issues/service/${id}`, context)
            .pipe(map((res) => res.data));
    }
    createWithItemLink(id: string, context: ICreateIssueDTO): Observable<IIssue> {
        return this.apiService
            .post<{ data: IIssue }, ICreateIssueDTO>(`/issues/item/${id}`, context)
            .pipe(map((res) => res.data));
    }

    getLinkStatus(ids: string[]): Observable<string> {
        let params = new HttpParams();

        ids.forEach((id: string) => {
            params = params.append(`Ids`, id);
        });

        return this.apiService
            .get<{ data: string }>(`/linkValidation/Issues/linkStatus`, params)
            .pipe(map((res) => res.data));
    }
    unlinkFromCompany(unlinkObject): Observable<IIssue> {
        return this.apiService
            .deleteBatch<{ data: IIssue }, ICreateIssueDTO>(`/issues/company/unlink`, unlinkObject)
            .pipe(map((res) => res.data));
    }
}
