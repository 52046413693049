// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { ITableLayout, ICreateTableLayoutDTO } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class TableLayoutService {
    constructor(private apiService: ApiService) {}

    update(context: ICreateTableLayoutDTO, page: String): Observable<ITableLayout> {
        return this.apiService
            .put<{ data: ITableLayout }, ICreateTableLayoutDTO>(`/tableLayout/${page}`, context)
            .pipe(map((res) => res.data));
    }

    getByPage(page: string): Observable<ITableLayout> {
        return this.apiService.get<{ data: ITableLayout }>(`/tableLayout/${page}`).pipe(map((res) => res.data));
    }
}
