// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IContract,
    IItem,
    ILinkContractsToItem,
    ILinkContractsToService,
    ILinkItemsToContract,
    ILinkServicesToContract,
    IService,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LinkContractItemsService {
    constructor(private apiService: ApiService) {}

    linkItemsToContract(context: ILinkItemsToContract): Observable<IItem[]> {
        return this.apiService
            .post<{ data: IItem[] }, ILinkItemsToContract>('/linkContractItems/link/items', context)
            .pipe(map((res) => res.data));
    }

    linkContractsToItem(context: ILinkContractsToItem): Observable<IContract[]> {
        return this.apiService
            .post<{ data: IContract[] }, ILinkContractsToItem>('/linkContractItems/link/contracts', context)
            .pipe(map((res) => res.data));
    }

    unlinkItemsFromContract(context: ILinkItemsToContract): Observable<IItem[]> {
        return this.apiService
            .deleteBatch<{ data: IItem[] }, ILinkItemsToContract>('/linkContractItems/unlink/items', context)
            .pipe(map((res) => res.data));
    }

    unlinkContractsFromItem(context: ILinkContractsToItem): Observable<IContract[]> {
        return this.apiService
            .deleteBatch<{ data: IContract[] }, ILinkContractsToItem>(
                '/linkContractItems/unlink/contracts',
                context,
            )
            .pipe(map((res) => res.data));
    }
}
