// core
import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';

// states
import { AuthState } from '@data/state';

// services
import { SweetAlertService } from '@app/service';

// schemas

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
    baseUrl: string;

    constructor(
        private store: Store,
        private sweetAlertService: SweetAlertService,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.baseUrl = `${baseUrl}`;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.store.selectSnapshot(AuthState.token);
        const tenantId = this.store.selectSnapshot(AuthState.tenantId);

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    tenantId: `${tenantId}`,
                },
            });
        }

        return next.handle(request).pipe(
            catchError((error) => {
                if (error) {
                    switch (error.status) {
                        case 400:
                            if (request.responseType === 'text' && error.error) {
                                error.error = JSON.parse(error.error);
                            }

                            if (error.error.errors) {
                                const modalStateErrors = [];
                                for (const key in error.error.errors) {
                                    if (error.error.errors[key]) {
                                        modalStateErrors.push(error.error.errors[key]);
                                    }
                                }
                                this.sweetAlertService.error(modalStateErrors.flat().toString());
                                throw modalStateErrors.flat();
                            } else if (typeof error.error === 'object') {
                                this.sweetAlertService.error(error.error.message);
                            } else {
                                this.sweetAlertService.error(error.error);
                            }
                            break;
                        case 401:
                            if (request?.url === `${this.baseUrl}/auth/login`) {
                                this.sweetAlertService.error(error.error.message);
                                break;
                            }

                            this.sweetAlertService.unauthorized(
                                'Your session has ended. Please login again to continue',
                                'Back to sign in',
                            );
                            break;
                        case 404:
                            this.sweetAlertService.error(error.error.message);
                            break;
                        case 412:
                            this.sweetAlertService.error(error.error.message);
                            break;
                        case 409:
                            this.sweetAlertService.error(error.error.message);
                            break;
                        case 500:
                            this.sweetAlertService.error(error.error.message);
                            break;
                        default:
                            this.sweetAlertService.error('Something went wrong');
                            console.log(error);
                            break;
                    }
                }
                return throwError(() => error);
            }),
        );
    }
}
