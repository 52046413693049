// schemas
import {
    ICreateContractDTO,
    ICreateHistoryDTO,
    IGenerateServiceDTO,
    ILinkIssuesToContract,
    ILinkItemsToContract,
    ILinkServicesToContract,
    ICreateTableLayoutDTO,
} from '@data/schema';

// enums
import { ServiceType, LinkedEntityType, LinkedCountType } from '@data/enum';

export class GetContracts {
    static readonly type = '[CONTRACT] GET CONTRACTS';
}

export class GetContract {
    static readonly type = '[CONTRACT] GET CONTRACT';
    constructor(public id: string) {}
}

export class GetLinkCountsForContract {
    static readonly type = '[CONTRACT] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetLinkedDataForContract {
    static readonly type = '[CONTRACT] GET LINKED DATA';
    constructor(
        public id: string,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public itemType?: string,
    ) {}
}

export class GetTableLayoutForContract {
    static readonly type = '[CONTRACT] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class GetNotesForContract {
    static readonly type = '[CONTRACT] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class CreateNoteForContract {
    static readonly type = '[CONTRACT] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class CreateContract {
    static readonly type = '[CONTRACT] CREATE CONTRACT';
    constructor(public payload: ICreateContractDTO) {}
}

export class GenerateService {
    static readonly type = '[CONTRACT] GENERATE SERVICE';
    constructor(public payload: IGenerateServiceDTO) {}
}

export class CreateLinkedItemForContract {
    static readonly type = '[CONTRACT] CREATE LINKED ITEM';
    constructor(
        public id: string,
        public payload: any,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public countType: LinkedCountType,
    ) {}
}

export class LinkNUnlinkServices {
    static readonly type = '[CONTRACT] LINK/UNLINK SERVICES';
    constructor(public payload: ILinkServicesToContract, public isLink: boolean) {}
}

export class LinkNUnlinkIssues {
    static readonly type = '[CONTRACT] LINK/UNLINK ISSUES';
    constructor(public payload: ILinkIssuesToContract, public isLink: boolean) {}
}

export class LinkNUnlinkItems {
    static readonly type = '[CONTRACT] LINK/UNLINK ITEMS';
    constructor(public payload: ILinkItemsToContract, public isLink: boolean) {}
}

export class UpdateContract {
    static readonly type = '[CONTRACT] UPDATE CONTRACT';
    constructor(public id: string, public payload: ICreateContractDTO) {}
}

export class UpdateTableLayoutForContract {
    static readonly type = '[CONTRACT] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class DeleteContract {
    static readonly type = '[CONTRACT] DELETE CONTRACT';
    constructor(public id: string) {}
}

export class DeleteContracts {
    static readonly type = '[CONTRACT] DELETE CONTRACTS';
    constructor(public ids: string[]) {}
}

export class ResetContract {
    static readonly type = '[CONTRACT] RESET CONTRACT';
    constructor() {}
}

export class ResetNotesContract {
    static readonly type = '[CONTRACT] RESET NOTES';
    constructor() {}
}
