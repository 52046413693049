// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IContract,
    IIssue,
    IItem,
    ILinkContractsToIssue,
    ILinkContractsToService,
    ILinkIssuesToContract,
    ILinkIssuesToItem,
    ILinkItemsToIssue,
    ILinkServicesToContract,
    IService,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LinkItemIssuesService {
    constructor(private apiService: ApiService) {}

    linkIssuesToItem(context: ILinkIssuesToItem): Observable<IIssue[]> {
        return this.apiService
            .post<{ data: IIssue[] }, ILinkIssuesToItem>('/linkIssueItems/link/issues', context)
            .pipe(map((res) => res.data));
    }

    linkItemsToIssue(context: ILinkItemsToIssue): Observable<IItem[]> {
        return this.apiService
            .post<{ data: IItem[] }, ILinkItemsToIssue>('/linkIssueItems/link/items', context)
            .pipe(map((res) => res.data));
    }

    unlinkIssuesFromItem(context: ILinkIssuesToItem): Observable<IIssue[]> {
        return this.apiService
            .deleteBatch<{ data: IIssue[] }, ILinkIssuesToItem>('/linkIssueItems/unlink/issues', context)
            .pipe(map((res) => res.data));
    }

    unlinkItemsFromIssue(context: ILinkItemsToIssue): Observable<IItem[]> {
        return this.apiService
            .deleteBatch<{ data: IItem[] }, ILinkItemsToIssue>('/linkIssueItems/unlink/items', context)
            .pipe(map((res) => res.data));
    }
}
