import { GenericStateModel, LinkedItemsStateModel } from '@data/state';
import { IContract, IHistory, IIssue, IItem, ILinkCounts, IService } from '@data/schema';

export interface ContractStateModel extends GenericStateModel<IContract> {
    linkCounts?: ILinkCounts;
    linkedItems?: {
        services?: LinkedItemsStateModel<IService>;
        purchasedItems?: LinkedItemsStateModel<IItem>;
        suppliedItems?: LinkedItemsStateModel<IItem>;
        manufacturedItems?: LinkedItemsStateModel<IItem>;
        issuesAsItemCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsContractCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsContractId?: LinkedItemsStateModel<IIssue>;
        notes: LinkedItemsStateModel<IHistory>;
        items: LinkedItemsStateModel<IItem>;
    };
}
