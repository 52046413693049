<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Item</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="item_modal_form_default">
            <!--  row: form -->
            <div class="row px-4 mt-2">
                <div class="col-8">
                    <!-- form-input: item number -->
                    <app-text-input
                        [formControl]="f['itemNumber']"
                        [label]="'Item#'"
                        testAutomationSelectorName="item_modal_input_item_number"
                    ></app-text-input>
                    <!-- //. form-input: item number -->
                </div>
                <div class="col-4 ps-0 pt-1">
                    <!-- form-button: generate -->
                    <app-spinner-button
                        buttonText="Generate"
                        [isProcessing]="isNumberGenerating"
                        [customClasses]="generateButtonCustomClasses"
                        (onButtonClick)="generate()"
                    ></app-spinner-button>
                    <!-- //. form-button: generate -->
                </div>
                <div class="col-12" *ngIf="entityType !== linkedEntityType.MANUFACTURED_ITEMS">
                    <!-- form-input: manufacturer -->
                    <app-select-input
                        [list]="manufacturers$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        [formControl]="f['manufacturer']"
                        [label]="'Manufacturer'"
                        testAutomationSelectorName="item_modal_select_manufacturer"
                    ></app-select-input>
                    <!-- //. form-input: manufacturer -->
                </div>
                <div class="col-12" *ngIf="entityType !== linkedEntityType.SUPPLIED_ITEMS">
                    <!-- form-input: supplier -->
                    <app-select-input
                        [list]="suppliers$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        [formControl]="f['supplier']"
                        [label]="'Supplier'"
                        testAutomationSelectorName="item_modal_select_supplier"
                    ></app-select-input>
                    <!-- //. form-input: supplier -->
                </div>
                <div class="col-12" *ngIf="entityType !== linkedEntityType.PURCHASED_ITEMS">
                    <!-- form-input: purchaser -->
                    <app-select-input
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        [formControl]="f['purchaser']"
                        [label]="'Purchaser'"
                        testAutomationSelectorName="item_modal_select_purchaser"
                    ></app-select-input>
                    <!-- //. form-input: purchaser -->
                </div>
                <div class="col-12">
                    <!-- form-input: category -->
                    <app-select-input
                        [list]="equipmentCategoriesSource$ | async"
                        [bindField]="'_id'"
                        [labelField]="'name'"
                        [addTag]="true"
                        [addTagCallback]="addCategory"
                        [formControl]="f['category']"
                        [label]="'Category'"
                        testAutomationSelectorName="item_modal_select_category"
                    ></app-select-input>
                    <!-- //. form-input: category -->
                </div>
                <div class="col-12">
                    <!-- form-input: type -->
                    <app-select-input
                        [list]="equipmentTypesSource$ | async"
                        [bindField]="'_id'"
                        [labelField]="'name'"
                        [addTag]="true"
                        [addTagCallback]="addType"
                        [formControl]="f['type']"
                        [label]="'Type'"
                        testAutomationSelectorName="item_modal_select_type"
                    ></app-select-input>
                    <!-- //. form-input: type -->
                </div>
                <div class="col-12">
                    <!-- form-input: factory build date -->
                    <app-text-input
                        [formControl]="f['factoryBuildDate']"
                        [label]="'Factory Build Date'"
                        type="date"
                        testAutomationSelectorName="item_modal_input_factory_build_date"
                    ></app-text-input>
                    <!-- //. form-input: factory build date -->
                </div>
                <div class="col-12">
                    <!-- form-input: factory left date -->
                    <app-text-input
                        [formControl]="f['factoryLeftDate']"
                        [label]="'Factory Left Date'"
                        type="date"
                        testAutomationSelectorName="item_modal_input_factory_left_date"
                    ></app-text-input>
                    <!-- //. form-input: factory left date -->
                </div>
                <div class="col-12">
                    <!-- form-input: location -->
                    <app-select-input
                        [formControl]="f['location']"
                        [list]="locationsSource$ | async"
                        [bindField]="'_id'"
                        [labelField]="'name'"
                        [addTag]="true"
                        [addTagCallback]="addLocation"
                        [label]="'Location'"
                        testAutomationSelectorName="item_modal_input_location"
                    ></app-select-input>
                    <!-- //. form-input: location -->
                </div>
                <div class="col-12">
                    <!-- form-input: location map -->
                    <app-text-input
                        [formControl]="f['locationMap']"
                        [label]="'Location Map'"
                        testAutomationSelectorName="item_modal_input_location_map"
                    ></app-text-input>
                    <!-- //. form-input: itemNumber -->
                </div>
                <div class="col-12">
                    <!-- form-input: serial number -->
                    <app-text-input
                        [formControl]="f['serialNumber']"
                        [label]="'Serial Number'"
                        testAutomationSelectorName="item_modal_input_serial_number"
                    ></app-text-input>
                    <!-- //. form-input: serial number -->
                </div>
                <div class="col-12">
                    <!-- form-input: description -->
                    <app-text-area-input
                        [formControl]="f['description']"
                        [label]="'Description'"
                        testAutomationSelectorName="item_modal_input_description"
                    ></app-text-area-input>
                    <!-- //. form-input: description -->
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
