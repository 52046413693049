// core
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

// schemas
import { IFormCustomClass } from '@data/schema';

@Component({
    selector: 'app-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxInputComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() checked: boolean = false;
    @Input() testAutomationSelectorName: string;
    @Input() customClasses: IFormCustomClass = {
        formGroup: 'form-group',
        label: 'fw-medium text-gray-700 fs-14 d-flex',
        input: 'form-control',
    };
    type = 'checkbox';

    @Output() onCheckBoxChange = new EventEmitter();
    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    writeValue(obj: any): void {
        this.setChecked(!!obj);
    }

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}

    private setChecked(checked: boolean) {
        if (checked === this.checked) {
            return;
        }
        this.checked = checked;
    }

    handleCheckboxChange(event) {
        this.setChecked(event.target.checked);
        this.ngControl.control.setValue(this.checked);
        this.onCheckBoxChange.emit(this.checked);
    }
}
