export enum DataTableActionType {
    CREATE = 'CREATE',
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    ROW_CLICK = 'ROW_CLICK',
    ROW_CLICK_NON_BOUND = 'ROW_CLICK_NON_BOUND',
    LINK = 'LINK',
    UNLINK = 'UNLINK',
    LINK_ITEMS = 'LINK_ITEMS',
    RESET_FILTERS = 'RESET_FILTERS',
    NO_CREATE = 'NO_CREATE',
}

export enum DataTableCellType {
    AVATAR_CELL = 'AVATAR_CELL',
    STATUS_CELL = 'STATUS_CELL',
    LINK_CELL = 'LINK_CELL',
    CHECKBOX_INPUT = 'CHECKBOX_INPUT',
    NUMBER_INPUT = 'NUMBER_INPUT',
    TEXT_INPUT = 'TEXT_INPUT',
    LOOP_CELL = 'LOOP_CELL',
    ROLE_CELL = 'ROLE_CELL',
    ENTITY_CELL = 'ENTITY_CELL',
    BUTTON_CELL = 'BUTTON_CELL',
    DATE_CELL = 'DATE_CELL',
    ROW_SPAN_CELL = 'ROW_SPAN_CELL',
    DESCRIPTION_CELL = 'DESCRIPTION_CELL',
    ACTION_CELL = 'ACTION_CELL',
    NUMBER_CELL = 'NUMBER_CELL',
}

export enum CompanyDataTableCellType {
    PARENT_CELL = 'PARENT_CELL',
    NAME_CELL = 'NAME_CELL',
}

export enum ContactDataTableCellType {
    PARENT_CELL = 'PARENT_CELL',
    FIRST_NAME_CELL = 'FIRST_NAME_CELL',
    PARTNER_LOGIN_CELL = 'PARTNER_LOGIN_CELL',
}

export enum ContractDataTableCellType {
    PROVIDER_CELL = 'PROVIDER_CELL',
    RECIPIENT_CELL = 'RECIPIENT_CELL',
    CONTRACT_NUMBER_CELL = 'CONTRACT_NUMBER_CELL',
}

export enum InstallationDataTableCellType {
    INSTALLATION_NUMBER_CELL = 'INSTALLATION_NUMBER_CELL',
    ITEM_MANUFACTURER_CELL = 'ITEM_MANUFACTURER_CELL',
    ITEM_SUPPLIER_CELL = 'ITEM_SUPPLIER_CELL',
}

export enum ItemDataTableCellType {
    ITEM_NUMBER_CELL = 'ITEM_NUMBER_CELL',
    MANUFACTURER_NAME_CELL = 'MANUFACTURER_NAME_CELL',
    SUPPLIER_NAME_CELL = 'SUPPLIER_NAME_CELL',
    PURCHASER_NAME_CELL = 'PURCHASER_NAME_CELL',
    CHECK_LIST_STATUS_CELL = 'CHECK_LIST_STATUS_CELL',
}

export enum IssueDataTableCellType {
    ISSUE_NUMBER_CELL = 'ISSUE_NUMBER_CELL',
    ITEM_COMPANY_NAME_CELL = 'ITEM_COMPANY_NAME_CELL',
    CONTRACT_COMPANY_NAME_CELL = 'CONTRACT_COMPANY_NAME_CELL',
}

export enum ServiceDataTableCellType {
    SERVICE_NUMBER_CELL = 'SERVICE_NUMBER_CELL',
    SERVICE_CHECKLIST_NAME = 'SERVICE_CHECKLIST_NAME',
    SERVICE_CHECKLIST_STATUS = 'SERVICE_CHECKLIST_STATUS',
}

export enum DataTableExportType {
    EXCEL = 'excel',
    CSV = 'csv',
    PDF = 'pdf',
}

export enum DashboardDataTableCellType {
    CONTRACT_NUMBER = 'CONTRACT_NUMBER',
    CONTRACT_STATUS = 'CONTRACT_STATUS',
    RECIPIENT = 'RECIPIENT',
    PROVIDER = 'PROVIDER',
    TYPE = 'TYPE',
}

export enum DateRangeFilter {
    DATE_RANGE = 'DATE_RANGE',
}

export enum DateFilterType {
    THIS_WEEK = 'thisWeek',
    NEXT_WEEK = 'nextWeek',
    THIS_MONTH = 'thisMonth',
    NEXT_MONTH = 'nextMonth',
    THIS_YEAR = 'thisYear',
    ALL = 'all',
}

export enum CheckListDataTableCellType {
    CHECK_LIST_NAME = 'CHECK_LIST_NAME',
    NO_OF_ITEMS = 'NO_OF_ITEMS',
    STATUS_COUNT = 'STATUS_COUNT',
}
