export enum InputType {
    NUMBER = 'number',
    TEXT = 'text',
    TEXT_AREA = 'textArea',
    CHECKBOX = 'checkbox',
    SINGLE_SELECT = 'singleSelect',
    MULTI_SELECT = 'multiSelect',
    IMAGE = 'image',
    SIGN = 'sign',

}

export enum InputTypeName {
    NUMBER = 'Numeric',
    TEXT = 'Text Short',
    TEXT_AREA = 'Text Long',
    CHECKBOX = 'Checkbox',
    SINGLE_SELECT = 'Single Select',
    MULTI_SELECT = 'Multi Select',
    IMAGE = 'Image',
    SIGN = 'Signature',
}