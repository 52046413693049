// schemas
import { ICreateHistoryDTO } from '@data/schema';

export class CreateUpload {
    static readonly type = '[ITEM] CREATE UPLOAD';
    constructor(public entity: string, public id: string, public file: File) {}
}

export class GetUploads {
    static readonly type = '[UPLOAD] GET UPLOADS';
    constructor(public id: string) {}
}

export class GetNotesForUpload {
    static readonly type = '[UPLOAD] GET NOTES';
    constructor(public id: string) {}
}

export class CreateNoteForUpload {
    static readonly type = '[UPLOAD] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class DeleteUpload {
    static readonly type = '[UPLOAD] DELETE UPLOAD';
    constructor(public id: string) {}
}

export class DeleteUploads {
    static readonly type = '[UPLOAD] DELETE UPLOADS';
    constructor(public ids: string[]) {}
}

export class ResetUploads {
    static readonly type = '[UPLOAD] RESET UPLOADS';
    constructor() {}
}

