//core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

// services
import { ApiService } from '@app/service';

// schema
import {
    ICheckList,
    ICheckListItem,
    ICreateCheckListDTO,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class CheckListService {
    constructor(private apiService: ApiService) {}

    create(context: any): Observable<ICheckList> {
        return this.apiService
            .post<{ data: ICheckList }, ICreateCheckListDTO>(`/checklist-templates`, context)
            .pipe(map((res) => res.data));
    }

    get(): Observable<ICheckList[]> {
        return this.apiService.get<{ data: ICheckList[] }>('/checklist-templates/service').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<ICheckList>> {
        let params = new HttpParams()
            .append('limit', `${context.limit}`)
            .append('skip', `${context.skip}`)
            .append('sort', `${context.order}`)
            .append('search', `${context.search}`);

        return this.apiService.get<{ data: ICheckList[]; totalItems: number }>('/checklist-templates', params).pipe(
            map((res) => ({
                data: !res.data[0]?.['data'].length ? [] : res.data[0]?.['data'],
                totalItems: res.data[0]?.['data'].length,
                totalFilteredItems: !res.data[0]?.['data'].length ? 0 : res.data[0]?.['total'],
            })),
        );
    }

    getById(id: string): Observable<ICheckListItem[]> {
        return this.apiService.get<{ data: ICheckListItem[] }>(`/checklist-field/${id}`).pipe(map((res) => res.data));
    }

    getByServiceId(id: string): Observable<any> {
        return this.apiService.get<{ data: any }>(`/linkServiceItems/template/${id}`).pipe(map((res) => res.data));
    }

    getByServiceIdSSR(id: string, context: IDataTablePaginationDTO): Observable<any> {
        let params = new HttpParams()
            .append('limit', `${context.limit}`)
            .append('skip', `${context.skip}`)
            .append('sort', `${context.order}`)
            .append('search', `${context.search}`);
        return this.apiService
            .get<{ data: any[]; totalItems: number }>(`/linkServiceItems/template/${id}`, params)
            .pipe(
                map((res) => ({
                    data: res.data?.['template'] ? [res.data] : [],
                    totalItems: res.data ? 1 : 0,
                    totalFilteredItems: 1,
                })),
            );
    }

    edit(id: string, context: any): Observable<ICheckList> {
        return this.apiService
            .put<{ data: ICheckList }, ICreateCheckListDTO>(`/checklist-templates/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: ICheckList }>(`/checklist-templates/${id}`);
    }

    deleteValidation(id: string) {
        return this.apiService
            .delete<{ data: string }>(`/checklist-templates/checkLink/${id}`)
            .pipe(map((res) => res.data));
    }
}
