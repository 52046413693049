// core
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

// enums
import { UserRole } from '@data/enum';

// schemas
import { IButtonCustomClass, IHistory, IUser } from '@data/schema';

// states
import { AuthState, ProfileState } from '@data/state';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
    @Select(AuthState.currentUser) currentUser$: Observable<IUser>;
    @Select(ProfileState.getProfileImage) avatarUrl$: Observable<any>;

    @Input() itemCount: number;
    @Input() data$: Observable<IHistory[]>;
    @Input() dataLoading$: Observable<boolean>;
    @Input() borderClass: string;
    @Input() title: string;
    @Input() isOpen: boolean = false;
    @Input() isFormProcessing: boolean;
    @Input() userRole: UserRole;

    @Output()
    onIsOpenChange = new EventEmitter();
    @Output() onNoteSubmit = new EventEmitter();
    @Output() showMoreOnClick = new EventEmitter();

    form: FormGroup;

    userRoleType = UserRole;

    buttonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-link text-decoration-none float-end mt-1 fs-16',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(private formBuilder: FormBuilder) {
        this.initializeForm();
    }

    ngOnInit() {}

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            note: ['', Validators.required],
        });
    }

    get f() {
        return this.form.controls;
    }

    async add() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        this.onNoteSubmit.emit(this.form.value.note);

        this.form.reset();
    }

    onChange($event) {
        this.onIsOpenChange.emit($event);
    }

    public showMoreRecords(totalRecords: number) {
        this.showMoreOnClick.emit(totalRecords);
    }
}
