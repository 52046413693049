import { Component, OnDestroy } from '@angular/core';
import { LayoutService, ResizeService } from '@app/service';
import { delay, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnDestroy {
    onDestroy$: Subject<void> = new Subject();

    constructor(public layoutService: LayoutService, private resizeService: ResizeService) {
        this.resizeService.onResize$
            .pipe(delay(0))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((size) => {
                this.layoutService.toggleSidebarExpandedState(size < 4 ? false : true);
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
