// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Title } from '@angular/platform-browser';

// states
import { AuthState, Logout } from '@data/state';
import { Observable, Subject, takeUntil } from 'rxjs';

// services

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @Select(AuthState.tenant) companyName$: Observable<string>;

    onDestroy$: Subject<void> = new Subject();

    title = 'beast-ui';
    companyName: string;

    constructor(private router: Router, private store: Store, private actions: Actions, private titleService: Title) {}

    ngOnInit(): void {
        this.companyName$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) =>
                this.titleService.setTitle(
                    'BEAST' + (res?.['companyName'] == undefined ? '' : ' - ' + res?.['companyName']),
                ),
            );
        this.redirectOnLogout();
        this.multiTabLogout();
    }

    redirectOnLogout() {
        this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
            this.router.navigate(['auth/login']);
        });
    }

    multiTabLogout() {
        window.addEventListener('storage', (event) => {
            if (event.storageArea === localStorage) {
                const user = localStorage.getItem('auth');
                if (!user) {
                    this.store.dispatch(new Logout());
                }
            }
        });
    }
}
