// core
import { getActionTypeFromInstance } from '@ngxs/store';

// actions
import { Logout } from '../auth/auth.actions';

// states
import { GlobalStateModel } from '../global/global-state.model';

export function logoutPlugin(state: GlobalStateModel, action, next) {
    if (getActionTypeFromInstance(action) === Logout.type) {
        state = {
            auth: null,
            companies: { items: { data: [], loading: true } },
            contacts: { items: { data: [], loading: true } },
            contracts: { items: { data: [], loading: true } },
            contractTypes: { items: { data: [], loading: true } },
            equipmentCategories: { items: { data: [], loading: true } },
            equipmentTypes: { items: { data: [], loading: true } },
            installations: { items: { data: [], loading: true } },
            issue: { items: { data: [], loading: true } },
            issueTypes: { items: { data: [], loading: true } },
            items: { items: { data: [], loading: true } },
            services: { items: { data: [], loading: true }, checkList: { data: [] } },
            checkLists: { items: { data: [], loading: true } },
            profile: { items: { profileLink: null, loading: true } },
            uploads: { items: { data: [], loading: true } },
        };
    }
    return next(state, action);
}
