<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Issue</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="issue_modal_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-8">
                    <!-- form-input: issue number -->
                    <app-text-input
                        [formControl]="f['issueNumber']"
                        [label]="'Issue#'"
                        testAutomationSelectorName="issue_modal_input_issue_number"
                    ></app-text-input>
                    <!-- //. form-input: issue number -->
                </div>
                <div class="col-4 ps-0 pt-1">
                    <!-- form-button: generate -->
                    <app-spinner-button
                        buttonText="Generate"
                        [isProcessing]="isNumberGenerating"
                        [customClasses]="generateButtonCustomClasses"
                        (onButtonClick)="generate()"
                    ></app-spinner-button>
                    <!-- //. form-button: generate -->
                </div>
                <div class="col-12">
                    <!-- form-input: status -->
                    <app-select-input
                        [list]="issueStatus"
                        labelField="name"
                        bindField="value"
                        [formControl]="f['status']"
                        [label]="'Status'"
                        testAutomationSelectorName="issue_modal_select_status"
                    ></app-select-input>
                    <!-- //. form-input: status -->
                </div>
                <div class="col-12">
                    <!-- form-input: issue type -->
                    <app-select-input
                        [formControl]="f['issueType']"
                        [label]="'Issue Type'"
                        [list]="issueTypesSource$ | async"
                        [addTag]="true"
                        [addTagCallback]="addType"
                        [bindField]="'_id'"
                        [labelField]="'name'"
                        testAutomationSelectorName="contract_modal_select_issue_type"
                    ></app-select-input>
                    <!-- //. form-input: issue type -->
                </div>
                <div class="col-12" *ngIf="entityType !== linkedEntityType.ISSUES_AS_ITEM_COMPANY">
                    <!-- form-input: item company -->
                    <app-select-input
                        [formControl]="f['itemCompany']"
                        [label]="'Item Company'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="contract_modal_select_item_company"
                    ></app-select-input>
                    <!-- //. form-input: item company -->
                </div>
                <div class="col-12" *ngIf="entityType !== linkedEntityType.ISSUES_AS_CONTRACT_COMPANY">
                    <!-- form-input: contract company -->
                    <app-select-input
                        [formControl]="f['contractCompany']"
                        [label]="'Contract Company'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="contract_modal_select_contract_company"
                    ></app-select-input>
                    <!-- //. form-input: contract company -->
                </div>
                <div class="col-12">
                    <!-- form-input: reported date -->
                    <app-text-input
                        [formControl]="f['reportedDate']"
                        [label]="'Reported Date'"
                        type="date"
                        testAutomationSelectorName="issue_modal_input_reported_date"
                    ></app-text-input>
                    <!-- //. form-input: reported date -->
                </div>
                <div class="col-12">
                    <!-- form-input: resolved date -->
                    <app-text-input
                        [formControl]="f['resolvedDate']"
                        [label]="'Resolved Date'"
                        type="date"
                        testAutomationSelectorName="issue_modal_input_resolved_date"
                    ></app-text-input>
                    <!-- //. form-input: resolved date -->
                </div>
                <div class="col-12">
                    <!-- form-input: description -->
                    <app-text-area-input
                        [formControl]="f['description']"
                        [label]="'Description'"
                        testAutomationSelectorName="issue_modal_input_description"
                    ></app-text-area-input>
                    <!-- //. form-input: description -->
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
