<div [class]="customClasses.formGroup">
    <div class="btn-group" btnRadioGroup [formControl]="ngControl.control">
        <label
            *ngFor="let value of values"
            [btnRadio]="value.value"
            [class]="customClasses.label"
            [class.btn-outline-danger]="ngControl.touched && ngControl.invalid"
            [attr.data-qa]="testAutomationSelectorName + '_' + value.value"
            >{{ value.name ? value.name.replace('_', ' ') : (value.value.replace('_', ' ') | titlecase) }}</label
        >
    </div>
</div>
<div
    *ngIf="ngControl.touched && ngControl.invalid"
    class="invalid-feedback d-block"
    [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required'"
>
    Please select the {{ label }}
</div>
