// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// schemas
import { IButtonCustomClass } from '@data/schema';

// enums
import { DataTableCellType } from '@data/enum';


@Component({
    selector: 'app-edit-layout-modal',
    templateUrl: './edit-layout-modal.component.html',
    styleUrls: ['./edit-layout-modal.component.scss'],
})
export class EditLayoutModalComponent implements OnInit {
    @Output() onApply = new EventEmitter();
    dataTableCols: DataTables.ColumnSettings[];
    initialDataTableCols: DataTables.ColumnSettings[];
    checkBoxRemovedCols: DataTables.ColumnSettings[];
    removedCol: DataTables.ColumnSettings[];

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(public bsModalRef: BsModalRef) {}

    ngOnInit() {
        this.checkBoxRemovedCols = JSON.parse(JSON.stringify(this.dataTableCols));
        const checkBoxIndex = this.dataTableCols.findIndex((x) => x.type === DataTableCellType.CHECKBOX_INPUT);
        if (checkBoxIndex > -1) this.removedCol = this.checkBoxRemovedCols.splice(checkBoxIndex, 1);
    }

    onToggleVisibility($event, columnName: string) {
        if (!$event.target.checked) {
            const index = this.checkBoxRemovedCols.findIndex((x) => x.title === columnName);
            if (index > -1) this.checkBoxRemovedCols[index] = { ...this.checkBoxRemovedCols[index], visible: false };
        } else {
            const index = this.checkBoxRemovedCols.findIndex((x) => x.title === columnName);
            if (index > -1) {
                const { visible, ...reset } = this.checkBoxRemovedCols[index];
                this.checkBoxRemovedCols[index] = { ...reset };
            }
        }
    }

    reset() {
        this.checkBoxRemovedCols = JSON.parse(JSON.stringify(this.initialDataTableCols));
        const checkBoxIndex = this.initialDataTableCols.findIndex((x) => x.type === DataTableCellType.CHECKBOX_INPUT);
        if (checkBoxIndex > -1) this.removedCol = this.checkBoxRemovedCols.splice(checkBoxIndex, 1);
    }

    apply() {
        this.onApply.emit(this.removedCol.concat(this.checkBoxRemovedCols));
    }

    drop(event: CdkDragDrop<any>) {
        moveItemInArray(this.checkBoxRemovedCols, event.previousIndex, event.currentIndex);
    }
}
