// schemas
import {
    ICreateHistoryDTO,
    ICreateIssueDTO,
    ICreateTableLayoutDTO,
    ILinkContractsToIssue,
    ILinkServicesToIssue,
    ILinkItemsToIssue,
} from '@data/schema';

// enums
import { ServiceType, LinkedEntityType, LinkedCountType } from '@data/enum';

export class GetIssues {
    static readonly type = '[ISSUE] GET ISSUES';
}

export class GetIssue {
    static readonly type = '[ISSUE] GET ISSUE';
    constructor(public id: string) {}
}

export class GetLinkCountsForIssue {
    static readonly type = '[ISSUE] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetLinkedDataForIssue {
    static readonly type = '[ISSUE] GET LINKED DATA';
    constructor(
        public id: string,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public itemType?: string,
    ) {}
}

export class GetNotesForIssue {
    static readonly type = '[ISSUE] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class GetTableLayoutForIssue {
    static readonly type = '[ISSUE] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class CreateNoteForIssue {
    static readonly type = '[ISSUE] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class CreateIssue {
    static readonly type = '[ISSUE] CREATE ISSUE';
    constructor(public payload: ICreateIssueDTO) {}
}

export class CreateLinkedItemForIssue {
    static readonly type = '[ISSUE] CREATE LINKED ITEM';
    constructor(
        public id: string,
        public payload: any,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public countType: LinkedCountType,
    ) {}
}

export class UpdateIssue {
    static readonly type = '[ISSUE] UPDATE ISSUE';
    constructor(public id: string, public payload: ICreateIssueDTO) {}
}

export class UpdateTableLayoutForIssue {
    static readonly type = '[ISSUE] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class DeleteIssue {
    static readonly type = '[ISSUE] DELETE ISSUE';
    constructor(public id: string) {}
}

export class DeleteIssues {
    static readonly type = '[ISSUE] DELETE ISSUES';
    constructor(public ids: string[]) {}
}

export class LinkNUnlinkContractsInIssues {
    static readonly type = '[ISSUE] LINK/UNLINK CONTRACTS';
    constructor(public payload: ILinkContractsToIssue, public isLink: boolean) {}
}

export class LinkNUnlinkServicesInIssues {
    static readonly type = '[ISSUE] LINK/UNLINK  SERVICES';
    constructor(public payload: ILinkServicesToIssue, public isLink: boolean) {}
}

export class LinkNUnlinkItemsInIssues {
    static readonly type = '[ISSUE] LINK/UNLINK ITEMS';
    constructor(public payload: ILinkItemsToIssue, public isLink: boolean) {}
}

export class ResetIssue {
    static readonly type = '[ISSUE] RESET ISSUE';
    constructor() {}
}

export class ResetNotesIssue {
    static readonly type = '[ISSUE] RESET NOTES';
    constructor() {}
}
