<!--side nav web-->
<app-side-nav></app-side-nav>
<!--//side nav web-->

<!--side nav mobile-->
<app-side-nav-mobile></app-side-nav-mobile>
<!--//side nav mobile-->

<div
    class="container-fluid sidebar-space p-0"
    [class.expanded]="layoutService.isSidebarExpanded$ | async"
    [class.collapsed]="(layoutService.isSidebarExpanded$ | async) === false"
>
    <app-header-nav></app-header-nav>

    <main>
        <!-- outlet - GENERAL -->
        <router-outlet></router-outlet>
        <!-- //. outlet - GENERAL -->
    </main>
</div>
<app-size-detector></app-size-detector>
