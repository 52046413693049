import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import {
    NgTypeheadInputOptionGroupTemplateDirective,
    NgTypeheadInputOptionTemplateDirective,
} from '@shared/directive/form-template.directive';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent {
    @Input() list$: Observable<any[]>;
    @Input() labelField = '';
    @Input() bindField = '';
    @Input() minLengthTerm = 0;
    @Input() isLoading = false;
    @Input() isOpen;
    @Input() searchInput$ = new Subject<string>();
    @Input() customClasses = '';
    @Input() placeholder = '';
    @Input() groupBy = '';

    @Output() onTypeheadClear = new EventEmitter();

    // custom templates
    @ContentChild(NgTypeheadInputOptionTemplateDirective, { read: TemplateRef })
    optionTemplate: TemplateRef<any>;
    @ContentChild(NgTypeheadInputOptionGroupTemplateDirective, { read: TemplateRef })
    optionGroupTemplate: TemplateRef<any>;

    constructor() {}

    onClear() {
        this.onTypeheadClear.emit();
    }
}
