export enum LinkedEntityType {
    COMPANIES = 'companies',
    CONTACTS = 'contacts',
    CONTRACT_AS_RECIPIENT = 'contractsAsRecipient',
    CONTRACT_AS_PROVIDER = 'contractsAsProvider',
    PURCHASED_ITEMS = 'purchasedItems',
    SUPPLIED_ITEMS = 'suppliedItems',
    MANUFACTURED_ITEMS = 'manufacturedItems',
    ISSUES_AS_ITEM_COMPANY = 'issuesAsItemCompany',
    ISSUES_AS_CONTRACT_COMPANY = 'issuesAsContractCompany',
    ISSUES_AS_ITEM_ID = 'issuesAsItemId',
    ISSUES_AS_CONTRACT_ID = 'issuesAsContractId',
    ISSUES_AS_SERVICE_ID = 'issuesAsServiceId',
    SERVICES = 'services',
    CONTRACTS = 'contracts',
    ITEMS = 'items',
}
