// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateCheckListItem,
    CreateCheckListTemplate,
    DeleteCheckListTemplate,
    GetCheckList,
    RemoveCheckListItem,
    ResetCheckList,
    UpdateCheckListItem,
    UpdateCheckListLayout,
    UpdateCheckListTemplate,
} from './check-list.actions';

// services
import { CheckListService } from '@data/service';

// states
import { CheckListStateModel } from '@data/state';

@State<CheckListStateModel>({
    name: 'checkLists',
    defaults: {
        items: {
            data: [],
            loading: true,
        },
    },
})
@Injectable()
export class CheckListState {
    constructor(private checkListService: CheckListService) {}

    @Selector()
    static getCheckLists(state: CheckListStateModel) {
        return state.items.data;
    }

    @Selector()
    static getCheckListItem(state: CheckListStateModel) {
        return state.items.data;
    }

    @Selector()
    static getCurrentCheckList(state: CheckListStateModel) {
        return state.items?.data;
    }

    @Action(GetCheckList)
    getCheckList({ getState, setState }: StateContext<CheckListStateModel>, { id }: GetCheckList) {
        const state = getState();

        return this.checkListService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(CreateCheckListItem)
    createCheckListItem({ getState, patchState }: StateContext<CheckListStateModel>, { payload }: CreateCheckListItem) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [...state.items.data, payload],
                loading: false,
                retrievedAt: Date.now(),
            },
        });
    }

    @Action(CreateCheckListTemplate)
    createCheckListTemplate({ getState }: StateContext<CheckListStateModel>, { name }: CreateCheckListTemplate) {
        const state = getState();

        if (state.items.data.length === 0) {
            throw 'no template values available';
        }

        const checkListTemplate = {
            checkListName: name,
            listItem: state.items.data,
        };

        return this.checkListService.create(checkListTemplate).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateCheckListTemplate)
    updateCheckListTemplate({ getState }: StateContext<CheckListStateModel>, { id, name }: UpdateCheckListTemplate) {
        const state = getState();

        if (state.items.data.length === 0) {
            throw 'no template values available';
        }

        const checkListTemplate = {
            checkListName: name,
            listItem: state.items.data,
        };

        return this.checkListService.edit(id, checkListTemplate).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateCheckListItem)
    updateCheckListItem(
        { getState, setState }: StateContext<CheckListStateModel>,
        { index, payload }: UpdateCheckListItem,
    ) {
        const state = getState();

        const currentList = [...state.items.data];

        if (typeof index === 'string') {
            const objIndex = currentList.findIndex((obj) => obj._id == index);
            currentList[objIndex] = payload;
        } else {
            currentList[index] = payload;
        }

        setState({
            ...state,
            items: {
                ...state.items,
                data: currentList,
            },
        });
    }

    @Action(UpdateCheckListLayout)
    updateCheckListLayout(
        { getState, setState }: StateContext<CheckListStateModel>,
        { payload }: UpdateCheckListLayout,
    ) {
        const state = getState();

        setState({
            ...state,
            items: {
                ...state.items,
                data: payload,
            },
        });
    }

    @Action(DeleteCheckListTemplate)
    deleteCheckListTemplate(
        { getState, setState }: StateContext<CheckListStateModel>,
        { id }: DeleteCheckListTemplate,
    ) {
        return this.checkListService.delete(id).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(RemoveCheckListItem)
    removeCheckListItem({ getState, setState }: StateContext<CheckListStateModel>, { index }: RemoveCheckListItem) {
        const state = getState();
        const filteredList = state.items.data.filter((item, i) => i !== index);

        setState({
            ...state,
            items: {
                ...state.items,
                data: filteredList,
            },
        });
    }

    @Action(ResetCheckList)
    resetCheckList({ getState, patchState }: StateContext<CheckListStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }
}
