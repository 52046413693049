// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { ISequence } from '@data/schema';

// enums
import { ServiceType } from '@data/enum';

@Injectable({
    providedIn: 'root',
})
export class SequenceService {
    constructor(private apiService: ApiService) {}

    get(type: ServiceType): Observable<ISequence> {
        return this.apiService.get<{ data: ISequence }>(`/sequences/${type}`).pipe(map((res) => res.data));
    }
}
