// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { IEquipmentType, ICreateEquipmentTypeDTO } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class EquipmentTypeService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IEquipmentType[]> {
        return this.apiService.get<{ data: IEquipmentType[] }>('/equipmentTypes').pipe(map((res) => res.data));
    }

    getById(id: string): Observable<IEquipmentType> {
        return this.apiService.get<{ data: IEquipmentType }>(`/equipmentTypes/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateEquipmentTypeDTO): Observable<IEquipmentType> {
        return this.apiService
            .post<{ data: IEquipmentType }, ICreateEquipmentTypeDTO>(`/equipmentTypes`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateEquipmentTypeDTO): Observable<IEquipmentType> {
        return this.apiService
            .put<{ data: IEquipmentType }, ICreateEquipmentTypeDTO>(`/equipmentTypes/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IEquipmentType }>(`/equipmentTypes/${id}`);
    }

    getAssignedById(id: string): Observable<IEquipmentType> {
        return this.apiService.get(`/equipmentTypes/${id}/assignedItems`);
    }
}
