// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { IIssueType, ICreateIssueTypeDTO } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class IssueTypeService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IIssueType[]> {
        return this.apiService.get<{ data: IIssueType[] }>('/issueTypes').pipe(map((res) => res.data));
    }

    getById(id: string): Observable<IIssueType> {
        return this.apiService.get<{ data: IIssueType }>(`/issueTypes/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateIssueTypeDTO): Observable<IIssueType> {
        return this.apiService
            .post<{ data: IIssueType }, ICreateIssueTypeDTO>(`/issueTypes`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateIssueTypeDTO): Observable<IIssueType> {
        return this.apiService
            .put<{ data: IIssueType }, ICreateIssueTypeDTO>(`/issueTypes/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IIssueType }>(`/issueTypes/${id}`);
    }

    getAssignedById(id: string): Observable<IIssueType> {
        return this.apiService.get(`/issueTypes/${id}/assignedIssues`);
    }
}
