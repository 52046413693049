import { Component, OnInit } from '@angular/core';
import { SideNavComponent } from '@layout/side-nav/side-nav.component';

@Component({
    selector: 'app-side-nav-mobile',
    templateUrl: './side-nav-mobile.component.html',
    styleUrls: ['./side-nav-mobile.component.scss'],
})
export class SideNavMobileComponent extends SideNavComponent {
    sideNavClose() {
        this.layoutService.toggleSidebarExpandedState(false);
    }
}
