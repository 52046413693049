<!-- input: base -->
<div *ngIf="!inputCalendarIconEnabled && !inputFloatingLabelEnabled" [class]="customClasses.formGroup">
    <label [class]="customClasses.label">{{ label }}</label>
    <ng-template [ngTemplateOutlet]="dataRangePickerInput"></ng-template>
    <ng-template [ngTemplateOutlet]="dataRangePickerErrors"></ng-template>
</div>
<!-- //. input: base -->

<!-- input: calendar-icon -->
<div *ngIf="inputCalendarIconEnabled && !inputFloatingLabelEnabled">
    <div>
        <label [class]="customClasses.label">{{ label }}</label>
    </div>
    <div>
        <div [class]="customClasses.formGroup" class="has-calendar">
            <span class="mdi mdi-calendar-month-outline fs-22 form-control-feedback"></span>
            <ng-template [ngTemplateOutlet]="dataRangePickerInput"></ng-template>
        </div>
        <ng-template [ngTemplateOutlet]="dataRangePickerErrors"></ng-template>
    </div>
</div>
<!-- //. input: calendar-icon -->

<!-- input: floating-label -->
<div *ngIf="!inputCalendarIconEnabled && inputFloatingLabelEnabled" class="row">
    <div class="col my-auto">
        <div [class]="customClasses.formGroup" class="has-floating-label">
            <label [class]="customClasses.label" class="form-control-feedback">{{ label }}</label>
            <ng-template [ngTemplateOutlet]="dataRangePickerInput"></ng-template>
        </div>
        <ng-template [ngTemplateOutlet]="dataRangePickerErrors"></ng-template>
    </div>
</div>
<!-- //. input: floating-label -->

<!-- templates -->
<!-- tmpl: data-range-picker -->
<ng-template #dataRangePickerInput>
    <input
        [(ngModel)]="ngModel"
        (ngModelChange)="onChange($event)"
        type="text"
        class="form-control form-control-sm"
        placeholder="{{ placeholder }}"
        bsDaterangepicker
        [bsConfig]="bsConfig"
        [placement]="placement"
    />
    <i class="bi bi-calendar3-event"></i>
</ng-template>
<!-- //. tmpl: data-range-picker -->

<!-- tmpl: data-range-picker-errors -->
<ng-template #dataRangePickerErrors>
    <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">{{ label }} is required</div>
</ng-template>
<!-- //. tmpl: data-range-picker-errors -->

<!-- //. templates -->
