import { IContract, IHistory, IInstallation, IIssue, IItem, ILinkCounts, IService, IUpload } from '@data/schema';
import { GenericStateModel, LinkedItemsStateModel } from '@data/state';

export interface ItemStateModel extends GenericStateModel<IItem> {
    linkCounts?: ILinkCounts;
    linkedItems?: {
        services?: LinkedItemsStateModel<IService>;
        installations?: LinkedItemsStateModel<IInstallation>;
        contractsAsRecipient?: LinkedItemsStateModel<IContract>;
        contractsAsProvider?: LinkedItemsStateModel<IContract>;
        issuesAsItemCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsContractCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsItemId?: LinkedItemsStateModel<IIssue>;
        notes: LinkedItemsStateModel<IHistory>;
        uploads: LinkedItemsStateModel<IUpload>;
        contracts?: LinkedItemsStateModel<IContract>;
    };
}
