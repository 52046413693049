// core
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import { CreateIssueType, DeleteIssueType, GetIssueType, GetIssueTypes, UpdateIssueType } from './issue-type.actions';

// services
import { IssueTypeService } from '@data/service';

// schemas
import { IssueTypeStateModel } from './issue-type-state.model';

@State<IssueTypeStateModel>({
    name: 'issueTypes',
    defaults: {
        items: {
            data: [],
            loading: true,
        },
    },
})
@Injectable()
export class IssueTypeState {
    constructor(private issueService: IssueTypeService, private store: Store) {}

    @Selector()
    static getIssueTypeList(state: IssueTypeStateModel) {
        return state.items.data;
    }

    @Selector()
    static getIssueTypeListLoading(state: IssueTypeStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentIssueType(state: IssueTypeStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getNewIssueType(state: IssueTypeStateModel) {
        return state.newItem?.data;
    }

    @Action(GetIssueTypes)
    getIssueTypes({ getState, setState }: StateContext<IssueTypeStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.issueService.get().pipe(
            tap((response) => {
                setState({
                    ...state,
                    items: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetIssueType)
    getIssueType({ getState, setState }: StateContext<IssueTypeStateModel>, { id }: GetIssueType) {
        const state = getState();
        const currentItem = state.items.data.find((item) => item._id === id);

        if (currentItem) {
            return setState({
                ...state,
                currentItem: {
                    data: currentItem,
                    loading: false,
                    retrievedAt: Date.now(),
                },
            });
        }

        return this.issueService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(CreateIssueType)
    createIssueType({ getState, patchState }: StateContext<IssueTypeStateModel>, { payload }: CreateIssueType) {
        return this.issueService.create(payload).pipe(
            tap((response) => {
                const state = getState();

                patchState({
                    items: {
                        ...state.items,
                        data: [...state.items.data, response],
                    },
                    newItem: {
                        data: response,
                    },
                });
            }),
        );
    }

    @Action(UpdateIssueType)
    updateIssueType({ getState, setState }: StateContext<IssueTypeStateModel>, { id, payload }: UpdateIssueType) {
        return this.issueService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });
            }),
        );
    }

    @Action(DeleteIssueType)
    deleteIssueType({ getState, setState }: StateContext<IssueTypeStateModel>, { id }: DeleteIssueType) {
        return this.issueService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }
}
