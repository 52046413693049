// core
import { NgModule } from '@angular/core';

// bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';

// angular-material
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        ProgressbarModule.forRoot(),
        PopoverModule.forRoot(),
    ],
    exports: [
        BsDropdownModule,
        AccordionModule,
        ModalModule,
        ButtonsModule,
        TabsModule,
        TooltipModule,
        ProgressbarModule,
        PopoverModule,
        DragDropModule,
    ],
})
export class BootstrapModule {}
