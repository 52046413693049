<div class="d-flex justify-content-center my-4 mx-4">
    <div>
        <signature-pad
            #signature
            [options]="signaturePadOptions"
            class="bg-white"
            (drawStart)="drawStart($event)"
            (drawEnd)="drawComplete($event)"
        ></signature-pad>

        <div class="col d-flex justify-content-center">
            <button class="btn btn-primary btn-sm m-3" (click)="submit()">Submit</button>
            <button class="btn border-primary text-primary btn-sm m-3" (click)="clear()">Clear</button>
        </div>
    </div>
</div>
