// core
import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Papa } from 'ngx-papaparse';
import xlsx from 'json-as-xlsx';
import flatten from 'flat';

// directives
import {
    NgDataTableCellTemplateDirective,
    NgDataTableDropdownTemplateDirective,
    NgDataTableExportTemplateDirective,
    NgDataTableFooterTemplateDirective,
    NgDataTableInnerFooterTemplateDirective,
    NgDataTableRowTemplateDirective,
    NgDataTableSubtitleTemplateDirective,
} from '@shared/directive/data-table-template.directive';

// services
import { DataTableService, HelperService, SweetAlertService } from '@app/service';

// schemas
import {
    IDataTableCustomClass,
    IDataTableFilter,
    IDataTableFilterValues,
    IDataTableInfo,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
    IFormCustomClass,
    ITableLayout,
} from '@data/schema';

// enums
import { DataTableActionType, DataTableCellType, DataTableExportType, UserRole } from '@data/enum';
import { DateFilterType, DateRangeFilter } from '@data/enum/data-table';

// components
import { ExportListModalComponent } from './export-list-modal/export-list-modal.component';
import { EditLayoutModalComponent } from './edit-layout-modal/edit-layout-modal.component';
import { FilterLayoutModalComponent } from './filter-layout-modal/filter-layout-modal.component';

// util
import { fiveYears } from '@shared/util';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterViewInit, OnDestroy {
    _dataTableSourceLoading = true;

    get dataTableSourceLoading(): boolean {
        return this._dataTableSourceLoading;
    }

    @Input() set dataTableSourceLoading(value: boolean) {
        this._dataTableSourceLoading = value;

        if (!value) {
            setTimeout(() => {
                if (!this.dtTrigger.closed) {
                    this.dtTrigger.next();
                }
            });
        }
    }
    @Input() set dataTableColsImmutable(value: DataTables.ColumnSettings[]) {
        if (value && Array.isArray(value)) {
            if (!this.dataTableCols) {
                this.dataTableCols = JSON.parse(JSON.stringify(value));
            } else if (JSON.stringify(this.dataTableCols) !== JSON.stringify(value)) {
                this.persistDataTableColumnChanges(value);
            }
        }
    }
    @Input() dataTableSource: any[];
    @Input() userRole: UserRole;
    @Input() dataTableInfo: IDataTableInfo;
    @Input() dataTableCols: DataTables.ColumnSettings[];
    @Input() dataTableDefaultOrder: any[] = [[1, 'asc']];
    @Input() dataTableActionButtonEnabled = true;
    @Input() linkedDataTableActionButtonEnabled = false;
    @Input() linkedDataTableActionButtons = [];
    @Input() linkItemDataTableActionButtonEnabled = false;
    @Input() dataTableActionButtonType = DataTableActionType.CREATE;
    @Input() dataTableActionButtonProcessing = false;
    @Input() dataTableActionButtonDisabledState = false;
    @Input() dataTablePagingEnabled = true;
    @Input() dataTableInfoEnabled = true;
    @Input() dataTableDropdownClass = '';
    @Input() dataTableEnableSearch = true;
    @Input() dataTableTemplateSearch = false;
    @Input() dataTableSelectAllCheckBoxEnabled = false;
    @Input() dataTableEnableStateSave = false;
    @Input() dataTableStateSaveKey: string;
    @Input() dataTableSearchType: string = 'simple';
    @Input() dataTableEnableServerSide = false;
    @Input() dataTableServerSideCallbackFn: (
        pagination: IDataTablePaginationDTO,
    ) => Observable<IDataTablePaginationResponse>;
    @Input() dataTableServerSideUrl: string;
    @Input() dataTableEnableExports = false;
    @Input() dataTableTemplateExports = false;
    @Input() dataTableEnableFixedHeader = true;
    @Input() dataTableEnableFilters = false;
    @Input() dataTableTemplateFilters = false;
    @Input() dataTableStripSpecialCharacters = false;
    @Input() dataTableFilters: IDataTableFilter[] = [];
    @Input() dataTableLengthMenu = [
        [10, 20, 50, 100],
        ['10 / page', '20 / page', '50 / page', '100 / page'],
    ];
    @Input() customClasses: IDataTableCustomClass = {
        table: 'table table-bordered table-responsive-sm datatable-wrapper w-100',
    };
    @Input() testAutomationSelectorName: string;
    @Input() dateFilterStatus: DateFilterType;
    @Input() dateRangeValue: DateRangeFilter;
    @Input() isFilterProcessing = true;
    @Input() filterValues: IDataTableFilterValues;
    @Output() actionButtonEvent = new EventEmitter();
    @Output() tableLayoutUpdateEvent = new EventEmitter<ITableLayout>();
    @Output() tableEvent = new EventEmitter();
    @Output() selectAllCheckBoxEvent = new EventEmitter();
    @Output() serverSideEvent = new EventEmitter();
    @Output() dataFilterDate = new EventEmitter<any>();
    @Output() filterApply = new EventEmitter();
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    @ViewChild('dataTableSearchInput') dataTableSearchInput: ElementRef;
    // custom templates
    @ContentChild(NgDataTableDropdownTemplateDirective, { read: TemplateRef }) dropDownTemplate: TemplateRef<any>;
    @ContentChild(NgDataTableFooterTemplateDirective, { read: TemplateRef }) footerTemplate: TemplateRef<any>;
    @ContentChild(NgDataTableCellTemplateDirective, { read: TemplateRef }) cellTemplate: TemplateRef<any>;
    // @ViewChild('datatableFilterTypeSelectRef') datatableFilterTypeSelectRef: NgSelectComponent;
    @ContentChild(NgDataTableRowTemplateDirective, { read: TemplateRef }) rowTemplate: TemplateRef<any>;
    @ContentChild(NgDataTableSubtitleTemplateDirective, { read: TemplateRef }) subTitleTemplate: TemplateRef<any>;
    @ContentChild(NgDataTableInnerFooterTemplateDirective, { read: TemplateRef }) innerFooterTemplate: TemplateRef<any>;
    @ContentChild(NgDataTableExportTemplateDirective, { read: TemplateRef }) exportTemplate: TemplateRef<any>;
    dtTrigger = new Subject<void>();
    onDestroy$: Subject<void> = new Subject();
    dtOptions: any = {};
    dataTableForm: FormGroup;
    checkboxCustomClasses: IFormCustomClass = {
        formGroup: 'form-group m-0',
        label: 'font-weight-bold text-muted small mb-0',
        input: '',
    };
    dateRangeInputCustomClasses: IFormCustomClass = {
        formGroup: 'form-group border-0 m-0 input-sm',
        label: 'd-none',
        input: 'form-control',
    };
    dataTableProcessing = true;
    dataTableRerenderer = false;
    dataTableSearchText = '';
    search$: Subject<string> = new Subject();
    dataTablePageInfo: DataTables.PageMethodeModelInfoReturn = null;
    dataTableAdjustColumns = true;
    // ssr states
    dataTableColsCurrent: DataTables.ColumnSettings[];
    dataTableServerSideParams$ = new Subject<any>();
    dataTableEnableServerSideImmutable = false;
    dataTableCellType = DataTableCellType;
    dataTableActionType = DataTableActionType;
    bsExportModalRef: BsModalRef;
    bsEditLayoutModalRef: BsModalRef;
    bsFilterLayoutModalRef: BsModalRef;
    isIncludeHeaderForExport = true;
    isIncludeUuidForExport = false;
    initialDataTableCols: DataTables.ColumnSettings[];
    dateRange: any;
    filterCount: any;

    userRoleType = UserRole;

    constructor(
        private formBuilder: FormBuilder,
        private titleService: Title,
        private papa: Papa,
        private helperService: HelperService,
        private dataTableService: DataTableService,
        private modalService: BsModalService,
        private sweetAlertService: SweetAlertService,
    ) {
        this.initializeForm();
    }

    ngOnInit(): void {
        // set server side enable / disable immutable vars
        this.dataTableEnableServerSideImmutable = this.dataTableEnableServerSide;

        // get/set datatable columns
        const columns = this.getDataTableColumns(this.dataTableCols);

        // get/set datatable options
        this.dtOptions = this.getDataTableOptions(columns);

        // sub to datatable rerender
        this.subscribeToDataTableRerender();

        // store initial column
        this.initialDataTableCols = JSON.parse(JSON.stringify(this.dataTableCols));

        // set date filter value
        if (this.dateRangeValue) {
            this.dateRange = this.dateRangeValue;
            const removeNull = Object.fromEntries(
                Object.entries(this.filterValues).filter(([_, value]) => value !== null),
            );
            this.filterCount = Object.keys(removeNull).filter((x) => removeNull[x].length > 0).length;
        }

        // get/set ssr data
        this.getServerSideData();

        //data table advance search
        this.onDataTableAdvanceSearch();
    }

    ngAfterViewInit(): void {
        // this.dtTrigger.next(this.dtOptions);
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getDataTableColumns(cols: DataTables.ColumnSettings[]): (
        | {
              data: string | number | DataTables.ObjectColumnData | DataTables.FunctionColumnData;
              name: string;
              orderable: boolean;
              visible: boolean;
          }
        | { name: string; orderable: boolean; visible: boolean; data?: undefined }
    )[] {
        // set columns with snake cased names
        const columns = cols.map(({ data, title, orderable, visible }) =>
            this.dataTableEnableServerSideImmutable
                ? {
                      data,
                      name: this.helperService.snakeCase(title),
                      orderable,
                      visible,
                      defaultContent: '',
                  }
                : { name: this.helperService.snakeCase(title), orderable, visible },
        );

        return columns;
    }

    getDataTableOptions(columns: DataTables.ColumnSettings[]): any {
        const options = {
            searching: this.dataTableEnableSearch,
            lengthChange: true,
            lengthMenu: this.dataTableLengthMenu,
            paging: this.dataTablePagingEnabled,
            info: this.dataTableInfoEnabled,
            filter: this.dataTableEnableFilters,
            stateSave: this.dataTableEnableStateSave,
            bDestroy: true,
            columns: columns,
            autoWidth: true,
            dom: 'rt<"row px-4"<"col d-flex"<"ms-auto d-flex align-items-center"<"me-3"i><"me-3"p><l>>>>',
            order: this.dataTableDefaultOrder,
            language: {
                info: `Total _TOTAL_ ${this.dataTableInfo.infoLabel}`,
                lengthMenu: `_MENU_ ${this.dataTableInfo.lengthMenuLabel || ''}`,
                paginate: {
                    previous: '<i class="bi bi-chevron-left"></i>',
                    next: '<i class="bi bi-chevron-right"></i>',
                    first: '',
                    last: '',
                },
            },
            buttons: [
                {
                    extend: 'csvHtml5',
                    header: true,
                    exportOptions: {
                        columns: ':visible',
                    },
                    customize: (doc) => {
                        if (this.dataTableEnableServerSideImmutable) {
                            const mappedCurrentCols = this.dataTableColsCurrent.filter(
                                (colum) => colum.type !== DataTableCellType.CHECKBOX_INPUT,
                            );
                            const csvData = this.papa.unparse((this.dataTableSource as any).map(flatten), {
                                columns: mappedCurrentCols.map((col) => col.data as string),
                                header: false,
                            });
                            const csvHeader = this.papa.unparse({
                                fields: mappedCurrentCols.map((col) => col.title),
                                data: [],
                            });
                            doc = csvHeader + csvData;
                        }
                        return doc;
                    },
                },
                {
                    extend: 'excelHtml5',
                    header: true,
                    exportOptions: {
                        columns: ':visible',
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                $('td.row-click', row).off('click');
                $('td.row-click', row).on('click', () => {
                    self.outputTableEvent(DataTableActionType.ROW_CLICK, data);
                });
                return row;
            },
            preDrawCallback: () => {
                this.dataTableProcessing = true;
            },

            drawCallback: (settings) => {
                this.dataTableProcessing = false;

                // adust datatable columns on the initial load
                if (this.dataTableAdjustColumns) {
                    // setTimeout(() => this.adjustDataTableColumns());
                    this.dataTableAdjustColumns = false;
                }

                // search datatable after rerender
                if (this.dataTableSearchText) {
                    this.onDataTableSearch(this.dataTableSearchText);
                    this.dataTableSearchText = '';
                }

                // disable col reorder drag event
                $('thead th').off('mousedown');
            },
        };

        if (!this.dataTableEnableServerSide) {
            return options;
        }

        return {
            ...options,
            serverSide: true,
            processing: false,
            ajax: (dataTablesParameters: any, callback: (data: any) => void) => {
                this.dataTableServerSideParams$.next({ dataTablesParameters, callback });
            },
        };
    }

    getServerSideData() {
        if (!this.dataTableEnableServerSideImmutable) {
            return;
        }

        let ajaxCb;

        this.dataTableServerSideParams$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                tap(({ callback, dataTablesParameters }) => {
                    ajaxCb = callback;
                    this.setServerSideColumns(this.dataTableEnableStateSave, dataTablesParameters);
                    this.dataTableProcessing = true;
                }),
                switchMap(({ dataTablesParameters }) => {
                    const { start, length, search, order } = dataTablesParameters;
                    return this.dataTableServerSideCallbackFn({
                        skip: start,
                        limit: length,
                        search: search?.value,
                        order:
                            order[0].dir == 'asc'
                                ? this.dataTableCols?.[order[0].column].data
                                : '-' + this.dataTableCols?.[order[0].column].data,
                    });
                }),
            )
            .subscribe({
                next: (response: IDataTablePaginationResponse) => {
                    //remove null from response
                    const notNullResponse = response.data.map((data) => {
                        const nonNullProperties = Object.entries(data)
                            .filter(([key, value]) => value !== null)
                            .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {});
                        return nonNullProperties;
                    });
                    this.dataTableSource = notNullResponse;
                    this.dataTableProcessing = false;
                    ajaxCb({
                        recordsTotal: response.totalItems,
                        recordsFiltered: response.totalFilteredItems,
                        data: [],
                    });
                },
                error: (error) => {
                    this.dataTableProcessing = false;
                    ajaxCb({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: [],
                    });
                },
            });
    }

    subscribeToDataTableRerender(): void {
        this.dataTableService.currentDataTableRerenderer
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(async (renderStatus) => {
                this.dataTableRerenderer = renderStatus;

                if (renderStatus) {
                    // set search value to filter after table rerender
                    if (this.dtElement && 'dtInstance' in this.dtElement) {
                        const dtInstance: DataTables.Api = await this.dtElement.dtInstance;
                        this.dataTableSearchText = dtInstance.search();
                        this.dataTablePageInfo = dtInstance.page.info();
                    }

                    this.reRenderDataTable();

                    this.dataTableService.changeDataTableRerenderer(false);
                }
            });
    }

    reRenderDataTable(): void {
        if (this.dtElement && 'dtInstance' in this.dtElement) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                this.dataTableProcessing = true;
                this.dataTableEnableServerSide = this.dataTableEnableServerSideImmutable;
                if (this.dataTableEnableServerSide) {
                    dtInstance.destroy();

                    dtInstance.clear();

                    this.dtTrigger.next();
                    dtInstance.ajax.reload();
                } else {
                    // fix source mapping not registering by deep cloning
                    this.dataTableSource = JSON.parse(JSON.stringify(this.dataTableSource));
                    dtInstance.destroy();
                    this.dtTrigger.next();

                    // enable columns readjusting after the table destroy
                    this.dataTableAdjustColumns = true;
                }
            });
        }
    }

    // datatable output actions
    outputActionButtonEvent(actionType: string, item?: any) {
        this.actionButtonEvent.emit({ actionType, item });
    }

    outputTableEvent(actionType: string, item?: any): void {
        this.tableEvent.emit({ actionType, item });
    }

    getColumnData(rowItem: any, column: any) {
        let columnData = column.data.split('.').reduce((a, b) => (a[b] || a[b] === 0 ? a[b] : ''), rowItem);

        if (column.render) {
            columnData = column.render(columnData);
        }

        return columnData;
    }

    getRow(tableRowRef) {
        return this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            return dtInstance.row(tableRowRef);
        });
    }

    onDataTableAdvanceSearch() {
        this.search$.pipe(debounceTime(3000)).subscribe((searchText) => {
            if (this.dtElement && 'dtInstance' in this.dtElement) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.search(searchText).draw();
                });
            }
        });
    }

    onDataTableSearch(searchText: string) {
        if (this.dataTableSearchType === 'advanced') {
            this.search$.next(searchText);
        } else {
            if (this.dtElement && 'dtInstance' in this.dtElement) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.search(searchText).draw();
                });
            }
        }
    }

    onChangeCustomDate(event) {
        if (+event[0] === +new Date(fiveYears().startDate)) {
            this.dateRange = [];
            this.dataFilterDate.emit({
                startDate: undefined,
                endDate: undefined,
                dateRange: [undefined, undefined],
            });
        } else {
            this.dataFilterDate.emit({
                startDate: event[0].toISOString(),
                endDate: event[1].toISOString(),
                dateRange: event,
            });
        }
        this.reRenderDataTable();
    }

    adjustDataTableColumns() {
        if (this.dtElement && 'dtInstance' in this.dtElement) {
            this.dtElement.dtInstance.then((dtInstance: any) => {
                dtInstance.columns.adjust().draw();
            });
        }
    }

    setServerSideColumns(isPersistedState: boolean, dtState?: any) {
        if (!isPersistedState) {
            this.dataTableColsCurrent = this.dataTableCols.filter((col) => col.visible !== false);
            return;
        }

        let colReorder = [...Array(this.dataTableCols.length + 1).keys()];

        if (dtState) {
            if (dtState.columns) {
                this.dataTableCols.forEach((col, i) => {
                    col.visible = dtState.columns[i]?.visible;
                });
            }

            if (dtState.order) {
                this.dataTableDefaultOrder = dtState.order;
            }

            if (dtState.ColReorder) {
                colReorder = dtState.ColReorder;
            }
        }

        const dtCols = [];
        this.dataTableCols.forEach((columnSetting: DataTables.ColumnSettings, index: number) => {
            const reorderIndex = colReorder.findIndex((i) => i === index);
            dtCols.push({ ...columnSetting, reorderIndex });
        });

        this.dataTableColsCurrent = dtCols
            .sort((a, b) => a.reorderIndex - b.reorderIndex)
            .filter((col) => col.visible !== false);
    }

    exportButtonEvent() {
        if (this.dtElement && 'dtInstance' in this.dtElement) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                const initialState = {
                    totalRecordsCount: dtInstance.rows().count(),
                    filteredRecordsCount: dtInstance.rows({ search: 'applied' }).count(),
                    isIncludeHeader: this.isIncludeHeaderForExport,
                    isIncludeUuid:
                        this.isIncludeUuidForExport ||
                        this.dataTableCols.find((x) => x.title === 'System UUID')?.visible,
                };

                this.bsExportModalRef = this.modalService.show(ExportListModalComponent, {
                    initialState,
                    class: 'export-list-modal-wrapper',
                    backdrop: true,
                });

                this.bsExportModalRef.content.onExport
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((type: DataTableExportType) => {
                        this.onDataTableExportClick(type);
                        this.bsExportModalRef.hide();
                    });

                this.bsExportModalRef.content.onIncludeHeader
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((isIncludeHeader: boolean) => {
                        this.isIncludeHeaderForExport = isIncludeHeader;
                        for (let i = 0; i < (this.dtOptions.buttons as string[]).length; i++) {
                            this.dtOptions.buttons[i].header = isIncludeHeader;
                        }
                        this.reRenderDataTable();
                    });

                this.bsExportModalRef.content.onIncludeUuid
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((isIncludeUuid: boolean) => {
                        this.isIncludeUuidForExport = isIncludeUuid;
                        const index = this.dataTableCols.findIndex((x) => x.title === 'System UUID');
                        if (index > -1) this.dataTableCols[index].visible = isIncludeUuid;
                        this.dtOptions.columns = this.getDataTableColumns(this.dataTableCols);
                        this.reRenderDataTable();
                    });
            });
        }
    }

    onDataTableExportClick(exportType: DataTableExportType) {
        this.dtElement.dtInstance.then((dtInstance: any) => {
            if (this.dataTableEnableServerSideImmutable && exportType === DataTableExportType.EXCEL) {
                return this.generateExcelForServerSideDataSource(this.dataTableSource);
            }

            dtInstance.button(`.buttons-${exportType}`).trigger();
        });
    }

    generateExcelForServerSideDataSource(dataSource: any) {
        const data = [
            {
                sheet: 'Sheet1',
                columns: this.dataTableColsCurrent
                    .filter((col) => col.type !== 'CHECKBOX_INPUT')
                    .filter((col) => col.visible !== false)
                    .map((col) => ({ label: col.title, value: col.data as string })),
                content: dataSource,
            },
        ];

        const settings = {
            fileName: this.titleService.getTitle(),
            extraLength: 3,
            writeOptions: {},
        };

        xlsx(data, settings);
    }

    filterButtonEvent() {
        const initialState = {
            filterValues: this.filterValues,
        };
        this.bsFilterLayoutModalRef = this.modalService.show(FilterLayoutModalComponent, {
            initialState,
            class: 'side-modal-wrapper',
            backdrop: true,
        });
        this.bsFilterLayoutModalRef.content.actionButtonEvent
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((filters: { deafult; provider; recipient; serviceStatus; contractStatus }) => {
                const { deafult, provider, recipient, serviceStatus, contractStatus } = filters;
                this.bsFilterLayoutModalRef.content.isFormProcessing = this.isFilterProcessing;
                this.filterApply.emit({
                    deafult,
                    provider,
                    recipient,
                    serviceStatus,
                    contractStatus,
                });
                if (deafult) {
                    this.dateRange = [];
                    this.sweetAlertService.success('Successfully reset the filter');
                } else {
                    this.sweetAlertService.success('Successfully apply the filter');
                }
                this.bsFilterLayoutModalRef.content.isFormProcessing = this.isFilterProcessing;
                this.bsFilterLayoutModalRef.hide();
                this.reRenderDataTable();
            });
    }

    editLayoutButtonEvent() {
        const initialState = {
            dataTableCols: JSON.parse(JSON.stringify(this.dataTableCols)),
            initialDataTableCols: this.initialDataTableCols,
        };
        this.bsEditLayoutModalRef = this.modalService.show(EditLayoutModalComponent, {
            initialState,
            class: 'side-modal-wrapper',
            backdrop: true,
        });
        this.bsEditLayoutModalRef.content.onApply
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((dataTableCols: DataTables.ColumnSettings[]) => {
                this.persistDataTableColumnChanges(dataTableCols);
                this.tableLayoutUpdateEvent.emit({ layout: this.dataTableCols });
                this.bsEditLayoutModalRef.hide();
            });
    }

    showAllButtonEvent() {
        if (!this.dataTableCols.every((x) => x.visible)) {
            this.dataTableCols.forEach((x) => (x.visible = true));
            this.dtOptions.columns = this.getDataTableColumns(this.dataTableCols);
            this.reRenderDataTable();
        }
    }

    public setForm(): void {
        this.dataTableForm.setValue({
            selectAll: [false],
        });
    }

    private initializeForm(): void {
        this.dataTableForm = this.formBuilder.group({
            selectAll: [false],
        });

        // sub for select all check / uncheck
        this.dataTableForm
            .get('selectAll')
            .valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(200))
            .subscribe((flag) => {
                this.selectAllCheckBoxEvent.emit(flag);
            });
    }

    get f() {
        return this.dataTableForm.controls;
    }

    persistDataTableColumnChanges(dataTableCols: DataTables.ColumnSettings[]) {
        dataTableCols.forEach((x, i) => {
            this.dataTableCols[i].title = x.title;
            this.dataTableCols[i].visible = x.visible;
            this.dataTableCols[i].data = x.data;
            this.dataTableCols[i].orderable = x.orderable;
            this.dataTableCols[i].type = x.type;
        });
        this.dtOptions.columns = this.getDataTableColumns(this.dataTableCols);
        this.isIncludeUuidForExport = this.dataTableCols.find((x) => x.title === 'System UUID')?.visible;
        this.reRenderDataTable();
    }
}
