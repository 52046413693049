import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[appTypeheadInputTagTmp]' })
export class NgTypeheadInputTagTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[appTypeheadInputOptionTmp]' })
export class NgTypeheadInputOptionTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[appTypeheadInputOptionGroupTmp]' })
export class NgTypeheadInputOptionGroupTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[appSelectInputOptionTmp]' })
export class NgSelectInputOptionTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[appSelectInputLabelTmp]' })
export class NgSelectInputLabelTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}
