import { IContract, IHistory, IIssue, IItem, ILinkCounts, IService } from '@data/schema';
import { GenericStateModel, LinkedItemsStateModel } from '@data/state';

export interface IssueStateModel extends GenericStateModel<IIssue> {
    linkCounts?: ILinkCounts;
    linkedItems?: {
        services?: LinkedItemsStateModel<IService>;
        purchasedItems?: LinkedItemsStateModel<IItem>;
        suppliedItems?: LinkedItemsStateModel<IItem>;
        manufacturedItems?: LinkedItemsStateModel<IItem>;
        items?: LinkedItemsStateModel<IItem>;
        contracts?: LinkedItemsStateModel<IContract>;
        notes: LinkedItemsStateModel<IHistory>;
    };
}
