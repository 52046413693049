// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

// enums
import { DataTableExportType } from '@data/enum';

@Component({
    selector: 'app-export-list-modal',
    templateUrl: './export-list-modal.component.html',
    styleUrls: ['./export-list-modal.component.scss'],
})
export class ExportListModalComponent implements OnInit {
    @Output() onExport = new EventEmitter();
    @Output() onIncludeHeader = new EventEmitter();
    @Output() onIncludeUuid = new EventEmitter();

    isIncludeUuid: boolean;
    isIncludeHeader: boolean;
    totalRecordsCount: number;
    filteredRecordsCount: number;

    exportType = DataTableExportType;

    constructor(public bsModalRef: BsModalRef) {}

    ngOnInit() {}

    onCloseClick() {
        this.bsModalRef.hide();
    }

    export(type: DataTableExportType) {
        this.onExport.emit(type);
    }

    onIncludeHeaders($event) {
        this.onIncludeHeader.emit($event.target.checked);
    }

    onIncludeUuids($event) {
        this.onIncludeUuid.emit($event.target.checked);
    }
}
