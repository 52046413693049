// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    ICreateCompanyDTO,
    ICompany,
    ILinkCounts,
    IDashboardSummary,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
} from '@data/schema';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    constructor(private apiService: ApiService) {}

    get(): Observable<ICompany[]> {
        return this.apiService.get<{ data: ICompany[] }>('/companies').pipe(map((res) => res.data));
    }

    getAll(): Observable<ICompany[]> {
        return this.apiService.get<{ data: ICompany[] }>('/companies/all').pipe(map((res) => res.data));
    }

    getAllIsTenant(): Observable<ICompany[]> {
        return this.apiService.get<{ data: ICompany[] }>('/companies/isTenant').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<ICompany>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: ICompany[]; totalItems: number }>('/companies', params).pipe(
            map((res) => ({
                data: res.data,
                totalItems: res.data.length,
                totalFilteredItems: !res.data ? 0 : res.totalItems,
            })),
        );
    }

    getAllIsTenantSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<ICompany>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: ICompany[]; totalItems: number }>('/companies/isTenant', params).pipe(
            map((res) => ({
                data: res.data,
                totalItems: res.data.length,
                totalFilteredItems: !res.data ? 0 : res.totalItems,
            })),
        );
    }

    getById(id: string): Observable<ICompany> {
        return this.apiService.get<{ data: ICompany }>(`/companies/${id}`).pipe(map((res) => res.data));
    }

    getCompanyLogoById(id: string): Observable<ICompany> {
        return this.apiService.get<{ data: ICompany }>(`/files/tenant/logo/${id}`).pipe(map((res) => res.data));
    }

    getManufacturers(): Observable<ICompany[]> {
        return this.apiService.get<{ data: ICompany[] }>(`/companies/type/manufacturers`).pipe(map((res) => res.data));
    }

    getSuppliers(): Observable<ICompany[]> {
        return this.apiService.get<{ data: ICompany[] }>(`/companies/type/supplier`).pipe(map((res) => res.data));
    }

    create(context: ICreateCompanyDTO): Observable<ICompany> {
        return this.apiService
            .post<{ data: ICompany }, ICreateCompanyDTO>(`/companies`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateCompanyDTO): Observable<ICompany> {
        return this.apiService
            .put<{ data: ICompany }, ICreateCompanyDTO>(`/companies/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: ICompany }>(`/companies/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: ICompany[] }, { ids: string[] }>(`/companies`, { ids });
    }

    getByCompanyId(id: string): Observable<ICompany[]> {
        return this.apiService.get<{ data: ICompany[] }>(`/companies/child/${id}`).pipe(map((res) => res.data));
    }

    getByCompanyIdSSR(
        id: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<ICompany>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: ICompany[]; totalItems: number }>(`/companies/child/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService.get<{ data: ILinkCounts }>(`/companies/${id}/linkCounts`).pipe(map((res) => res.data));
    }

    getDashboardSummaries(): Observable<IDashboardSummary> {
        return this.apiService
            .get<{ data: IDashboardSummary }>('/companies/dashboard/summary')
            .pipe(map((res) => res.data));
    }

    getLinkStatus(ids: string[]): Observable<string> {
        let params = new HttpParams();

        ids.forEach((id: string) => {
            params = params.append(`Ids`, id);
        });

        return this.apiService
            .get<{ data: string }>(`/linkValidation/Companies/linkStatus`, params)
            .pipe(map((res) => res.data));
    }
}
