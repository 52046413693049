// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IService,
    ICreateServiceDTO,
    ILinkCounts,
    IGenerateServiceDTO,
    IGenerateServiceResponse,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
    IServiceCheckList,
} from '@data/schema';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IService[]> {
        return this.apiService.get<{ data: IService[] }>('/services').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IService>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<IService[]>('/services', params).pipe(
            map((res) => ({
                data: !res?.['data'][0].data.length ? [] : res?.['data'][0].data,
                totalItems: res?.['data'][0].data.length,
                totalFilteredItems: !res?.['data'][0].data.length ? 0 : res?.['data'][0].total,
            })),
        );
    }

    getById(id: string): Observable<IService> {
        return this.apiService.get<{ data: IService }>(`/services/${id}`).pipe(map((res) => res.data));
    }

    getCurrentCheckListById(id: string): Observable<IServiceCheckList[]> {
        return this.apiService
            .get<{ data: IServiceCheckList[] }>(`/checklist-field-serviceItem/${id}`)
            .pipe(map((res) => res.data));
    }

    create(context: ICreateServiceDTO): Observable<IService> {
        return this.apiService
            .post<{ data: IService }, ICreateServiceDTO>(`/services`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateServiceDTO): Observable<IService> {
        return this.apiService
            .put<{ data: IService }, ICreateServiceDTO>(`/services/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IService }>(`/services/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IService[] }, { ids: string[] }>(`/services`, { ids });
    }

    generateServices(context: IGenerateServiceDTO) {
        return this.apiService
            .post<{ data: IGenerateServiceResponse }, IGenerateServiceDTO>('/services/contract', context)
            .pipe(map((res) => res.data));
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService.get<{ data: ILinkCounts }>(`/services/${id}/linkCounts`).pipe(map((res) => res.data));
    }

    getByContractId(id: string): Observable<IService[]> {
        return this.apiService.get<{ data: IService[] }>(`/services/contract/${id}`).pipe(map((res) => res.data));
    }

    getByContractIdSSR(
        id: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IService>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IService[]; totalItems: number }>(`/services/contract/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByIssueId(id: string): Observable<IService[]> {
        return this.apiService.get<{ data: IService[] }>(`/services/issue/${id}`).pipe(map((res) => res.data));
    }

    getByIssueIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IService>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IService[]; totalItems: number }>(`/services/issue/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByItemId(id: string): Observable<IService[]> {
        return this.apiService.get<{ data: IService[] }>(`/services/item/${id}`).pipe(map((res) => res.data));
    }

    getByItemIdSSR(id: string, context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IService>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IService[]; totalItems: number }>(`/services/item/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }

    getByItemIdNTypeSSR(
        id: string,
        type: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IService>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<IService[]>(`/services/item/${id}/itemType/${type}`, params).pipe(
            map((res) => ({
                data: !res?.['data'][0].data.length ? [] : res?.['data'][0].data,
                totalItems: res?.['data'][0].data.length,
                totalFilteredItems: !res?.['data'][0].data.length ? 0 : res?.['data'][0].total,
            })),
        );
    }

    createWithContractLink(id: string, context: ICreateServiceDTO): Observable<IService> {
        return this.apiService
            .post<{ data: IService }, ICreateServiceDTO>(`/services/contract/${id}`, context)
            .pipe(map((res) => res.data));
    }

    createWithIssueLink(id: string, context: ICreateServiceDTO): Observable<IService> {
        return this.apiService
            .post<{ data: IService }, ICreateServiceDTO>(`/services/issue/${id}`, context)
            .pipe(map((res) => res.data));
    }
    createWithItemLink(id: string, context: ICreateServiceDTO): Observable<IService> {
        return this.apiService
            .post<{ data: IService }, ICreateServiceDTO>(`/services/item/${id}`, context)
            .pipe(map((res) => res.data));
    }

    getLinkStatus(ids: string[]): Observable<string> {
        let params = new HttpParams();

        ids.forEach((id: string) => {
            params = params.append(`Ids`, id);
        });

        return this.apiService
            .get<{ data: string }>(`/linkValidation/Services/linkStatus`, params)
            .pipe(map((res) => res.data));
    }

    updateCheckListFieldValue(
        entity: string,
        id: string,
        value: string,
        file?: File,
        isImage?: string,
        userName?: string,
    ): Observable<any> {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('isImage', isImage);
        formData.append('entity', entity);
        formData.append('value', value);
        formData.append('file', file);
        formData.append('signedBy', userName);
        return this.apiService
            .update<{ data: any }>(`/checklist-field-serviceItem`, formData)
            .pipe(map((res) => res.data));
    }

    removeServiceCheckList(id: string) {
        return this.apiService.delete<{ data: IService }>(`/linkServiceItems/unlink/template/${id}`);
    }

    addServiceCheckList(serviceId: string, templateId: string) {
        return this.apiService.get<{ data: any }>(`/linkServiceItems/link/template/${serviceId}/${templateId}`);
    }
}
