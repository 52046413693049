// core
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

// schemas
import { IFormCustomClass } from '@data/schema';

@Component({
    selector: 'app-text-area-input',
    templateUrl: './text-area-input.component.html',
    styleUrls: ['./text-area-input.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaInputComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() secondaryLabel: string;
    @Input() placeholder: string;
    @Input() testAutomationSelectorName: string;
    @Input() rowCount = 3;
    @Input() maxLength = null;
    @Input() customClasses: IFormCustomClass = {
        formGroup: 'form-group mb-3',
        label: 'fw-medium text-gray-700 fs-14 mb-0',
        input: 'form-control',
        secondaryLabel: 'text-gray-700 mb-0 fs-10',
    };

    text: string;
    inputFocused = false;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    writeValue(obj: any): void {}

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}

    onInputFocus(focused: boolean) {
        this.inputFocused = focused;
    }
}
