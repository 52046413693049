// core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

// 3rd party
// --password-strength-bar
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
// --ngx-avatars
import { AvatarModule } from 'ngx-avatars';
// --ng-select
import { NgSelectModule } from '@ng-select/ng-select';
// --angular-datatables
import { DataTablesModule } from 'angular-datatables';
// --ngx-dropzone
import { NgxDropzoneModule } from 'ngx-dropzone';
// --ng2-nested-search
import { FilterPipeModule } from 'ng2-nested-search';
// --ngx-bootstrap-date-picker
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// --ngx-webcam
import { WebcamModule } from 'ngx-webcam';
// --almothafar-angular-signature-pad
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

// bootstrap
import { BootstrapModule } from './bootstrap.module';

// services
import { PasswordStrengthService } from '@app/service';

// directives
// --datatable
import {
    NgDataTableDropdownTemplateDirective,
    NgDataTableFooterTemplateDirective,
    NgDataTableCellTemplateDirective,
    NgDataTableRowTemplateDirective,
    NgDataTableSubtitleTemplateDirective,
    NgDataTableInnerFooterTemplateDirective,
    NgDataTableExportTemplateDirective,
} from './directive/data-table-template.directive';
// --form-template
import {
    NgTypeheadInputOptionGroupTemplateDirective,
    NgTypeheadInputOptionTemplateDirective,
    NgSelectInputLabelTemplateDirective,
    NgSelectInputOptionTemplateDirective,
    NgTypeheadInputTagTemplateDirective,
} from './directive/form-template.directive';

// pipes
import { IsVowelPipe } from './pipe/is-vowel.pipe';

// components
// --form
import { TextInputComponent } from './component/form/text-input/text-input.component';
import { CheckboxInputComponent } from './component/form/checkbox-input/checkbox-input.component';
import { ToggleInputComponent } from './component/form/toggle-input/toggle-input.component';
import { SelectInputComponent } from './component/form/select-input/select-input.component';
import { TextAreaInputComponent } from './component/form/text-area-input/text-area-input.component';
import { RadioToggleInputComponent } from './component/form/radio-toggle-input/radio-toggle-input.component';
import { DateRangeInputComponent } from './component/form/date-range-input/date-range-input.component';
// --link-modals
import { LinkServiceModalComponent } from './component/link-modals/link-service-modal/link-service-modal.component';
import { LinkContractModalComponent } from './component/link-modals/link-contract-modal/link-contract-modal.component';
import { LinkIssueModalComponent } from './component/link-modals/link-issue-modal/link-issue-modal.component';
import { LinkItemModalComponent } from './component/link-modals/link-item-modal/link-item-modal.component';

// --create-modals
import { CompanyCreateModalComponent } from './component/create-modals/company-create-modal/company-create-modal.component';
import { ContactCreateModalComponent } from './component/create-modals/contact-create-modal/contact-create-modal.component';
import { ContractCreateModalComponent } from './component/create-modals/contract-create-modal/contract-create-modal.component';
import { InstallationCreateModalComponent } from './component/create-modals/installation-create-modal/installation-create-modal.component';
import { ServiceCreateModalComponent } from './component/create-modals/service-create-modal/service-create-modal.component';
import { IssueCreateModalComponent } from './component/create-modals/issue-create-modal/issue-create-modal.component';
import { ItemCreateModalComponent } from './component/create-modals/item-create-modal/item-create-modal.component';
// --button
import { SpinnerButtonComponent } from './component/button/spinner-button/spinner-button.component';
// --global-search
import { GlobalSearchComponent } from './component/global-search/global-search.component';
// --data-table
import { DataTableComponent } from './component/data-table/data-table.component';
// --breadcrumb
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
// --upload list
import { UploadListComponent } from './component/upload/upload-list/upload-list.component';
// --ngx-scrollbar;
import { NgScrollbarModule } from 'ngx-scrollbar';
// --notes
import { NotesComponent } from './component/notes/notes.component';
// --link table
import { LinkTableComponent } from './component/link-table/link-table.component';
// --file upload modal
import { FileUploadModalComponent } from './component/upload/file-upload-modal/file-upload-modal.component';
// --size detector
import { SizeDetectorComponent } from './component/size-detector/size-detector.component';
// --export list modal
import { ExportListModalComponent } from './component/data-table/export-list-modal/export-list-modal.component';
// --edit layout modal
import { EditLayoutModalComponent } from './component/data-table/edit-layout-modal/edit-layout-modal.component';
// --filter layout modal
import { FilterLayoutModalComponent } from './component/data-table/filter-layout-modal/filter-layout-modal.component';
// --loading-grow
import { LoadingGrowComponent } from './component/loading/loading-grow/loading-grow.component';
// --web-cam-modal
import { WebCamComponent } from './component/web-cam/web-cam.component';
// --sign-pad-modal
import { SignPadComponent } from './component/sign-pad/sign-pad.component';

@NgModule({
    declarations: [
        // directives
        // --datatable
        NgDataTableDropdownTemplateDirective,
        NgDataTableFooterTemplateDirective,
        NgDataTableCellTemplateDirective,
        NgDataTableRowTemplateDirective,
        NgDataTableSubtitleTemplateDirective,
        NgDataTableInnerFooterTemplateDirective,
        NgDataTableExportTemplateDirective,
        // --form-template
        NgTypeheadInputOptionTemplateDirective,
        NgTypeheadInputOptionGroupTemplateDirective,
        NgSelectInputOptionTemplateDirective,
        NgSelectInputLabelTemplateDirective,
        NgTypeheadInputTagTemplateDirective,

        // pipes
        IsVowelPipe,

        // components
        // --form
        TextInputComponent,
        CheckboxInputComponent,
        ToggleInputComponent,
        SelectInputComponent,
        TextAreaInputComponent,
        RadioToggleInputComponent,
        DateRangeInputComponent,
        // --link-modals
        LinkServiceModalComponent,
        LinkContractModalComponent,
        LinkIssueModalComponent,
        LinkItemModalComponent,
        // --create-modals
        CompanyCreateModalComponent,
        ContactCreateModalComponent,
        ContractCreateModalComponent,
        InstallationCreateModalComponent,
        ServiceCreateModalComponent,
        IssueCreateModalComponent,
        ItemCreateModalComponent,
        // --button
        SpinnerButtonComponent,
        // --global-search
        GlobalSearchComponent,
        // --data-table
        DataTableComponent,
        // --breadcrumb
        BreadcrumbComponent,
        // --upload
        UploadListComponent,
        // --notes
        NotesComponent,
        // --link-table
        LinkTableComponent,
        // --file upload modal
        FileUploadModalComponent,
        // --size detector
        SizeDetectorComponent,
        // --export list modal
        ExportListModalComponent,
        // --edit layout modal
        EditLayoutModalComponent,
        // --filter layout modal
        FilterLayoutModalComponent,
        // --loading-grow
        LoadingGrowComponent,
        //--web-cam-modal
        WebCamComponent,
        // --sign-pad-modal
        SignPadComponent,
    ],
    imports: [
        // core
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        DragDropModule,

        // bootstrap
        BootstrapModule,

        // 3rd party
        // --password-strength-bar
        PasswordStrengthMeterModule.forRoot({ serviceClass: PasswordStrengthService }),
        // --ngx-avatars
        AvatarModule,
        // --ng-select
        NgSelectModule,
        // --angular-datatables
        DataTablesModule,
        // --ngx-scrollbar
        NgScrollbarModule,
        // --ngx-dropzone
        NgxDropzoneModule,
        // --ng2-nested-search
        FilterPipeModule,
        // --ngx-bootstrap-date-picker
        BsDatepickerModule,
        // --ngx-webcam
        WebcamModule,
        // --almothafar-angular-signature-pad
        AngularSignaturePadModule,
    ],
    exports: [
        // core
        FormsModule,
        ReactiveFormsModule,

        // bootstrap
        BootstrapModule,

        // 3rd party
        // --password-strength-bar
        PasswordStrengthMeterModule,
        // --ngx-avatars
        AvatarModule,
        // --ng-select
        NgSelectModule,
        // --angular-datatables
        DataTablesModule,
        // --ngx-scrollbar
        NgScrollbarModule,
        // --ngx-dropzone
        NgxDropzoneModule,
        // --ng2-nested-search
        FilterPipeModule,
        // --ngx-bootstrap-date-picker
        BsDatepickerModule,
        // --ngx-webcam
        WebcamModule,
        // --almothafar-angular-signature-pad
        AngularSignaturePadModule,

        // directives
        // --datatable
        NgDataTableDropdownTemplateDirective,
        NgDataTableFooterTemplateDirective,
        NgDataTableCellTemplateDirective,
        NgDataTableRowTemplateDirective,
        NgDataTableSubtitleTemplateDirective,
        NgDataTableInnerFooterTemplateDirective,
        NgDataTableExportTemplateDirective,
        // --form-template
        NgTypeheadInputOptionTemplateDirective,
        NgTypeheadInputOptionGroupTemplateDirective,
        NgSelectInputOptionTemplateDirective,
        NgSelectInputLabelTemplateDirective,
        NgTypeheadInputTagTemplateDirective,

        // pipes
        IsVowelPipe,

        // components
        // --form
        TextInputComponent,
        CheckboxInputComponent,
        ToggleInputComponent,
        SelectInputComponent,
        TextAreaInputComponent,
        RadioToggleInputComponent,
        DateRangeInputComponent,
        // --link-modals
        LinkServiceModalComponent,
        LinkContractModalComponent,
        LinkIssueModalComponent,
        LinkItemModalComponent,
        // --create-modals
        CompanyCreateModalComponent,
        ContactCreateModalComponent,
        ContractCreateModalComponent,
        InstallationCreateModalComponent,
        ServiceCreateModalComponent,
        IssueCreateModalComponent,
        ItemCreateModalComponent,
        // --button
        SpinnerButtonComponent,
        // --global-search
        GlobalSearchComponent,
        // --data-table
        DataTableComponent,
        // --breadcrumb
        BreadcrumbComponent,
        // --upload
        UploadListComponent,
        // --notes
        NotesComponent,
        // --link-table
        LinkTableComponent,
        // --file upload modal
        FileUploadModalComponent,
        // --size detector
        SizeDetectorComponent,
        // --loading-grow
        LoadingGrowComponent,
        // --web-cam-modal
        WebCamComponent,
        // --sign-pad-modal
        SignPadComponent,
    ],
})
export class SharedModule {}
