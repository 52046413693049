// core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import {
    AuthState,
    CompanyState,
    CreateContact,
    CreateLinkedItemForCompany,
    GetAllCompanies,
    GetAllIsTenantCompanies,
    GetCompanies,
} from '@data/state';

// schemas
import { IButtonCustomClass, ICompany, IUser } from '@data/schema';

// services
import { DataTableService, SweetAlertService } from '@app/service';
import { LinkedCountType, LinkedEntityType, ServiceType, UserRole } from '@data/enum';

@Component({
    selector: 'app-contact-create-modal',
    templateUrl: './contact-create-modal.component.html',
    styleUrls: ['./contact-create-modal.component.scss'],
})
export class ContactCreateModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany>;
    @Select(AuthState.userRole) userRole$: Observable<UserRole>;
    @Select(AuthState.tenantId) tenantId$: Observable<string>;

    tenantId = this.store.selectSnapshot(AuthState.tenantId);
    currentUser: IUser;

    serviceType: ServiceType;
    userRoleType = UserRole;
    linkedItemId: string;
    parentDisabled: boolean;
    userRole: string;

    form: FormGroup;
    isFormProcessing = false;

    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    userRoles;

    userRolesAdmin = [
        { name: 'Super Admin', value: 'admin' },
        { name: 'Application Admin', value: 'applicationAdmin' },
        { name: 'Application Viewer', value: 'applicationViewer' },
    ];

    userRolesApplicationAdmin = [
        { name: 'Application Admin', value: 'applicationAdmin' },
        { name: 'Application Viewer', value: 'applicationViewer' },
    ];

    userRolesTenantAdmin = [
        { name: 'Tenant Admin', value: 'tenantAdmin' },
        { name: 'Tenant Viewer', value: 'tenantViewer' },
    ];

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private dataTableService: DataTableService,
    ) {}

    ngOnInit() {
        this.initializeForm();
        this.store.dispatch([new GetAllIsTenantCompanies()]);
        this.form.controls['parent'].setValue(this.linkedItemId);
        this.userRole = this.store.selectSnapshot(AuthState.userRole);
        this.currentUser = this.store.selectSnapshot(AuthState.currentUser);
        if (this.tenantId) {
            this.form.controls['tenants'].setValue([this.tenantId]);
        }
        this.assignUserRole();
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            firstName: [null, Validators.required],
            lastName: [null],
            role: [null, Validators.required],
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern(
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    ),
                ],
            ],
            parent: [null],
            tenants: [[]],
            telMobile: [null],
            telWork: [null],
            isPartnerLoginEnable: [false],
            isPartnerLoginViewOnly: [false],
        });
        const tenantsControl = this.form.get('tenants');

        this.form.get('role').valueChanges.subscribe((role) => {
            const roleValidators =
                role === UserRole.APPLICATION_ADMIN || role === UserRole.APPLICATION_VIEWER
                    ? [Validators.required]
                    : [];

            tenantsControl.setValidators(roleValidators);
            tenantsControl.updateValueAndValidity();
        });
    }

    get f() {
        return this.form.controls;
    }

    assignUserRole() {
        switch (this.userRole) {
            case UserRole.ADMIN:
                this.userRoles = this.userRolesAdmin;
                break;
            case UserRole.APPLICATION_ADMIN:
                this.userRoles = this.userRolesApplicationAdmin;
                break;
            default:
                break;
        }
    }

    create() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { id, ...rest } = this.form.getRawValue();

        this.isFormProcessing = true;
        if (!this.serviceType) {
            this.store
                .dispatch(
                    new CreateContact({
                        ...rest,
                    }),
                )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        this.isFormProcessing = false;
                        this.sweetAlertService.success('Successfully create the record');
                        this.bsModalRef.hide();
                        this.dataTableService.dataTableRerenderer.next(true);
                    },
                    error: () => {
                        this.isFormProcessing = false;
                    },
                });
        } else {
            switch (this.serviceType) {
                case ServiceType.CONTACT:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForCompany(
                                this.linkedItemId,
                                rest,
                                ServiceType.CONTACT,
                                LinkedEntityType.CONTACTS,
                                LinkedCountType.LINKED_CONTACT_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
            }
        }
    }

    reset() {
        this.form.reset();
    }
}
