// core
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

// services
import {
    CheckListService,
    CompanyService,
    ContactService,
    ContractService,
    IssueService,
    ItemService,
    ServiceService,
} from '@data/service';

// schemas
import { IDataTablePaginationDTO } from '@data/schema';

// enums
import { ServiceType } from '@data/enum';

@Injectable({
    providedIn: 'root',
})
export class DataTableService {
    constructor(
        private serviceService: ServiceService,
        private contractService: ContractService,
        private issueService: IssueService,
        private contactService: ContactService,
        private companyService: CompanyService,
        private itemService: ItemService,
        private checkListService: CheckListService,
    ) {}

    dataTableRerenderer = new BehaviorSubject<boolean>(false);
    currentDataTableRerenderer = this.dataTableRerenderer.asObservable();

    changeDataTableRerenderer(reRender: boolean) {
        this.dataTableRerenderer.next(reRender);
    }

    getLinkTableData(
        serviceType: ServiceType,
        idType: string,
        id: string,
        pagination: IDataTablePaginationDTO,
        type?: string,
    ) {
        if (type) {
            return this?.[serviceType + 'Service']?.['getBy' + idType + 'NTypeSSR'](id, type, pagination);
        } else {
            return this?.[serviceType + 'Service']?.['getBy' + idType + 'SSR'](id, pagination);
        }
    }
}
