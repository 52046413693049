// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { IEquipmentCategory, ICreateEquipmentCategoryDTO } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class EquipmentCategoryService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IEquipmentCategory[]> {
        return this.apiService.get<{ data: IEquipmentCategory[] }>('/equipmentCategories').pipe(map((res) => res.data));
    }

    getById(id: string): Observable<IEquipmentCategory> {
        return this.apiService
            .get<{ data: IEquipmentCategory }>(`/equipmentCategories/${id}`)
            .pipe(map((res) => res.data));
    }

    create(context: ICreateEquipmentCategoryDTO): Observable<IEquipmentCategory> {
        return this.apiService
            .post<{ data: IEquipmentCategory }, ICreateEquipmentCategoryDTO>(`/equipmentCategories`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateEquipmentCategoryDTO): Observable<IEquipmentCategory> {
        return this.apiService
            .put<{ data: IEquipmentCategory }, ICreateEquipmentCategoryDTO>(`/equipmentCategories/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IEquipmentCategory }>(`/equipmentCategories/${id}`);
    }

    getAssignedById(id: string): Observable<IEquipmentCategory> {
        return this.apiService.get(`/equipmentCategories/${id}/assignedItems`);
    }
}
