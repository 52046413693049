import { GenericStateModel, LinkedItemsStateModel } from '@data/state';
import { ICompany, IContact, IContract, IItem, IIssue, ILinkCounts, IHistory } from '@data/schema';

export interface CompanyStateModel extends GenericStateModel<ICompany> {
    manufacturers?: ICompany[];
    suppliers?: ICompany[];
    linkCounts?: ILinkCounts;
    linkedItems?: {
        companies?: LinkedItemsStateModel<ICompany>;
        contacts?: LinkedItemsStateModel<IContact>;
        contractsAsRecipient?: LinkedItemsStateModel<IContract>;
        contractsAsProvider?: LinkedItemsStateModel<IContract>;
        purchasedItems?: LinkedItemsStateModel<IItem>;
        suppliedItems?: LinkedItemsStateModel<IItem>;
        manufacturedItems?: LinkedItemsStateModel<IItem>;
        issuesAsItemCompany?: LinkedItemsStateModel<IIssue>;
        issuesAsContractCompany?: LinkedItemsStateModel<IIssue>;
        notes: LinkedItemsStateModel<IHistory>;
    };
}
