// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// states
import { AuthState } from '../auth/auth.state';

// actions
import {
    CreateContact,
    CreateNoteForContact,
    DeleteContact,
    DeleteContacts,
    GetContact,
    GetContacts,
    GetLinkCountsForContact,
    GetNotesForContact,
    GetTableLayoutForContact,
    ResetContact,
    UpdateContact,
    UpdateTableLayoutForContact,
} from './contact.actions';

import { UpdateUserProfile } from '../auth/auth.actions';

// services
import { ContactService, HistoryService, TableLayoutService } from '@data/service';

// schemas
import { ContactStateModel } from './contact-state.model';

// enums
import { ContactDataTableCellType, DataTableCellType } from '@data/enum';

@State<ContactStateModel>({
    name: 'contacts',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        { data: 'firstName', title: 'First Name', type: ContactDataTableCellType.FIRST_NAME_CELL },
                        { data: 'lastName', title: 'Last Name' },
                        { data: 'email', title: 'Email' },
                        { data: 'telMobile', title: 'Tel Mobile' },
                        { data: 'telWork', title: 'Tel Work' },
                    ],
                },
                isDefault: true,
            },
        },
        linkCounts: null,
    },
})
@Injectable()
export class ContactState {
    constructor(
        private contactService: ContactService,
        private store: Store,
        private historyService: HistoryService,
        private tableLayoutService: TableLayoutService,
    ) {}

    @Selector()
    static getContactList(state: ContactStateModel) {
        return state.items.data;
    }

    @Selector()
    static getContactListLoading(state: ContactStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentContact(state: ContactStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getLinkCounts(state: ContactStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getTableLayout(state: ContactStateModel) {
        return state.items?.tableLayout?.data?.layout;
    }

    @Selector()
    static getNotes(state: ContactStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: ContactStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetContacts)
    getContacts({ getState, patchState }: StateContext<ContactStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.contactService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetContact)
    getContact({ getState, setState, patchState }: StateContext<ContactStateModel>, { id }: GetContact) {
        this.resetLinkedData({ patchState });
        const state = getState();
        const currentItem = state.items.data.find((item) => item._id === id);

        if (currentItem) {
            return setState({
                ...state,
                currentItem: {
                    data: currentItem,
                    loading: false,
                    retrievedAt: Date.now(),
                },
            });
        }

        return this.contactService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetLinkCountsForContact)
    GetLinkCountsForContact({ patchState }: StateContext<ContactStateModel>, { id }: GetLinkCountsForContact) {
        return this.contactService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetTableLayoutForContact)
    GetTableLayoutForContact(
        { getState, patchState }: StateContext<ContactStateModel>,
        { page }: GetTableLayoutForContact,
    ) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                {
                                    data: 'firstName',
                                    title: 'First Name',
                                    type: ContactDataTableCellType.FIRST_NAME_CELL,
                                },
                                { data: 'lastName', title: 'Last Name' },
                                { data: 'email', title: 'Email' },
                                { data: 'telMobile', title: 'Tel Mobile' },
                                { data: 'telWork', title: 'Tel Work' },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(GetNotesForContact)
    GetNotesForContact(
        { getState, patchState }: StateContext<ContactStateModel>,
        { id, skip, limit }: GetNotesForContact,
    ) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(CreateNoteForContact)
    createNoteForContact({ getState, patchState }: StateContext<ContactStateModel>, { payload }: CreateNoteForContact) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateContact)
    createContact({ getState, patchState }: StateContext<ContactStateModel>, { payload }: CreateContact) {
        const state = getState();

        return this.contactService.create(payload).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateTableLayoutForContact)
    updateTableLayoutForContact(
        { getState, patchState }: StateContext<ContactStateModel>,
        { payload, page }: UpdateTableLayoutForContact,
    ) {
        const state = getState();

        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(UpdateContact)
    updateContact({ getState, setState }: StateContext<ContactStateModel>, { id, payload }: UpdateContact) {
        return this.contactService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                const currentUser = this.store.selectSnapshot(AuthState.currentUser);

                if (currentUser?.user?._id === response?._id) {
                    this.store.dispatch(new UpdateUserProfile(response));
                }

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });
            }),
        );
    }

    @Action(DeleteContact)
    deleteContact({ getState, setState }: StateContext<ContactStateModel>, { id }: DeleteContact) {
        return this.contactService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteContacts)
    deleteContacts({ getState, setState, patchState }: StateContext<ContactStateModel>, { ids }: DeleteContacts) {
        return this.contactService.deleteBatch(ids);
    }

    @Action(ResetContact)
    resetContact({ getState, patchState }: StateContext<ContactStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
        });
    }
}
