// modules
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// enums
import { ScreenSize } from '@data/enum';

@Injectable({
    providedIn: 'root',
})
export class ResizeService {
    private resizeBehaviorSubject: BehaviorSubject<ScreenSize>;

    constructor() {
        this.resizeBehaviorSubject = new BehaviorSubject(ScreenSize.LG);
    }

    get onResize$(): Observable<ScreenSize> {
        return this.resizeBehaviorSubject.asObservable().pipe(distinctUntilChanged());
    }

    onResize(size: ScreenSize) {
        this.resizeBehaviorSubject.next(size);
    }
}
