<accordion-group
    #element
    class="accordion-item shadow-sm my-4 border-5 border-start"
    [isOpen]="isOpen"
    [ngClass]="borderClass"
    (isOpenChange)="onChange($event)"
>
    <div accordion-heading>
        <h6 class="mb-0">
            {{ title }}
            <span class="badge rounded-pill bg-danger fw-medium fs-10 align-middle">{{ itemCount }}</span>
            <span class="float-end">
                <i
                    class="toggle-icon bi"
                    [ngClass]="{ 'bi-chevron-up': element.isOpen, 'bi-chevron-down': !element.isOpen }"
                ></i>
            </span>
        </h6>
    </div>
    <div class="border-1 border-top pb-3" *ngIf="!isTab">
        <app-data-table
            *ngIf="dataTableEnableServerSide?.length > 0 && dataTableEnableServerSide[0] && element.isOpen"
            #dataTable
            [dataTableInfo]="dataTableInfo"
            [dataTableCols]="dataTableCols[0]"
            [dataTableSource]="
                !(dataTableEnableServerSide?.length > 0 && dataTableEnableServerSide[0]) && dataTableSource$[0] | async
            "
            [dataTableSourceLoading]="
                dataTableEnableServerSide?.length > 0 && dataTableEnableServerSide[0] && element.isOpen
                    ? false
                    : (dataLoading$[0] | async)
            "
            [dataTableActionButtonEnabled]="false"
            [linkedDataTableActionButtonEnabled]="linkedDataTableActionButtonEnabled"
            [dataTableSelectAllCheckBoxEnabled]="true"
            [dataTableEnableSearch]="dataTableEnableSearch"
            [dataTableActionButtonDisabledState]="(completeSummary?.totalPercentage | json) > 0 ? true : false"
            [linkedDataTableActionButtons]="linkedDataTableActionButtons"
            [dataTableEnableServerSide]="dataTableEnableServerSide?.length > 0 ? dataTableEnableServerSide[0] : false"
            [dataTableServerSideCallbackFn]="dataTableServerSideCallbackFn"
            [userRole]="userRole"
            (actionButtonEvent)="outputActionButtonEvent($event, 0)"
            (selectAllCheckBoxEvent)="onSelectAllCheckBoxValueChanges($event, 0)"
        >
            <!-- Cell override -->
            <ng-template
                ng-datatable-cell-tmp
                let-item="item"
                let-columns="columns"
                let-currentColumns="currentColumns"
            >
                <td *ngFor="let column of currentColumns">
                    <ng-container [ngSwitch]="column.type">
                        <ng-container *ngSwitchCase="contactDataTableCellType.PARENT_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.parent?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="companyDataTableCellType.NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="contactDataTableCellType.FIRST_NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/contacts/' + item?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="contractDataTableCellType.PROVIDER_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.provider?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="contractDataTableCellType.RECIPIENT_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.recipient?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="contractDataTableCellType.CONTRACT_NUMBER_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/contracts/' + item?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="itemDataTableCellType.ITEM_NUMBER_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/items/' + item?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="itemDataTableCellType.MANUFACTURER_NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.manufacturer?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="itemDataTableCellType.PURCHASER_NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.purchaser?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="itemDataTableCellType.SUPPLIER_NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.supplier?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="itemDataTableCellType.CHECK_LIST_STATUS_CELL">
                            <div class="mb-2 d-flex">
                                <span class="text-secondary">
                                    {{
                                        dataTable.getColumnData(item, column) > 0
                                            ? dataTable.getColumnData(item, column) + '%'
                                            : '0%'
                                    }}</span
                                >
                                &nbsp;&nbsp;
                                <progressbar
                                    [value]="dataTable.getColumnData(item, column)"
                                    [type]="dataTable.getColumnData(item, column) === 100 ? 'success' : 'warning'"
                                ></progressbar>
                                &nbsp;&nbsp;
                                <i
                                    *ngIf="item?.templateName"
                                    class="bi bi-eye text-primary"
                                    role="button"
                                    [routerLink]="'/service-records/checklist/' + item?.serviceItemId"
                                    [queryParams]="{
                                        name: item?.templateName,
                                        itemNumber: item?.itemNumber,
                                        serviceId: serviceId
                                    }"
                                ></i>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="issueDataTableCellType.ISSUE_NUMBER_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/issue-records/' + item?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="issueDataTableCellType.ITEM_COMPANY_NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.itemCompany?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="issueDataTableCellType.CONTRACT_COMPANY_NAME_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/companies/' + item?.contractCompany?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="serviceDataTableCellType.SERVICE_NUMBER_CELL">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/service-records/' + item?._id"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="checkListDataTableCellType.CHECK_LIST_NAME">
                            <a
                                class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                [routerLink]="'/settings/check-list/update/' + item?.template?._id"
                                [queryParams]="{ name: item?.template?.checkListName }"
                            >
                                {{ dataTable.getColumnData(item, column) }}
                            </a>
                        </ng-container>

                        <ng-container *ngSwitchCase="checkListDataTableCellType.NO_OF_ITEMS">
                            <div class="mb-2 mt-2">
                                <div class="d-flex text-secondary">
                                    <span
                                        >{{ completeSummary?.completeItems?.complete | json }}/{{
                                            completeSummary?.completeItems?.total | json
                                        }}</span
                                    >&nbsp;&nbsp;
                                    <progressbar
                                        class="ms-3"
                                        [value]="completeSummary?.taskPercentage"
                                        [type]="completeSummary?.taskPercentage === 100 ? 'success' : 'warning'"
                                    ></progressbar>
                                </div>
                                &nbsp;&nbsp;
                                <div class="d-flex text-secondary">
                                    <span>{{
                                        completeSummary?.totalPercentage
                                            ? (completeSummary?.totalPercentage | number : '1.0-0') + '%'
                                            : '0%'
                                    }}</span
                                    >&nbsp;&nbsp;
                                    <progressbar
                                        class="ms-2"
                                        [value]="completeSummary?.totalPercentage"
                                        [type]="completeSummary?.totalPercentage === 100 ? 'success' : 'warning'"
                                    ></progressbar>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="dataTableCellType.DATE_CELL">
                            <span class="text-nowrap">{{
                                dataTable.getColumnData(item, column) | date : 'yyyy-MM-dd'
                            }}</span>
                        </ng-container>

                        <ng-container *ngSwitchCase="dataTableCellType.STATUS_CELL">
                            <span
                                class="badge"
                                [ngClass]="{
                                    'bg-success':
                                        dataTable.getColumnData(item, column).toLowerCase() === 'active' ||
                                        dataTable.getColumnData(item, column) === 'completed',
                                    'bg-danger':
                                        dataTable.getColumnData(item, column).toLowerCase() === 'inactive' ||
                                        dataTable.getColumnData(item, column) === 'cancelled',
                                    'bg-warning': dataTable.getColumnData(item, column) === 'pending'
                                }"
                            >
                                {{ dataTable.getColumnData(item, column) | uppercase }}
                            </span>
                        </ng-container>

                        <ng-container *ngSwitchCase="contactDataTableCellType.PARTNER_LOGIN_CELL">
                            <span
                                class="badge"
                                [ngClass]="{
                                    'bg-success': dataTable.getColumnData(item, column),
                                    'bg-danger': !dataTable.getColumnData(item, column)
                                }"
                            >
                                {{ dataTable.getColumnData(item, column) ? 'ENABLED' : 'DISABLED' }}
                            </span>
                        </ng-container>

                        <ng-container *ngSwitchCase="dataTableCellType.CHECKBOX_INPUT">
                            <form [formGroup]="getFormGroup(0)">
                                <div formArrayName="items" *ngFor="let bi of f(0)['items']['controls']; let i = index">
                                    <div
                                        [formGroupName]="i"
                                        *ngIf="p(0).controls[i]['controls']._id.value === item._id"
                                    >
                                        <app-checkbox-input
                                            formControlName="isSelected"
                                            [label]=""
                                            [customClasses]="checkboxCustomClasses"
                                        ></app-checkbox-input>
                                    </div>
                                </div>
                            </form>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            {{ dataTable.getColumnData(item, column) }}
                        </ng-container>
                    </ng-container>
                </td>
            </ng-template>
            <!-- //. Cell override -->
        </app-data-table>
    </div>
    <div class="border-1 border-top py-3 px-1" *ngIf="isTab">
        <tabset>
            <tab *ngFor="let item of tabData; let x = index" (selectTab)="onTabSelected(x)">
                <ng-template tabHeading><i class="me-2 bi" [ngClass]="item?.icon"></i>{{ item?.title }}</ng-template>
                <app-data-table
                    *ngIf="dataTableEnableServerSide?.length > 0 && dataTableEnableServerSide[0] && element.isOpen"
                    #dataTable
                    [dataTableInfo]="dataTableInfo"
                    [dataTableCols]="dataTableCols[x]"
                    [dataTableSource]="
                        !(dataTableEnableServerSide?.length > 0 && dataTableEnableServerSide[0]) && dataTableSource$[x]
                            | async
                    "
                    [dataTableSourceLoading]="
                        dataTableEnableServerSide?.length > 0 && dataTableEnableServerSide[0] && element.isOpen
                            ? false
                            : (dataLoading$[x] | async)
                    "
                    [dataTableActionButtonEnabled]="false"
                    [linkedDataTableActionButtonEnabled]="true"
                    [dataTableSelectAllCheckBoxEnabled]="true"
                    [dataTableEnableServerSide]="
                        dataTableEnableServerSide?.length > 0 ? dataTableEnableServerSide[0] : false
                    "
                    [dataTableServerSideCallbackFn]="dataTableServerSideCallbackFn"
                    [linkedDataTableActionButtons]="linkedDataTableActionButtons"
                    [userRole]="userRole"
                    (actionButtonEvent)="outputActionButtonEvent($event, x)"
                    (selectAllCheckBoxEvent)="onSelectAllCheckBoxValueChanges($event, x)"
                >
                    <!-- Cell override -->
                    <ng-template
                        ng-datatable-cell-tmp
                        let-item="item"
                        let-columns="columns"
                        let-currentColumns="currentColumns"
                    >
                        <td *ngFor="let column of currentColumns">
                            <ng-container [ngSwitch]="column.type">
                                <ng-container *ngSwitchCase="contactDataTableCellType.PARENT_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.parent?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="companyDataTableCellType.NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="contactDataTableCellType.FIRST_NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/contacts/' + item?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="contractDataTableCellType.PROVIDER_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.provider?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="contractDataTableCellType.RECIPIENT_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.recipient?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="contractDataTableCellType.CONTRACT_NUMBER_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/contracts/' + item?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="itemDataTableCellType.ITEM_NUMBER_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/items/' + item?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="itemDataTableCellType.MANUFACTURER_NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.manufacturer?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="itemDataTableCellType.PURCHASER_NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.purchaser?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="itemDataTableCellType.SUPPLIER_NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.supplier?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="issueDataTableCellType.ISSUE_NUMBER_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/issue-records/' + item?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="issueDataTableCellType.ITEM_COMPANY_NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.itemCompany?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="issueDataTableCellType.CONTRACT_COMPANY_NAME_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/companies/' + item?.contractCompany?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="serviceDataTableCellType.SERVICE_NUMBER_CELL">
                                    <a
                                        class="mb-0 text-primary fw-normal cursor-pointer text-decoration-none"
                                        [routerLink]="'/service-records/' + item?._id"
                                    >
                                        {{ dataTable.getColumnData(item, column) }}
                                    </a>
                                </ng-container>

                                <ng-container *ngSwitchCase="serviceDataTableCellType.SERVICE_CHECKLIST_NAME">
                                    <div
                                        *ngIf="item?.checkList"
                                        class="d-flex align-self-center justify-content-center'"
                                        role="button"
                                        [routerLink]="'/settings/check-list/update/' + item?.checkList?._id"
                                        [queryParams]="{
                                            name: item?.checkList?.checkListName
                                        }"
                                        [routerLink]="'/service-records/checklist/' + item?.template[0]?._id"
                                        [queryParams]="{
                                            name: item?.checkList?.checkListName,
                                            itemNumber: item?.item[0]?.itemNumber,
                                            serviceId: item?._id
                                        }"
                                    >
                                        <i class="bi bi-eye text-primary" role="button"></i>
                                        <span class="text-primary">&nbsp;&nbsp; View Checklist</span>
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchCase="dataTableCellType.DATE_CELL">
                                    <span class="text-nowrap">{{
                                        dataTable.getColumnData(item, column) | date : 'yyyy-MM-dd'
                                    }}</span>
                                </ng-container>

                                <ng-container *ngSwitchCase="dataTableCellType.STATUS_CELL">
                                    <span
                                        class="badge"
                                        [ngClass]="{
                                            'bg-success':
                                                dataTable.getColumnData(item, column).toLowerCase() === 'active' ||
                                                dataTable.getColumnData(item, column) === 'completed',
                                            'bg-danger':
                                                dataTable.getColumnData(item, column).toLowerCase() === 'inactive' ||
                                                dataTable.getColumnData(item, column) === 'cancelled',
                                            'bg-warning': dataTable.getColumnData(item, column) === 'pending'
                                        }"
                                    >
                                        {{ dataTable.getColumnData(item, column) | uppercase }}
                                    </span>
                                </ng-container>

                                <ng-container *ngSwitchCase="contactDataTableCellType.PARTNER_LOGIN_CELL">
                                    {{ dataTable.getColumnData(item, column) ? 'ENABLED' : 'DISABLED' }}
                                </ng-container>

                                <ng-container *ngSwitchCase="dataTableCellType.CHECKBOX_INPUT">
                                    <form [formGroup]="getFormGroup(x)">
                                        <div
                                            formArrayName="items"
                                            *ngFor="let bi of f(x)['items']['controls']; let i = index"
                                        >
                                            <div
                                                [formGroupName]="i"
                                                *ngIf="p(x).controls[i]['controls']._id.value === item._id"
                                            >
                                                <app-checkbox-input
                                                    formControlName="isSelected"
                                                    [label]=""
                                                    [customClasses]="checkboxCustomClasses"
                                                ></app-checkbox-input>
                                            </div>
                                        </div>
                                    </form>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                    {{ dataTable.getColumnData(item, column) }}
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-template>
                    <!-- //. Cell override -->
                </app-data-table>
            </tab>
        </tabset>
    </div>
</accordion-group>
