// schemas
import { ICreateContractTypeDTO } from '@data/schema';

export class GetContractTypes {
    static readonly type = '[CONTRACT-TYPE] GET CONTRACT-TYPES';
}

export class GetContractType {
    static readonly type = '[CONTRACT-TYPE] GET CONTRACT-TYPE';
    constructor(public id: string) {}
}

export class CreateContractType {
    static readonly type = '[CONTRACT-TYPE] CREATE CONTRACT-TYPE';
    constructor(public payload: ICreateContractTypeDTO) {}
}

export class UpdateContractType {
    static readonly type = '[CONTRACT-TYPE] UPDATE CONTRACT-TYPE';
    constructor(public id: string, public payload: ICreateContractTypeDTO) {}
}

export class DeleteContractType {
    static readonly type = '[CONTRACT-TYPE] DELETE CONTRACT-TYPE';
    constructor(public id: string) {}
}
