<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Installation</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="installation_modal_form_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-8">
                    <!-- form-input: installation number -->
                    <app-text-input
                        [formControl]="f['installationNumber']"
                        [label]="'Installation#'"
                        testAutomationSelectorName="installation_modal_input_installation_number"
                    ></app-text-input>
                    <!-- //. form-input: installation number -->
                </div>
                <div class="col-4 ps-0 pt-1">
                    <!-- form-button: generate -->
                    <app-spinner-button
                        buttonText="Generate"
                        [isProcessing]="isNumberGenerating"
                        [customClasses]="generateButtonCustomClasses"
                        (onButtonClick)="generate()"
                    ></app-spinner-button>
                    <!-- //. form-button: generate -->
                </div>
                <div class="col-12">
                    <!-- form-input: item -->
                    <app-select-input
                        [formControl]="f['item']"
                        [label]="'Item'"
                        [list]="items$ | async"
                        [bindField]="'_id'"
                        [labelField]="'itemNumber'"
                        testAutomationSelectorName="installation_modal_select_item"
                        (onOptionSelect)="onItemChange($event)"
                    ></app-select-input>
                    <!-- //. form-input: item -->
                </div>
                <div class="col-12">
                    <!-- form-input: item type -->
                    <app-select-input
                        [formControl]="f['itemType']"
                        [label]="'Item Type'"
                        [list]="equipmentTypesSource$ | async"
                        [bindField]="'_id'"
                        [labelField]="'name'"
                        testAutomationSelectorName="installation_modal_select_item_type"
                    ></app-select-input>
                    <!-- //. form-input: item type -->
                </div>
                <div class="col-12">
                    <!-- form-input: item manufacturer -->
                    <app-select-input
                        [formControl]="f['itemManufacturer']"
                        [label]="'Item Manufacturer'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="installation_modal_select_item_manufacturer"
                    ></app-select-input>
                    <!-- //. form-input: item manufacturer -->
                </div>
                <div class="col-12">
                    <!-- form-input: item supplier -->
                    <app-select-input
                        [formControl]="f['itemSupplier']"
                        [label]="'Item Supplier'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="installation_modal_select_item_supplier"
                    ></app-select-input>
                    <!-- //. form-input: item manufacturer -->
                </div>
                <div class="col-12">
                    <!-- form-input: item purchaser -->
                    <app-select-input
                        [formControl]="f['itemPurchaser']"
                        [label]="'Item Purchaser'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="installation_modal_select_item_purchaser"
                    ></app-select-input>
                    <!-- //. form-input: item purchaser -->
                </div>
                <div class="col-12">
                    <!-- form-input: startDate -->
                    <app-text-input
                        [formControl]="f['startDate']"
                        [label]="'Start Date'"
                        type="date"
                        testAutomationSelectorName="installation_modal_input_start_date"
                    ></app-text-input>
                    <!-- //. form-input: startDate -->
                </div>
                <div class="col-12">
                    <!-- form-input: endDate -->
                    <app-text-input
                        [formControl]="f['endDate']"
                        [label]="'End Date'"
                        type="date"
                        testAutomationSelectorName="installation_modal_input_end_date"
                    ></app-text-input>
                    <!-- //. form-input: endDate -->
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
