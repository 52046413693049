<div [class]="customClasses.formGroup">
    <ng-container *ngIf="!inputGroupAppendEnabled">
        <div class="row" *ngIf="secondaryLabel">
            <div class="col">
                <label [class]="customClasses.label">{{ label }}</label>
            </div>
            <div class="col-auto">
                <label [class]="customClasses.secondaryLabel">{{ secondaryLabel }}</label>
            </div>
        </div>
        <label [class]="customClasses.label" [class.disabled]="ngControl.disabled" *ngIf="!secondaryLabel">
            {{ label }}
            <span *ngIf="inputRequiredIndicatorEnabled"> * </span>
        </label>
        <input
            *ngIf="!inputDecimalCheckEnabled"
            [class.is-invalid]="ngControl.touched && ngControl.invalid"
            type="{{ type }}"
            [class]="customClasses.input"
            [formControl]="ngControl.control"
            placeholder="{{ placeholder }}"
            (blur)="onInputFocus(false)"
            (focus)="onInputFocus(true)"
            [attr.data-qa]="testAutomationSelectorName"
        />
        <input
            *ngIf="inputDecimalCheckEnabled"
            [class.is-invalid]="ngControl.touched && ngControl.invalid"
            type="text"
            [class]="customClasses.input"
            [formControl]="ngControl.control"
            placeholder="{{ placeholder }}"
            inputmode="numeric"
            [pattern]="inputDecimalPattern"
            digitOnly
            [decimal]="true"
            [attr.data-qa]="testAutomationSelectorName"
        />
    </ng-container>
    <ng-container *ngIf="inputGroupAppendEnabled">
        <label [class]="customClasses.label">{{ label }}</label>
        <div class="input-group" [class.is-invalid]="ngControl.touched && ngControl.invalid">
            <input
                *ngIf="!inputDecimalCheckEnabled"
                [class.is-invalid]="ngControl.touched && ngControl.invalid"
                type="{{ type }}"
                [class]="customClasses.input"
                [formControl]="ngControl.control"
                placeholder="{{ placeholder }}"
                [attr.data-qa]="testAutomationSelectorName"
            />
            <div class="input-group-append">
                <div class="input-group-text" *ngIf="inputGroupAppendText">{{ inputGroupAppendText }}</div>
                <button
                    class="btn btn-outline-primary"
                    type="button"
                    *ngIf="inputGroupAppendButtonText"
                    (click)="onAppendClick()"
                    [disabled]="inputGroupAppendButtonProcessing || inputGroupAppendButtonDisabled"
                >
                    <span
                        *ngIf="inputGroupAppendButtonProcessing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    {{ inputGroupAppendButtonText }}
                </button>
            </div>
        </div>
    </ng-container>

    <password-strength-meter
        *ngIf="inputPasswordStrengthCheckerEnabled && (inputFocused || (ngControl.touched && ngControl.invalid))"
        [password]="ngControl.value"
        [numberOfProgressBarItems]="4"
        [minPasswordLength]="4"
        [enableFeedback]="true"
        [colors]="inputPasswordStrengthCheckerColors"
    ></password-strength-meter>

    <div
        *ngIf="ngControl.control.errors?.['required'] && label"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required'"
    >
        Please enter {{ (label | isVowel) ? 'an' : 'a' }} {{ label | lowercase }}
    </div>
    <div
        *ngIf="ngControl.control.errors?.['min'] || ngControl.control.errors?.['minlength']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_min'"
    >
        {{ label }} must be at least
        {{
            ngControl.control.errors?.min
                ? ngControl.control.errors?.min['min']
                : ngControl.control.errors?.minlength['requiredLength']
        }}
        {{ ngControl.control.errors?.min ? '' : 'characters' }}
    </div>
    <div
        *ngIf="ngControl.control.errors?.['max'] || ngControl.control.errors?.['maxlength']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_max'"
    >
        {{ label }} must be at most
        {{
            ngControl.control.errors.max
                ? ngControl.control.errors?.max['max']
                : ngControl.control.errors?.maxlength['requiredLength']
        }}
        {{ ngControl.control.errors.max ? '' : 'characters' }}
    </div>
    <div
        *ngIf="ngControl.control.errors?.['email']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_invalid_email_format'"
    >
        {{ label }} is not properly formatted
    </div>
    <div
        *ngIf="ngControl.control.errors?.['NoPasswordMatch']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_no_password_match'"
    >
        Passwords do not match
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasNumber']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required_a_number'"
    >
        {{ label }} must contain at least 1 number
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasSmallCase']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required_a_lowercase_letter'"
    >
        {{ label }} must contain at least 1 lowercase letter
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasCapitalCase']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required_a_uppercase_letter'"
    >
        {{ label }} must contain at least 1 uppercase letter
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasSpecialCharacters']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required_a_special_character'"
    >
        {{ label }} must contain at least 1 special character
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasRestrictedCharacters']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_contains_restricted_characters'"
    >
        {{ label }} shouldn't contain restricted characters
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasExistingReference']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_duplicated_reference'"
    >
        {{ label }} reference already exists
    </div>
    <div
        *ngIf="ngControl.control.errors?.['pattern']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_invalid_pattern'"
    >
        {{ label }} does not follow the required pattern
    </div>
    <div
        *ngIf="ngControl.control.errors?.['exceedsMaxQuantity']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_exceeds_max_quantity'"
    >
        {{ label }} exceeds the maximum quantity allowed
    </div>
    <div
        *ngIf="ngControl.control.errors?.['hasInvalidBarcodeFormat']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_invalid_barcode_format'"
    >
        {{ label }} does not follow supported formats
    </div>
    <div *ngIf="ngControl.control.errors?.['invalidEndDate'] && ngControl.control.touched" class="text-danger small">
        Please Select a Last Service Date Correctly
    </div>
</div>
