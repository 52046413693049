export enum PageType {
    COMPANIES = 'company',
    CONTACTS = 'contact',
    CONTRACTS = 'contract',
    ITEMS = 'item',
    INSTALLATION_RECORDS = 'installation',
    SERIVCE_RECORDS = 'service',
    ISSUE_RECORDS = 'issue',
    TENANT_COMPANIES = 'tenantCompany',
}
