// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

// services
import { ApiService } from '@app/service';

// schemas
import { IContact, ICreateContactDTO } from '@data/schema/type/contact';
import { IDataTablePaginationDTO, IDataTablePaginationResponse, ILinkCounts } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class ContactService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IContact[]> {
        return this.apiService.get<{ data: IContact[] }>('/contacts').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IContact>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IContact[]; totalItems: number }>('/contacts', params).pipe(
            map((res) => ({
                data: res.data,
                totalItems: res.data.length,
                totalFilteredItems: !res.data ? 0 : res.totalItems,
            })),
        );
    }

    getAllSuperAdminContactsSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IContact>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IContact[]; totalItems: number }>('/contacts/superAdmins', params).pipe(
            map((res) => ({
                data: res.data,
                totalItems: res.data.length,
                totalFilteredItems: !res.data ? 0 : res.totalItems,
            })),
        );
    }

    getContactsByApplicationAdminSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IContact>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IContact[]; totalItems: number }>('/contacts/applicationAdmin', params).pipe(
            map((res) => ({
                data: res.data,
                totalItems: res.data.length,
                totalFilteredItems: !res.data ? 0 : res.totalItems,
            })),
        );
    }

    getById(id: string): Observable<IContact> {
        return this.apiService.get<{ data: IContact }>(`/contacts/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateContactDTO): Observable<IContact> {
        return this.apiService
            .post<{ data: IContact }, ICreateContactDTO>(`/contacts`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateContactDTO): Observable<IContact> {
        return this.apiService
            .put<{ data: IContact }, ICreateContactDTO>(`/contacts/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IContact }>(`/contacts/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IContact[] }, { ids: string[] }>(`/contacts`, { ids });
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService.get<{ data: ILinkCounts }>(`/contacts/${id}/linkCounts`).pipe(map((res) => res.data));
    }

    getByCompanyId(id: string): Observable<IContact[]> {
        return this.apiService.get<{ data: IContact[] }>(`/contacts/company/${id}`).pipe(map((res) => res.data));
    }

    getByCompanyIdSSR(
        id: string,
        context: IDataTablePaginationDTO,
    ): Observable<IDataTablePaginationResponse<IContact>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService
            .get<{ data: IContact[]; totalItems: number }>(`/contacts/company/${id}`, params)
            .pipe(map((res) => ({ data: res.data, totalItems: res.data.length, totalFilteredItems: res.totalItems })));
    }
}
