import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isVowel',
})
export class IsVowelPipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): boolean {
        if (!value && value.length === 0) {
            return false;
        }

        return /[aeiou]/i.test(value.toLowerCase()[0]);
    }
}
