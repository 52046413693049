import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-web-cam',
    templateUrl: './web-cam.component.html',
    styleUrls: ['./web-cam.component.scss'],
})
export class WebCamComponent implements OnInit {
    @Output() actionButtonEvent = new EventEmitter();

    showWebcam = false;
    allowCameraSwitch = true;
    multipleWebcamsAvailable = false;

    errors: WebcamInitError[] = [];

    // latest snapshot
    webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

    constructor() {}

    ngOnInit() {}

    handleInitError(error: WebcamInitError): void {
        if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
            console.warn('Camera access was not allowed by user!');
        }
    }

    triggerSnapshot(): void {
        this.trigger.next();
    }

    showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    handleImage(webcamImage: WebcamImage): void {
        this.actionButtonEvent.emit(webcamImage);
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }
}
