// schemas
import { ICreateLocationDTO } from '@data/schema';

export class GetLocations {
    static readonly type = '[LOCATION] GET LOCATIONS';
}

export class GetLocation {
    static readonly type = '[LOCATION] GET LOCATION';
    constructor(public id: string) {}
}

export class CreateLocation {
    static readonly type = '[LOCATION] CREATE LOCATION';
    constructor(public payload: ICreateLocationDTO) {}
}

export class UpdateLocation {
    static readonly type = '[LOCATION] UPDATE LOCATION';
    constructor(public id: string, public payload: ICreateLocationDTO) {}
}

export class DeleteLocation {
    static readonly type = '[LOCATION] DELETE LOCATION';
    constructor(public id: string) {}
}
