// schemas
import { ICreateIssueTypeDTO } from '@data/schema';

export class GetIssueTypes {
    static readonly type = '[ISSUE-TYPE] GET ISSUE-TYPES';
}

export class GetIssueType {
    static readonly type = '[ISSUE-TYPE] GET ISSUE-TYPE';
    constructor(public id: string) {}
}

export class CreateIssueType {
    static readonly type = '[ISSUE-TYPE] CREATE ISSUE-TYPE';
    constructor(public payload: ICreateIssueTypeDTO) {}
}

export class UpdateIssueType {
    static readonly type = '[ISSUE-TYPE] UPDATE ISSUE-TYPE';
    constructor(public id: string, public payload: ICreateIssueTypeDTO) {}
}

export class DeleteIssueType {
    static readonly type = '[ISSUE-TYPE] DELETE ISSUE-TYPE';
    constructor(public id: string) {}
}
