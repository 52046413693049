// core
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// schemas
import { IButtonCustomClass } from '@data/schema';

@Component({
    selector: 'app-spinner-button',
    templateUrl: './spinner-button.component.html',
    styleUrls: ['./spinner-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerButtonComponent implements OnInit {
    @Input() buttonText: string;
    @Input() isProcessing = false;
    @Input() isDisabled = false;
    @Input() type: 'button' | 'submit' = 'button';
    @Input() customClasses: IButtonCustomClass = {
        button: 'btn btn-primary',
        spinner: 'spinner-border spinner-border-sm',
    };
    @Input() testAutomationSelectorName: string;

    @Output() onButtonClick = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    click() {
        this.onButtonClick.emit();
    }
}
