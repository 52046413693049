// core
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';

// actions
import { GetProfileImage, UpdateProfileImage } from './profile.actions';

// services
import {
    FileService,
} from '@data/service';

// schemas
import { ProfileStateModel } from './profile-state.model';


@State<ProfileStateModel>({
    name: 'profile',
    defaults: {
        items: {
            profileLink: null,
            loading: true,
        },
    },
})
@Injectable()
export class ProfileState {
    constructor(private fileService: FileService) {}

    @Selector()
    static getProfileImage(state: ProfileStateModel) {
        return state.items.profileLink;
    }

    @Action(GetProfileImage)
    getProfileImage({ getState, patchState }: StateContext<ProfileStateModel>) {
        const state = getState();

        return this.fileService.getProfileImage().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        profileLink: response?.url,
                        loading: false,
                    },
                });
            }),
        );
    }

    @Action(UpdateProfileImage)
    updateProfileImage(
        { getState, patchState }: StateContext<ProfileStateModel>,
        { entityType, file }: UpdateProfileImage,
    ) {
        const state = getState();

        return this.fileService.profileUpdate(entityType, file).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        profileLink: response?.url,
                        loading: false,
                    },
                });
            }),
        );
    }
}
