// core
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';

// actions
import { GetDashboardData, GetDashboardSummaries } from './dashboard.actions';

// services
import { CompanyService, DashboardService } from '@data/service';

// schemas
import { DashboardStateModel } from './dashboard-state.model';

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: {
        items: {
            data: [],
            loading: true,
        },
        summaries: null,
    },
})
@Injectable()
export class DashboardState {
    constructor(private companyService: CompanyService, private serviceService: DashboardService) {}

    @Selector()
    static getServicesList(state: DashboardStateModel) {
        return state.items.data;
    }

    @Selector()
    static getServicesListLoading(state: DashboardStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getDashboardSummaries(state: DashboardStateModel) {
        return state.summaries.data;
    }

    @Action(GetDashboardData)
    getDashboardData(
        { getState, setState, patchState }: StateContext<DashboardStateModel>,
        { startDate, endDate, serviceStatus, provider, recipient, contractStatus }: GetDashboardData,
    ) {
        const state = getState();
        patchState({
            ...state,
            items: {
                ...state.items,
                loading: true,
            },
        });

        return this.serviceService.get(startDate, endDate, serviceStatus, provider, recipient, contractStatus).pipe(
            tap((response) => {
                setState({
                    ...state,
                    items: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetDashboardSummaries)
    getDashboardSummaries({ getState, setState }: StateContext<DashboardStateModel>) {
        const state = getState();

        return this.companyService.getDashboardSummaries().pipe(
            tap((response) => {
                setState({
                    ...state,
                    summaries: {
                        data: response,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }
}
