// core
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

// schemas
import { IFormCustomClass } from '@data/schema';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() secondaryLabel: string;
    @Input() placeholder: string;
    @Input() type = 'text';
    @Input() inputGroupAppendEnabled = false;
    @Input() inputGroupAppendText: string;
    @Input() inputGroupAppendButtonText: string;
    @Input() inputGroupAppendButtonProcessing = false;
    @Input() inputGroupAppendButtonDisabled = false;
    @Input() inputDecimalCheckEnabled = false;
    @Input() inputDecimalPattern = new RegExp('^\\d+(\\.\\d{1,2})?$');
    @Input() inputRequiredIndicatorEnabled = false;
    @Input() inputPasswordStrengthCheckerEnabled = false;
    @Input() inputPasswordStrengthCheckerColors = ['#D92D20', '#DC6803', '#0086C9', '#05603A'];
    @Input() testAutomationSelectorName: string;
    @Input() customClasses: IFormCustomClass = {
        formGroup: 'form-group mb-3',
        label: 'fw-medium text-gray-700 fs-14 mb-0',
        input: 'form-control',
        secondaryLabel: 'text-gray-700 mb-0 fs-10',
    };

    @Output() onAppendButtonClick = new EventEmitter();

    inputFocused = false;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    writeValue(obj: any): void {}

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}

    onAppendClick() {
        this.onAppendButtonClick.emit(true);
    }

    onInputFocus(focused: boolean) {
        this.inputFocused = focused;
    }
}
