// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { IContractType, ICreateContractTypeDTO } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class ContractTypeService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IContractType[]> {
        return this.apiService.get<{ data: IContractType[] }>('/contractTypes').pipe(map((res) => res.data));
    }

    getById(id: string): Observable<IContractType> {
        return this.apiService.get<{ data: IContractType }>(`/contractTypes/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateContractTypeDTO): Observable<IContractType> {
        return this.apiService
            .post<{ data: IContractType }, ICreateContractTypeDTO>(`/contractTypes`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateContractTypeDTO): Observable<IContractType> {
        return this.apiService
            .put<{ data: IContractType }, ICreateContractTypeDTO>(`/contractTypes/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IContractType }>(`/contractTypes/${id}`);
    }

    getAssignedById(id: string): Observable<IContractType> {
        return this.apiService.get(`/contractTypes/${id}/assignedContracts`);
    }
}
