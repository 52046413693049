import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/service';
import { UserRole } from '@data/enum';
import { AuthState, GetTenant } from '@data/state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
    @Select(AuthState.userRole) userRole$: Observable<string>;
    @Select(AuthState.tenantId) tenantId$: Observable<string>;
    @Select(AuthState.tenant) companyName$: Observable<string>;

    userRoleType = UserRole;

    adminRoutes = [
        { name: 'Dashboard', icon: 'bi-grid', route: '/dashboard' },
        { name: 'Companies', icon: 'bi-briefcase', route: '/companies' },
        { name: 'Contacts', icon: 'bi-people', route: '/contacts' },
        { name: 'Contract', icon: 'bi-file-earmark-medical', route: '/contracts' },
        { name: 'Items', icon: 'bi-folder2-open', route: '/items' },
        { name: 'Installation Records', icon: 'bi-boxes', route: '/installation-records' },
        { name: 'Service Records', icon: 'bi-clipboard2-check', route: '/service-records' },
        { name: 'Issue Records', icon: 'bi-bug', route: '/issue-records' },
        {
            name: 'Settings',
            icon: 'bi-gear-wide-connected',
            route: '/settings',
            hasSubMenu: true,
            subMenu: [
                { name: 'Manage List values', route: '/settings/manage-list-values', icon: 'bi-pencil-square' },
                { name: 'Checklists', route: '/settings/check-list', icon: 'bi-check-square' },
            ],
        },
    ];

    viewRoutes = [
        { name: 'Dashboard', icon: 'bi-grid', route: '/dashboard' },
        { name: 'Companies', icon: 'bi-briefcase', route: '/companies' },
        { name: 'Contacts', icon: 'bi-people', route: '/contacts' },
        { name: 'Contract', icon: 'bi-file-earmark-medical', route: '/contracts' },
        { name: 'Items', icon: 'bi-folder2-open', route: '/items' },
        { name: 'Installation Records', icon: 'bi-boxes', route: '/installation-records' },
        { name: 'Service Records', icon: 'bi-clipboard2-check', route: '/service-records' },
        { name: 'Issue Records', icon: 'bi-bug', route: '/issue-records' },
    ];

    tenantManageRoutes = [
        { name: 'Tenants', icon: 'bi-briefcase', route: '/manage/tenant/company' },
        { name: 'Users', icon: 'bi-people', route: '/manage/tenant/contact' },
    ];

    isSidebarExpanded: boolean = true;
    companyName: string;
    logoUrl: string;

    onDestroy$: Subject<void> = new Subject();

    constructor(public layoutService: LayoutService, private store: Store) {
        this.layoutService.isSidebarExpanded$.subscribe((isExpanded) => {
            this.isSidebarExpanded = isExpanded;
        });
    }

    ngOnInit() {
        this.companyName$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => ((this.companyName = res?.['companyName']), (this.logoUrl = res?.['url'])));
        this.tenantId$.pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
            if (res) {
                this.store.dispatch(new GetTenant(res));
            }
        });
    }
}
