<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Service</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="service_modal_form_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-8">
                    <!-- form-input: service number -->
                    <app-text-input
                        [formControl]="f['serviceNumber']"
                        [label]="'Service#'"
                        testAutomationSelectorName="service_modal_input_service_number"
                    ></app-text-input>
                    <!-- //. form-input: service number -->
                </div>
                <div class="col-4 ps-0 pt-1">
                    <!-- form-button: generate -->
                    <app-spinner-button
                        buttonText="Generate"
                        [isProcessing]="isNumberGenerating"
                        [customClasses]="generateButtonCustomClasses"
                        (onButtonClick)="generate()"
                    ></app-spinner-button>
                    <!-- //. form-button: generate -->
                </div>
                <div class="col-12">
                    <!-- form-input: planned date -->
                    <app-text-input
                        [formControl]="f['plannedDate']"
                        [label]="'Planned Date'"
                        type="date"
                        testAutomationSelectorName="service_modal_planned_date"
                    ></app-text-input>
                    <!-- //. form-input: planned date -->
                </div>
                <div class="col-12">
                    <!-- form-input: onsite start date -->
                    <app-text-input
                        [formControl]="f['onSiteStartDate']"
                        [label]="'Onsite Start Date'"
                        type="date"
                        testAutomationSelectorName="service_modal_onsite_start_date"
                    ></app-text-input>
                    <!-- //. form-input: onsite start date -->
                </div>
                <div class="col-12">
                    <!-- form-input: onsite End date -->
                    <app-text-input
                        [formControl]="f['onSiteEndDate']"
                        [label]="'Onsite End Date'"
                        type="date"
                        testAutomationSelectorName="service_modal_onsite_end_date"
                    ></app-text-input>
                    <!-- //. form-input: onsite eEnd date -->
                </div>
                <div class="col-12">
                    <!-- form-input: status -->
                    <app-select-input
                        [list]="serviceStatus"
                        labelField="name"
                        bindField="value"
                        [formControl]="f['status']"
                        [label]="'Status'"
                        testAutomationSelectorName="service_modal_status"
                    ></app-select-input>
                    <!-- //. form-input: status -->
                </div>
                <div class="col-12">
                    <!-- form-input: description -->
                    <app-text-area-input
                        [formControl]="f['description']"
                        [label]="'Description'"
                        testAutomationSelectorName="service_modal_input_description"
                    ></app-text-area-input>
                    <!-- //. form-input: description -->
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
