// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { IContract, ILinkContractsToService, ILinkServicesToContract, IService } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LinkContractServicesService {
    constructor(private apiService: ApiService) {}

    linkServicesToContract(context: ILinkServicesToContract): Observable<IService[]> {
        return this.apiService
            .post<{ data: IService[] }, ILinkServicesToContract>('/linkContractServices/link/services', context)
            .pipe(map((res) => res.data));
    }

    linkContractsToService(context: ILinkContractsToService): Observable<IContract[]> {
        return this.apiService
            .post<{ data: IContract[] }, ILinkContractsToService>('/linkContractServices/link/contracts', context)
            .pipe(map((res) => res.data));
    }

    unlinkServicesFromContract(context: ILinkServicesToContract): Observable<IService[]> {
        return this.apiService
            .deleteBatch<{ data: IService[] }, ILinkServicesToContract>(
                '/linkContractServices/unlink/services',
                context,
            )
            .pipe(map((res) => res.data));
    }

    unlinkContractsFromService(context: ILinkContractsToService): Observable<IContract[]> {
        return this.apiService
            .deleteBatch<{ data: IContract[] }, ILinkContractsToService>(
                '/linkContractServices/unlink/contracts',
                context,
            )
            .pipe(map((res) => res.data));
    }
    
}
