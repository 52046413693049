<button
    [disabled]="isProcessing || isDisabled"
    [type]="type"
    [class]="customClasses.button"
    [attr.data-qa]="testAutomationSelectorName"
    (click)="click()"
>
    <span *ngIf="isProcessing; else buttonTextTemplate" [class]="customClasses.spinner"></span>

    <ng-template #buttonTextTemplate>
        <span [innerHTML]="buttonText"></span>
    </ng-template>
</button>
