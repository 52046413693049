<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Company</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="company-modal_form_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-12">
                    <!-- form-input: company name -->
                    <app-text-input
                        [formControl]="f['companyName']"
                        [label]="'Company Name'"
                        testAutomationSelectorName="company-modal_input_first_company-name"
                    ></app-text-input>
                    <!-- //. form-input: company name -->
                </div>
                <div class="col-12">
                    <!-- form-input: address1 -->
                    <app-text-input
                        [formControl]="f['address1']"
                        [label]="'Address 1'"
                        testAutomationSelectorName="company-modal_input_last_address1"
                    ></app-text-input>
                    <!-- //. form-input: address1 -->
                </div>
                <div class="col-12">
                    <!-- form-input: email -->
                    <app-text-input
                        [formControl]="f['address2']"
                        [label]="'Address 2'"
                        testAutomationSelectorName="company-modal_input_address2"
                    ></app-text-input>
                    <!-- //. form-input: email -->
                </div>
                <div class="col-12">
                    <!-- form-input: city -->
                    <app-text-input
                        [formControl]="f['cityOrTown']"
                        [label]="'City / Town'"
                        testAutomationSelectorName="company-modal_input_last_city"
                    ></app-text-input>
                    <!-- //. form-input: city -->
                </div>
                <div class="col-12">
                    <!-- form-input: state -->
                    <app-text-input
                        [formControl]="f['stateOrRegion']"
                        [label]="'State / Region'"
                        testAutomationSelectorName="company-modal_input_state"
                    ></app-text-input>
                    <!-- //. form-input: state -->
                </div>
                <div class="col-12">
                    <!-- form-input: country -->
                    <app-select-input
                        [formControl]="f['country']"
                        [list]="countries"
                        [label]="'Country'"
                        testAutomationSelectorName="company-modal_select_country"
                    ></app-select-input>
                    <!-- //. form-input: country -->
                </div>
                <div class="col-12" *ngIf="parentDisabled">
                    <!-- form-input: parent -->
                    <app-select-input
                        [formControl]="f['parent']"
                        [list]="companies$ | async"
                        [label]="'Parent'"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="company-modal_select_parent"
                    ></app-select-input>
                    <!-- //. form-input: parent -->
                </div>
                <div class="col-12 mt-3" *ngIf="tenantId">
                    <!-- form-input: supplier -->
                    <app-toggle-input
                        [label]="'Supplier'"
                        [formControl]="f['isSupplier']"
                        testAutomationSelectorName="company-modal_check_is_supplier"
                    ></app-toggle-input>
                    <!-- //. form-input: supplier -->
                </div>
                <div class="col-12 mt-3" *ngIf="tenantId">
                    <!-- form-input: manufacturer -->
                    <app-toggle-input
                        [label]="'Manufacturer'"
                        [formControl]="f['isManufacturer']"
                        testAutomationSelectorName="company-modal_check_is_supplier"
                    ></app-toggle-input>
                    <!-- //. form-input: manufacturer -->
                </div>
                <div class="col-12" *ngIf="!tenantId">
                    <label class="fw-medium text-gray-700 fs-14 mb-0">Company Logo</label>
                    <input class="form-control" type="file" (change)="onFileSelected($event)" accept="image/*" #file />
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>
<div class="modal-footer">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
