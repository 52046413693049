// core
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// env
import { environment } from './environments/environment';

/**
 * It returns the base URL of the API
 * @returns The base url for the api.
 */
export function getBaseUrl() {
    return `${environment.apiBaseUrl}/${environment.apiPrefix}`;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
