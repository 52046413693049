// schemas
import { ICreateEquipmentTypeDTO } from '@data/schema';

export class GetEquipmentTypes {
    static readonly type = '[EQUIPMENT-TYPE] GET EQUIPMENT-TYPES';
}

export class GetEquipmentType {
    static readonly type = '[EQUIPMENT-TYPE] GET EQUIPMENT-TYPE';
    constructor(public id: string) {}
}

export class CreateEquipmentType {
    static readonly type = '[EQUIPMENT-TYPE] CREATE EQUIPMENT-TYPE';
    constructor(public payload: ICreateEquipmentTypeDTO) {}
}

export class UpdateEquipmentType {
    static readonly type = '[EQUIPMENT-TYPE] UPDATE EQUIPMENT-TYPE';
    constructor(public id: string, public payload: ICreateEquipmentTypeDTO) {}
}

export class DeleteEquipmentType {
    static readonly type = '[EQUIPMENT-TYPE] DELETE EQUIPMENT-TYPE';
    constructor(public id: string) {}
}
