// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';



@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(private apiService: ApiService) {}

    create(entity: string, id: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.apiService
            .upload<{ data: any }>(`/uploads/googlecloud/${entity}/${id}`, formData)
            .pipe(map((res) => res.data));
    }

    profileUpdate(entity: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.apiService
            .upload<{ data: any }>(`/uploads/userImage/googlecloud/${entity}`, formData)
            .pipe(map((res) => res.data));
    }

    companyLogoUpdate(companyId: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.apiService
            .upload<{ data: any }>(`/uploads/tenantLogo/googlecloud/${companyId}`, formData)
            .pipe(map((res) => res.data));
    }

    getById(id: string): Observable<any> {
        return this.apiService.get<{ data: any }>(`/files/entity/${id}`).pipe(map((res) => res.data));
    }

    getProfileImage(): Observable<any> {
        return this.apiService.get<{ data: any }>(`/files/profile/image`).pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: any }>(`/files/${id}`);
    }

    getDownloadUrl(id: string) {
        return this.apiService.get<{ data: any }>(`/files/${id}/download`).pipe(map((res) => res.data));
    }

    // getZippedFile(id: string){
    //    return this.apiService.getBlob(`/files/${id}/zippedByEntity`);
    // }

    getSelectedZippedFile(ids: string[]) {
        //    return this.apiService.getBlob(`/files/zipSelected`,  ids);
        return this.apiService.getBlob(`/files/zipSelected`, { ids });
    }
}