// core
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';

// schemas
import { IButtonCustomClass, IDataTableInfo, IUpload } from '@data/schema';

// states
import { DeleteUpload } from '@data/state';

// service
import { SweetAlertService } from '@app/service';
import { FileService } from '@data/service';

// components
import { FileUploadModalComponent } from '@shared/component/upload/file-upload-modal/file-upload-modal.component';

// enums
import { UserRole } from '@data/enum';

@Component({
    selector: 'app-upload-list',
    templateUrl: './upload-list.component.html',
    styleUrls: ['./upload-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadListComponent implements OnInit {
    @Input() uploads: IUpload & { isChecked: boolean }[];
    @Input() isProcessing = false;
    @Input() entity;
    @Input() id;
    @Input() userRole: UserRole;

    bsModalRef: BsModalRef;
    onDestroy$: Subject<void> = new Subject();
    isAllFilesSelected: boolean = false;
    isChecked = [];
    selectedFiles = [];

    userRoleType = UserRole;

    buttonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm  w-100',
        spinner: 'spinner-border spinner-border-sm',
    };

    downloadUrl: string;
    url: string;

    constructor(
        private modalService: BsModalService,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private fileService: FileService,
    ) {}

    ngOnInit(): void {}

    // Commented out for future possible implementation

    // async downloadAll() {
    // let checkedFiles = this.uploads.filter((file) => {
    //     return file.isChecked === true;
    // })

    // console.log(checkedFiles);
    // await firstValueFrom(this.fileService.getZippedFile(this.id))
    //     .then((res) => {
    //         const url = URL.createObjectURL(res);
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = 'files.zip';

    //         link.click();
    //         URL.revokeObjectURL(url); // cleanup the URL object
    //     })
    //     .catch((err) => {

    //         console.log(err);
    //     });
    // }

    async downloadSelected() {
        const ids = this.selectedFiles.filter(Boolean).map((item) => item._id); // Filter Boolean to removed undefined values if any
        await firstValueFrom(this.fileService.getSelectedZippedFile(ids))
            .then((res) => {
                const url = URL.createObjectURL(res);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'files.zip';

                link.click();
                URL.revokeObjectURL(url); // cleanup the URL object
            })
            .catch((err) => {
                console.log(err);
            });
    }

    download(id: string) {
        firstValueFrom(this.fileService.getDownloadUrl(id))
            .then((res) => {
                this.downloadUrl = res.url;
                window.open(this.downloadUrl, '_blank').focus();
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    upload() {
        const initialState = { entityType: this.entity, id: this.id };
        this.bsModalRef = this.modalService.show(FileUploadModalComponent, {
            initialState,
            class: 'center-modal-wrapper',
            backdrop: 'static',
        });
    }

    async delete(id: string, fileName: string) {
        const isConfirmed = await this.sweetAlertService.delete(
            'Delete Record',
            `Do you want to delete the file ( ${fileName} )?`,
        );
        if (!isConfirmed) {
            return;
        }
        this.store
            .dispatch(new DeleteUpload(id))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.isProcessing = false;
                    this.sweetAlertService.success('Successfully delete the record');
                },
                error: () => {
                    this.isProcessing = false;
                },
            });
    }

    onChange(isAll: boolean, $event: boolean, upload?: any, index?: any) {
        if (isAll) {
            if ($event) {
                this.isAllFilesSelected = true;
                this.uploads.forEach((file) => {
                    this.selectedFiles.push(file);
                    this.isChecked.push(true);
                });
            } else {
                this.selectedFiles = [];
                this.isAllFilesSelected = false;
                this.isChecked = [];
            }
        }
        if ($event) {
            this.selectedFiles.push(upload);
            this.isChecked[index] = true;
        } else {
            this.selectedFiles = this.selectedFiles.filter((obj) => obj._id !== upload._id);
            this.isChecked[index] = false;
        }
        this.isAllFilesSelected = this.selectedFiles.length === this.uploads.length;
    }
}
