// schemas
import {
    ICreateHistoryDTO,
    ICreateItemDTO,
    ICreateTableLayoutDTO,
    ILinkIssuesToItem,
    ILinkServicesToItem,
    ILinkContractsToItem,
    ILinkContractsToService,
} from '@data/schema';

// enums
import { ServiceType, LinkedEntityType, LinkedCountType } from '@data/enum';

export class GetItems {
    static readonly type = '[ITEM] GET ITEMS';
}

export class GetItem {
    static readonly type = '[ITEM] GET ITEM';
    constructor(public id: string) {}
}

export class GetLinkCountsForItem {
    static readonly type = '[ITEM] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetLinkedDataForItem {
    static readonly type = '[ITEM] GET LINKED DATA';
    constructor(
        public id: string,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public itemType?: string,
    ) {}
}

export class CreateLinkedItemForItem {
    static readonly type = '[ITEM] CREATE LINKED ITEM';
    constructor(
        public id: string,
        public payload: any,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public countType: LinkedCountType,
    ) {}
}

export class LinkNUnlinkIssuesInItem {
    static readonly type = '[ITEM] LINK/UNLINK ISSUES';
    constructor(public payload: ILinkIssuesToItem, public isLink: boolean) {}
}

export class LinkNUnlinkServicesInItem {
    static readonly type = '[ITEM] LINK/UNLINK SERVICES';
    constructor(public payload: ILinkServicesToItem, public isLink: boolean) {}
}

export class GetTableLayoutForItems {
    static readonly type = '[ITEM] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class GetNotesForItem {
    static readonly type = '[ITEM] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class CreateNoteForItem {
    static readonly type = '[ITEM] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class CreateItem {
    static readonly type = '[ITEM] CREATE ITEM';
    constructor(public payload: ICreateItemDTO) {}
}

export class UpdateItem {
    static readonly type = '[ITEM] UPDATE ITEM';
    constructor(public id: string, public payload: ICreateItemDTO) {}
}

export class UpdateTableLayoutForItems {
    static readonly type = '[ITEM] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class DeleteItem {
    static readonly type = '[ITEM] DELETE ITEM';
    constructor(public id: string) {}
}

export class DeleteItems {
    static readonly type = '[ITEM] DELETE ITEMS';
    constructor(public ids: string[]) {}
}

export class LinkNUnlinkContractsInItem {
    static readonly type = '[ITEM] LINK/UNLINK  CONTRACTS';
    constructor(public payload: ILinkContractsToItem, public isLink: boolean) {}
}

export class ResetItem {
    static readonly type = '[ITEM] RESET ITEM';
    constructor() {}
}

export class ResetNotesItem {
    static readonly type = '[ITEM] RESET NOTES';
    constructor() {}
}
