// core
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';

// actions
import { GetTenant, Login, Logout, TenantChange, TenantReset, UpdateUserProfile } from './auth.actions';

// services
import { AuthService } from '@app/service';

// schemas
import { AuthStateModel } from './auth-state.model';
import { ICompany, IUser } from '@data/schema';
import { CompanyService } from '@data/service';
import { ITenant } from '@data/schema/type/user';
import { of } from 'rxjs';

// enums

@State<AuthStateModel>({
    name: 'auth',
    defaults: null,
})
@Injectable()
export class AuthState {
    constructor(private authService: AuthService, private companyService: CompanyService) {}

    @Selector()
    static token(state: AuthStateModel): string | null {
        return state.accessToken;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.accessToken;
    }

    @Selector()
    static currentUser(state: AuthStateModel): IUser {
        return state;
    }

    @Selector()
    static userRole(state: AuthStateModel): string {
        return state.user.role;
    }

    @Selector()
    static tenantId(state: AuthStateModel): string {
        return state.tenantId;
    }

    @Selector()
    static tenant(state: AuthStateModel): ICompany | ITenant {
        return state.tenant;
    }

    @Action(Login)
    login({ patchState }: StateContext<AuthStateModel>, action: Login) {
        return this.authService.login(action.payload).pipe(
            tap((response) => {
                patchState({
                    ...response,
                });
            }),
        );
    }

    @Action(TenantChange)
    tenantChange({ getState, patchState }: StateContext<AuthStateModel>, { tenantId }: TenantChange) {
        const state = getState();
        patchState({
            ...state,
            tenantId: tenantId,
        });
    }

    @Action(GetTenant)
    getTenant({ getState, patchState }: StateContext<AuthStateModel>, { tenantId }: GetTenant) {
        return this.companyService.getCompanyLogoById(tenantId).pipe(
            switchMap((response) => {
                if (!response) {
                    return this.companyService.getById(tenantId);
                } else {
                    return of(response);
                }
            }),
            tap((updatedResponse) => {
                patchState({
                    tenant: updatedResponse,
                });
            }),
        );
    }

    @Action(TenantReset)
    tenantReset({ getState, patchState }: StateContext<AuthStateModel>) {
        const state = getState();
        patchState({
            ...state,
            tenantId: null,
            tenant: null,
        });
    }

    @Action(Logout)
    logOut({ setState }: StateContext<AuthStateModel>, userId: string) {
        this.authService.logout(userId);
        setState(null);
        localStorage.clear();
    }

    @Action(UpdateUserProfile)
    updateUserProfile({ patchState }: StateContext<AuthStateModel>, { payload }: UpdateUserProfile) {
        patchState({
            user: payload,
        });
    }
}
