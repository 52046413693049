// core
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, tap } from 'rxjs';

// services
import { DataTableService } from '@app/service';

// enums
import {
    CheckListDataTableCellType,
    CompanyDataTableCellType,
    ContactDataTableCellType,
    ContractDataTableCellType,
    DataTableCellType,
    IssueDataTableCellType,
    ItemDataTableCellType,
    ServiceDataTableCellType,
    ServiceType,
    UserRole,
} from '@data/enum';

// schemas
import {
    ICheckListSummary,
    IDataTableInfo,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
    IFormCustomClass,
} from '@data/schema';

@Component({
    selector: 'app-link-table',
    templateUrl: './link-table.component.html',
    styleUrls: ['./link-table.component.scss'],
})
export class LinkTableComponent implements OnInit {
    @Input() borderClass: string;
    @Input() title: string;
    @Input() itemCount: number;
    @Input() isOpen: boolean = false;
    @Input() isTab: boolean = false;
    @Input() dataTableCols: DataTables.ColumnSettings[][];
    @Input() dataTableEnableServerSide: boolean[] = [];
    @Input() data$: Observable<any[]>[];
    @Input() dataLoading$: Observable<boolean[]>[];
    @Input() tabData: [];
    @Input() buttonTitle: string = '';
    @Input() linkedDataTableActionButtons = [];
    @Input() unlinkEntity: string;
    @Input() serviceType: ServiceType;
    @Input() idType;
    @Input() tabType: string[] = [];
    @Input() parentDocumentId: string;
    @Input() completeSummary?: ICheckListSummary;
    @Input() dataTableEnableSearch = true;
    @Input() serviceId: string;
    @Input() userRole: UserRole;
    @Input() linkedDataTableActionButtonEnabled: boolean = true;

    @Output() onIsOpenChange = new EventEmitter();
    @Output() onIsOpenChangeServerSide = new EventEmitter();
    @Output() actionButtonEvent = new EventEmitter();

    dataTableSource$: Observable<any[]>[] = [];

    dataTableInfo: IDataTableInfo = {
        buttonTitle: 'Create New',
        infoLabel: 'items',
    };

    checkboxCustomClasses: IFormCustomClass = {
        formGroup: 'form-group m-0',
        label: 'font-weight-bold text-muted small mb-0',
        input: '',
    };

    dataTableForm: FormGroup;

    selectedTab: number = 0;

    dataTableCellType = DataTableCellType;
    companyDataTableCellType = CompanyDataTableCellType;
    contactDataTableCellType = ContactDataTableCellType;
    contractDataTableCellType = ContractDataTableCellType;
    itemDataTableCellType = ItemDataTableCellType;
    issueDataTableCellType = IssueDataTableCellType;
    serviceDataTableCellType = ServiceDataTableCellType;
    checkListDataTableCellType = CheckListDataTableCellType;

    constructor(private formBuilder: FormBuilder, private dataTableService: DataTableService) {}

    ngOnInit(): void {
        this.initializeForm();
        this.dataTableInfo.buttonTitle = this.buttonTitle;
    }

    onChange($event) {
        this.onIsOpenChange.emit($event);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.['buttonTitle']) {
            this.dataTableInfo.buttonTitle = changes?.['buttonTitle'].currentValue;
        }
    }

    onTabSelected(index) {
        this.selectedTab = index;
        this.dataTableService.dataTableRerenderer.next(true);
    }

    outputActionButtonEvent($event, index: number) {
        const { items } = this.dataTableForm.getRawValue()?.table[index];
        const selectedItems = items.filter((item) => item.isSelected).map((item) => item._id);

        this.actionButtonEvent.emit({
            type:
                this.buttonTitle === 'Remove Checklist Template' || this.buttonTitle === 'Add Checklist Template'
                    ? this.buttonTitle.split(' Checklist Template')[0]
                    : this.buttonTitle.split('Create new ')[1],
            selectedIds: selectedItems,
            index,
            ...$event,
        });
    }

    private initializeForm(): void {
        this.dataTableForm = this.formBuilder.group({ table: this.formBuilder.array([]) });

        if (!this.isTab) {
            this.table.push(
                this.formBuilder.group({
                    items: this.formBuilder.array([]),
                }),
            );
        } else {
            this.tabData.forEach(() => {
                this.table.push(
                    this.formBuilder.group({
                        items: this.formBuilder.array([]),
                    }),
                );
            });
        }
    }

    private addFormControls(items: any[], index: number): void {
        this.p(index).clear();
        items.forEach((item) => {
            this.p(index).push(this.createItemField(item));
        });
    }

    private createItemField(item: any): FormGroup {
        const itemField = this.formBuilder.group({
            _id: item._id,
            isSelected: false,
        });

        return itemField;
    }

    get mainForm() {
        return this.dataTableForm.controls;
    }

    get table() {
        return this.mainForm['table'] as FormArray;
    }

    f(index: number) {
        return (this.table.controls[index] as FormGroup).controls;
    }

    p(index: number) {
        return this.f(index)['items'] as FormArray;
    }

    getFormGroup(index) {
        return this.table.controls[index];
    }

    onSelectAllCheckBoxValueChanges(flag: boolean, index: number) {
        const { items } = this.dataTableForm.getRawValue()?.table[index];

        items.forEach((formField, i) => {
            if (flag && !this.p(index).controls[i].get('isSelected').disabled) {
                this.p(index).controls[i].patchValue({ isSelected: true });
            } else {
                this.p(index).controls[i].patchValue({ isSelected: false });
            }
        });
    }

    dataTableServerSideCallbackFn = (pagination: IDataTablePaginationDTO) => {
        if (!this.isTab) {
            return this.dataTableService
                .getLinkTableData(this.serviceType, this.idType, this.parentDocumentId, pagination)
                .pipe(tap((response: IDataTablePaginationResponse) => this.addFormControls(response.data, 0)));
        } else {
            return this.dataTableService
                .getLinkTableData(
                    this.serviceType,
                    this.idType,
                    this.parentDocumentId,
                    pagination,
                    this.tabType[this.selectedTab],
                )
                .pipe(
                    tap((response: IDataTablePaginationResponse) => {
                        this.addFormControls(response.data, this.selectedTab);
                    }),
                );
        }
    };
}
