import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '@app/service';
import { map, Observable } from 'rxjs';
import { IDataTablePaginationDTO, IDataTablePaginationResponse, IServiceDashboard } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private apiService: ApiService) {}

    get(
        startDate: string,
        endDate: string,
        serviceStatus: string[],
        provider: string[],
        recipient: string[],
        contractStatus: string[],
    ): Observable<IServiceDashboard[]> {
        let params = new HttpParams();
        params = params.append('limit', 10);
        params = params.append('skip', 1);
        params = params.append('sort', `${-'_id'}`);
        if (serviceStatus) {
            serviceStatus.forEach((serviceStatus: string) => {
                params = params.append(`serviceStatus`, serviceStatus);
            });
        }
        if (recipient) {
            recipient.forEach((recipientName: string) => {
                params = params.append(`recipient`, recipientName);
            });
        }
        if (provider) {
            provider.forEach((providerName: string) => {
                params = params.append(`provider`, providerName);
            });
        }
        if (contractStatus) {
            contractStatus.forEach((contractStatus: string) => {
                params = params.append(`contractStatus`, contractStatus);
            });
        }

        return this.apiService
            .get<{ data: IServiceDashboard[] }>(
                `/linkContractServices/upcomingServices/${startDate}/${endDate}`,
                params,
            )
            .pipe(map((res) => res.data));
    }

    getSSR(
        context: IDataTablePaginationDTO,
        startDate: string,
        endDate: string,
        serviceStatus: string[],
        provider: string[],
        recipient: string[],
        contractStatus: string[],
    ): Observable<IDataTablePaginationResponse<IServiceDashboard>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        params = params.append('order', `${context.sort}`);
        if (serviceStatus) {
            serviceStatus.forEach((serviceStatus: string) => {
                params = params.append(`serviceStatus`, serviceStatus);
            });
        }
        if (recipient) {
            recipient.forEach((recipientName: string) => {
                params = params.append(`recipient`, recipientName);
            });
        }
        if (provider) {
            provider.forEach((providerName: string) => {
                params = params.append(`provider`, providerName);
            });
        }
        if (contractStatus) {
            contractStatus.forEach((contractStatus: string) => {
                params = params.append(`contractStatus`, contractStatus);
            });
        }
        return this.apiService
            .get<IDataTablePaginationResponse>(`/linkContractServices/upcomingServices/${startDate}/${endDate}`, params)
            .pipe(
                map((res) => ({
                    data: !res.data[0].data.length ? [] : res.data[0].data,
                    totalItems: res.data[0].data.length,
                    totalFilteredItems: !res.data[0].data.length ? 0 : res.data[0].total,
                })),
            );
    }
}
