// schemas
import { IContact, ILoginDTO } from '@data/schema';

export class Login {
    static readonly type = '[AUTH] LOGIN';
    constructor(public payload: ILoginDTO) {}
}

export class TenantChange {
    static readonly type = '[AUTH] TENANT CHANGE';
    constructor(public tenantId: string) {}
}

export class GetTenant {
    static readonly type = '[AUTH] GET TENANT';
    constructor(public tenantId: string) {}
}

export class TenantReset {
    static readonly type = '[AUTH] TENANT RESET';
    constructor() {}
}

export class Logout {
    static readonly type = '[AUTH] LOGOUT';
    constructor(public userId?: string) {}
}

export class UpdateUserProfile {
    static readonly type = '[AUTH] UPDATE USER PROFILE';
    constructor(public payload: IContact) {}
}
