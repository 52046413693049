// core
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

// services
import { ApiService } from './api.service';

// schemas
import { IForgotPasswordDTO, ILoginDTO, IResetPasswordDTO, IUser } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private apiService: ApiService) {}

    login(context: ILoginDTO) {
        return this.apiService.post<{ data: IUser }, ILoginDTO>('/auth/login', context).pipe(map((res) => res.data));
    }

    forgotPassword(context: IForgotPasswordDTO) {
        return this.apiService
            .post<{ data: IUser }, IForgotPasswordDTO>('/auth/forgot-password', context)
            .pipe(map((res) => res.data));
    }

    resetPassword(context: IResetPasswordDTO) {
        return this.apiService
            .post<{ data: IUser }, IResetPasswordDTO>('/auth/reset-password', context)
            .pipe(map((res) => res.data));
    }

    logout(id : string) {
        return this.apiService
        .get<{ data: any }>(`/auth/logout/${id}`)
        .pipe(map((res) => res.data));
    }
    
}
