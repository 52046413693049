// core
import { Component, Input, Self, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

// directives
import {
    NgSelectInputOptionTemplateDirective,
    NgSelectInputLabelTemplateDirective,
    NgTypeheadInputTagTemplateDirective,
} from '@shared/directive/form-template.directive';

// schemas
import { IFormCustomClass } from '@data/schema/generic/form';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() list: any[];
    @Input() labelField: string;
    @Input() bindField: string;
    @Input() searchable = true;
    @Input() clearable = true;
    @Input() closeOnSelect = true;
    @Input() multiple = false;
    @Input() appendTo: string;
    @Input() inputFloatingLabelEnabled = false;
    @Input() showRequiredIndicator = false;
    @Input() customClasses: IFormCustomClass = {
        formGroup: 'form-group  mb-3',
        label: 'fw-medium text-gray-700 fs-14 mb-0',
        input: '',
    };
    @Input() addTagCallback: Function;
    @Input() addTag = false;
    @Output() onOptionSelect = new EventEmitter();
    @Output() onOptionClear = new EventEmitter();
    @Output() onAddTagClick = new EventEmitter();

    // custom templates
    @ContentChild(NgTypeheadInputTagTemplateDirective, { read: TemplateRef }) tagTemplate: TemplateRef<any>;
    @ContentChild(NgSelectInputOptionTemplateDirective, { read: TemplateRef }) optionTemplate: TemplateRef<any>;
    @ContentChild(NgSelectInputLabelTemplateDirective, { read: TemplateRef }) labelTemplate: TemplateRef<any>;

    loading = false;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {}
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}

    onChange($event: any) {
        this.onOptionSelect.emit($event);
    }

    onClear() {
        this.onOptionClear.emit();
    }

    addTagPromise = (name) => {
        return new Promise((resolve) => {
            this.loading = true;

            this.addTagCallback(name).subscribe(
                (res) => {
                    resolve(res);
                    this.loading = false;
                },
                (error) => {
                    console.log(error);
                    this.loading = false;
                },
            );
        });
    };
}
