// core
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateContractType,
    DeleteContractType,
    GetContractType,
    GetContractTypes,
    UpdateContractType,
} from './contract-type.actions';

// services
import { ContractTypeService } from '@data/service';

// schemas
import { ContractTypeStateModel } from './contract-type-state.model';

// states
import { ResetContract } from '@data/state';

@State<ContractTypeStateModel>({
    name: 'contractTypeTypes',
    defaults: {
        items: {
            data: [],
            loading: true,
        },
    },
})
@Injectable()
export class ContractTypeState {
    constructor(private contractTypeService: ContractTypeService, private store: Store) {}

    @Selector()
    static getContractTypeList(state: ContractTypeStateModel) {
        return state.items.data;
    }

    @Selector()
    static getContractTypeListLoading(state: ContractTypeStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentContractType(state: ContractTypeStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getNewContractType(state: ContractTypeStateModel) {
        return state.newItem?.data;
    }

    @Action(GetContractTypes)
    getContractTypes({ getState, setState }: StateContext<ContractTypeStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.contractTypeService.get().pipe(
            tap((response) => {
                setState({
                    ...state,
                    items: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetContractType)
    getContractType({ getState, setState }: StateContext<ContractTypeStateModel>, { id }: GetContractType) {
        const state = getState();
        const currentItem = state.items.data.find((item) => item._id === id);

        if (currentItem) {
            return setState({
                ...state,
                currentItem: {
                    data: currentItem,
                    loading: false,
                    retrievedAt: Date.now(),
                },
            });
        }

        return this.contractTypeService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(CreateContractType)
    createContractType(
        { getState, patchState }: StateContext<ContractTypeStateModel>,
        { payload }: CreateContractType,
    ) {
        return this.contractTypeService.create(payload).pipe(
            tap((response) => {
                const state = getState();

                patchState({
                    items: {
                        ...state.items,
                        data: [...state.items.data, response],
                    },
                    newItem: {
                        data: response,
                    },
                });
            }),
        );
    }

    @Action(UpdateContractType)
    updateContractType(
        { getState, setState }: StateContext<ContractTypeStateModel>,
        { id, payload }: UpdateContractType,
    ) {
        return this.contractTypeService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });
                this.store.dispatch(new ResetContract());
            }),
        );
    }

    @Action(DeleteContractType)
    deleteContractType({ getState, setState }: StateContext<ContractTypeStateModel>, { id }: DeleteContractType) {
        return this.contractTypeService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }
}
