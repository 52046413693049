// core
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    baseUrl: string;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.baseUrl = `${baseUrl}`;
    }

    /**
     * It returns an observable of type T, which is a generic type
     * @param {string} url - The url to make the request to.
     * @param {HttpParams} params - HttpParams = new HttpParams()
     * @param {HttpHeaders} [headers] - This is the header that you want to send with the request.
     * @returns An observable of type T
     */
    get<T>(url: string, params: HttpParams = new HttpParams(), headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(`${this.baseUrl}${url}`, {
            headers: headers ? headers : this.headers,
            params,
        });
    }

    /**
     * It takes a URL and a data object, and returns an Observable of type T
     * @param {string} url - The url to make the request to.
     * @param {D} data - The data to be sent to the server.
     * @returns Observable<T>
     */
    post<T, D>(url: string, data: D): Observable<T> {
        return this.http.post<T>(`${this.baseUrl}${url}`, JSON.stringify(data), { headers: this.headers });
    }

    /**
     * It takes a URL and a data object, and returns an Observable of type T
     * @param {string} url - The URL to make the request to.
     * @param {D} data - The data to be sent to the server.
     * @returns Observable<T>
     */
    put<T, D>(url: string, data: D): Observable<T> {
        return this.http.put<T>(`${this.baseUrl}${url}`, JSON.stringify(data), {
            headers: this.headers,
        });
    }

    /**
     * It takes a URL and a data object, and returns an Observable of type T
     * @param {string} url - The URL to make the request to.
     * @param {D} data - The data to be sent to the server.
     * @returns Observable<T>
     */
    patch<T, D>(url: string, data: D): Observable<T> {
        return this.http.patch<T>(`${this.baseUrl}${url}`, JSON.stringify(data), {
            headers: this.headers,
        });
    }

    /**
     * It returns an observable of type T, which is a generic type
     * @param {string} url - The URL to make the request to.
     * @returns Observable<T>
     */
    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(`${this.baseUrl}${url}`, {
            headers: this.headers,
        });
    }

    /**
     * It takes a URL and a data object, and returns an observable of type T
     * @param {string} url - The url to make the request to.
     * @param {D} data - The data to be sent to the server.
     * @returns Observable<T>
     */
    deleteBatch<T, D>(url: string, data: D): Observable<T> {
        return this.http.delete<T>(`${this.baseUrl}${url}`, {
            headers: this.headers,
            body: data,
        });
    }

    /**
     * It takes a url and a FormData object as parameters, and returns an Observable of type T
     * @param {string} url - The url of the API endpoint.
     * @param {FormData} data - FormData
     * @returns Observable<T>
     */
    upload<T>(url: string, data: FormData): Observable<T> {
        return this.http.post<T>(`${this.baseUrl}${url}`, data);
    }

    /**
     * It takes a url and a FormData object as parameters, and returns an Observable of type T
     * @param {string} url - The url of the API endpoint.
     * @param {FormData} data - FormData
     * @returns Observable<T>
     */
    update<T>(url: string, data: FormData): Observable<T> {
        return this.http.put<T>(`${this.baseUrl}${url}`, data);
    }

    /**
     * It returns an observable of type T, which is a generic type
     * @param {string} url - The url to make the request to.
     * @param {HttpParams} params - HttpParams = new HttpParams()
     * @param {HttpHeaders} [headers] - This is the header that you want to send with the request.
     * @returns An observable of type T
     */
        getBlob(url: string, data: any , params: HttpParams = new HttpParams(), headers?: HttpHeaders) {
            return this.http.post(`${this.baseUrl}${url}`, data , {
                headers: headers ? headers : this.headers,
                params, 
                responseType:'blob',
            });
        }

    /**
     * It returns a new instance of the HttpHeaders class, which is a class that comes with Angular's
     * HttpClient module
     * @returns The headers object is being returned.
     */
    get headers(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        return new HttpHeaders(headersConfig);
    }
}
