// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IContract,
    IIssue,
    ILinkContractsToIssue,
    ILinkContractsToService,
    ILinkIssuesToContract,
    ILinkServicesToContract,
    IService,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LinkContractIssuesService {
    constructor(private apiService: ApiService) {}

    linkIssuesToContract(context: ILinkIssuesToContract): Observable<IIssue[]> {
        return this.apiService
            .post<{ data: IIssue[] }, ILinkIssuesToContract>('/linkContractIssues/link/issues', context)
            .pipe(map((res) => res.data));
    }

    linkContractsToIssue(context: ILinkContractsToIssue): Observable<IContract[]> {
        return this.apiService
            .post<{ data: IContract[] }, ILinkContractsToIssue>('/linkContractIssues/link/contracts', context)
            .pipe(map((res) => res.data));
    }

    unlinkIssuesFromContract(context: ILinkIssuesToContract): Observable<IIssue[]> {
        return this.apiService
            .deleteBatch<{ data: IIssue[] }, ILinkIssuesToContract>('/linkContractIssues/unlink/issues', context)
            .pipe(map((res) => res.data));
    }

    unlinkContractsFromIssue(context: ILinkContractsToIssue): Observable<IContract[]> {
        return this.apiService
            .deleteBatch<{ data: IContract[] }, ILinkContractsToIssue>(
                '/linkContractIssues/unlink/contracts',
                context,
            )
            .pipe(map((res) => res.data));
    }
}
