export enum ServiceFrequency {
    ANNUALLY = 'annually',
    SEMI_ANNUALLY = 'semiAnnually',
    QUARTERLY = 'quarterly',
    MONTHLY = 'monthly',
    HALF_MONTHLY = 'halfMonthly',
    WEEKLY = 'weekly',
}

export enum ServiceFrequencyType {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
}
