export enum UserRole {
    ADMIN = 'admin',
    APPLICATION_ADMIN = 'applicationAdmin',
    APPLICATION_VIEWER = 'applicationViewer',
    TENANT_ADMIN = 'tenantAdmin',
    TENANT_VIEWER = 'tenantViewer',
    COMPANY_APPLICATION_ADMIN = 'companyapplicationAdmin',
    COMPANY_APPLICATION_VIEWER = 'companyapplicationViewer',
    COMPANY_TENANT_VIEWER = 'companytenantViewer',
}
