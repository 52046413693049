// core
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor() {}

    /**
     * It replaces all non-word characters with a space, splits the string into an array of words, maps
     * each word to lowercase, and joins the array with an underscore
     * @param {string} string - The string to convert to snake case.
     * @returns a string that has been converted to snake case.
     */
    snakeCase(string: string) {
        return string
            .replace(/\W+/g, ' ')
            .split(/ |\B(?=[A-Z])/)
            .map((word) => word.toLowerCase())
            .join('_');
    }

    /**
     * Helper to produce an array of enum descriptors.
     * @param enumeration Enumeration object.
     * @param separatorRegex Regex that would catch the separator in your enum key.
     * @param keyName String that will used in the key place.
     * @param valueName String that will used in the value place.
     * @param titleCase Boolean that decides to use the title case or not.
     */
    enumToDescriptiveArray<T>({
        enumeration,
        separatorRegex = /_/g,
        keyName = 'id',
        valueName = 'description',
        titleCase = true,
    }: {
        enumeration: T;
        separatorRegex?: RegExp;
        keyName?: string;
        valueName?: string;
        titleCase?: boolean;
    }) {
        return (Object.keys(enumeration) as Array<keyof T>)
            .filter((key) => isNaN(Number(key)))
            .filter((key) => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
            .map((key) => ({
                [keyName]: enumeration[key],
                [valueName]: titleCase
                    ? String(key)
                          .replace(separatorRegex, ' ')
                          .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
                    : String(key).replace(separatorRegex, ' '),
            }));
    }
}
