<!-- input: base -->
<div [class]="customClasses.formGroup" *ngIf="!inputFloatingLabelEnabled">
    <label [class]="customClasses.label">
        {{ label }} <span *ngIf="showRequiredIndicator" class="c-asterisk"> * </span>
    </label>
    <ng-template [ngTemplateOutlet]="selectInput"></ng-template>
    <ng-template [ngTemplateOutlet]="selectInputErrors"></ng-template>
</div>
<!-- //. input: base -->

<!-- input: floating-label -->
<div *ngIf="inputFloatingLabelEnabled" class="row">
    <div class="col my-auto">
        <div [class]="customClasses.formGroup" class="has-floating-label-dropdown">
            <label [class]="customClasses.label" class="form-control-feedback">
                {{ label }} <span *ngIf="showRequiredIndicator" class="c-asterisk"> * </span>
            </label>
            <ng-template [ngTemplateOutlet]="selectInput"></ng-template>
        </div>
        <ng-template [ngTemplateOutlet]="selectInputErrors"></ng-template>
    </div>
</div>
<!-- //. input: floating-label -->

<!-- templates -->
<!-- tmpl: select-input -->
<ng-template #selectInput>
    <ng-select
        #select
        [items]="list"
        [searchable]="false"
        [dropdownPosition]="'auto'"
        [bindValue]="bindField"
        [bindLabel]="labelField"
        [placeholder]="placeholder"
        [formControl]="ngControl.control"
        [clearable]="clearable"
        [closeOnSelect]="closeOnSelect"
        [appendTo]="appendTo"
        [addTag]="addTag && addTagPromise"
        [loading]="loading"
        [searchable]="searchable"
        (change)="onChange($event)"
        (clear)="onClear()"
        [class]="customClasses.input"
        class="select-input-custom"
        [multiple]="multiple"
        [class.is-invalid]="ngControl.touched && ngControl.invalid"
    >
        <ng-template ng-tag-tmp>
            <div class="text-primary cursor-pointer">
                <span class="bi bi-plus-lg"></span> &nbsp;Create new from "{{ select.searchTerm }}"
            </div>
        </ng-template>

        <!-- add tag template -->
        <ng-template *ngIf="tagTemplate" ng-tag-tmp>
            <ng-container
                [ngTemplateOutlet]="tagTemplate"
                [ngTemplateOutletContext]="{ selectValue: select.searchTerm }"
            ></ng-container>
        </ng-template>

        <!-- option list template -->
        <ng-template *ngIf="optionTemplate" ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <ng-container
                [ngTemplateOutlet]="optionTemplate"
                [ngTemplateOutletContext]="{
                    item: item,
                    index: index,
                    search: search
                }"
            ></ng-container>
        </ng-template>
        <!-- //. option list template -->

        <!-- label template -->
        <ng-template *ngIf="labelTemplate" ng-label-tmp let-item="item">
            <ng-container [ngTemplateOutlet]="labelTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </ng-template>
        <!-- //. label template -->
    </ng-select>
</ng-template>
<!-- //. tmpl: select-input -->

<!-- tmpl: select-input-errors -->
<ng-template #selectInputErrors>
    <div *ngIf="ngControl.control.errors?.required && ngControl.control.touched" class="text-danger small">
        Please select a {{ label | lowercase }}
    </div>
    <div *ngIf="ngControl.control.errors?.invalid && ngControl.control.touched" class="text-danger small">
        Please select valid option
    </div>
</ng-template>
<!-- //. tmpl: select-input-errors -->
<!-- //. templates -->
