import { ITableLayout } from '@data/schema';

export interface GenericStateModel<T> {
    items: {
        data: T[];
        loading: boolean;
        retrievedAt?: number;
        tableLayout?: {
            data: ITableLayout;
            isDefault: boolean;
        };
    };
    currentItem?: {
        data: T;
        loading: boolean;
        retrievedAt?: number;
    };
    newItem?: {
        data: T;
        loading?: boolean;
        retrievedAt?: number;
    };
}
