export function currentWeek() {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - dayOfWeek);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - dayOfWeek + 6);

    return { startDate, endDate };
}

export function nextWeek() {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - dayOfWeek + 7,
    );
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - dayOfWeek + 13);
    return { startDate, endDate };
}

export function thisMonth() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 1);
    endDate.setDate(endDate.getDate() - 1);
    return { startDate, endDate };
}

export function nextMonth() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startDate = new Date(currentYear, currentMonth + 1, 1);
    const endDate = new Date(currentYear, currentMonth + 2, 1);
    endDate.setDate(endDate.getDate() - 1);
    return { startDate, endDate };
}

export function thisYear() {
    const year = new Date().getFullYear();
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31, 23, 59, 59);
    return { startDate, endDate };
}

export function fiveYears() {
    const today = new Date();
    const numberOfDaysToAdd = 1826;
    const endDate = today.setDate(today.getDate() + numberOfDaysToAdd);
    return { startDate: new Date().toDateString(), endDate: new Date(endDate) };
}
