// schemas
import {
    ICreateCompanyDTO,
    ICreateContactDTO,
    ICreateHistoryDTO,
    ICreateTableLayoutDTO,
    ILinkCounts,
} from '@data/schema';

// enums
import { LinkedCountType, LinkedEntityType, ServiceType } from '@data/enum';

export class GetCompanies {
    static readonly type = '[COMPANY] GET COMPANIES';
}

export class GetAllCompanies {
    static readonly type = '[COMPANY] GET ALL COMPANIES';
}

export class GetAllIsTenantCompanies {
    static readonly type = '[COMPANY] GET ALL IS TENANT COMPANIES';
}

export class GetCompany {
    static readonly type = '[COMPANY] GET COMPANY';
    constructor(public id: string) {}
}

export class GetLinkCountsForCompany {
    static readonly type = '[COMPANY] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetManufacturers {
    static readonly type = '[COMPANY] GET MANUFACTURERS';
}

export class GetSuppliers {
    static readonly type = '[COMPANY] GET SUPPLIERS';
}

export class GetLinkedDataForCompany {
    static readonly type = '[COMPANY] GET LINKED DATA';
    constructor(
        public id: string,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public itemType?: string,
    ) {}
}

export class GetNotesForCompany {
    static readonly type = '[COMPANY] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class GetTableLayoutForCompany {
    static readonly type = '[COMPANY] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class CreateNoteForCompany {
    static readonly type = '[COMPANY] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class CreateLinkedItemForCompany {
    static readonly type = '[COMPANY] CREATE LINKED ITEM';
    constructor(
        public id: string,
        public payload: any,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public countType: LinkedCountType,
    ) {}
}

export class CreateCompany {
    static readonly type = '[COMPANY] CREATE COMPANY';
    constructor(public payload: ICreateCompanyDTO, public files?: File) {}
}

export class UpdateCompany {
    static readonly type = '[COMPANY] UPDATE COMPANY';
    constructor(public id: string, public payload: ICreateCompanyDTO, public files?: File) {}
}

export class UpdateTableLayoutForCompany {
    static readonly type = '[COMPANY] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class DeleteCompany {
    static readonly type = '[COMPANY] DELETE COMPANY';
    constructor(public id: string) {}
}

export class DeleteCompanies {
    static readonly type = '[COMPANY] DELETE COMPANIES';
    constructor(public ids: string[]) {}
}

export class UnlinkLinkedItemsForCompany {
    static readonly type = '[COMPANY] UNLINK ITEMS';
    constructor(
        public ids: string[],
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public countType: LinkedCountType,
    ) {}
}

export class ResetCompany {
    static readonly type = '[COMPANY] RESET COMPANY';
    constructor() {}
}

export class ResetNotesCompany {
    static readonly type = '[COMPANY] RESET NOTES';
    constructor() {}
}
