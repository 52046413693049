// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { IIssue, ILinkIssuesToService, ILinkServicesToIssue, IService } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LinkServiceIssuesService {
    constructor(private apiService: ApiService) {}

    linkServicesToIssue(context: ILinkServicesToIssue): Observable<IService[]> {
        return this.apiService
            .post<{ data: IService[] }, ILinkServicesToIssue>('/linkServiceIssues/link/services', context)
            .pipe(map((res) => res.data));
    }

    linkIssuesToService(context: ILinkIssuesToService): Observable<IIssue[]> {
        return this.apiService
            .post<{ data: IIssue[] }, ILinkIssuesToService>('/linkServiceIssues/link/issues', context)
            .pipe(map((res) => res.data));
    }

    unlinkServicesFromIssue(context: ILinkServicesToIssue): Observable<IService[]> {
        return this.apiService
            .deleteBatch<{ data: IService[] }, ILinkServicesToIssue>('/linkServiceIssues/unlink/services', context)
            .pipe(map((res) => res.data));
    }

    unlinkIssuesFromService(context: ILinkIssuesToService): Observable<IIssue[]> {
        return this.apiService
            .deleteBatch<{ data: IIssue[] }, ILinkIssuesToService>('/linkServiceIssues/unlink/issues', context)
            .pipe(map((res) => res.data));
    }
}
