// core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import {
    CompanyState,
    CreateLinkedItemForContract,
    CreateLinkedItemForIssue,
    CreateLinkedItemForItem,
    CreateService,
    GetCompanies,
} from '@data/state';

// schemas
import { IButtonCustomClass, ICompany } from '@data/schema';

// services
import { DataTableService, SweetAlertService } from '@app/service';
import { SequenceService } from '@data/service';

// enums
import { LinkedCountType, LinkedEntityType, ServiceType } from '@data/enum';

@Component({
    selector: 'app-service-create-modal',
    templateUrl: './service-create-modal.component.html',
    styleUrls: ['./service-create-modal.component.scss'],
})
export class ServiceCreateModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany>;

    serviceType: ServiceType;
    linkedItemId: string;

    form: FormGroup;
    isFormProcessing = false;
    isNumberGenerating = false;

    serviceStatus = [
        { name: 'Pending', value: 'pending' },
        { name: 'Completed', value: 'completed' },
        { name: 'Cancelled', value: 'cancelled' },
    ];

    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    generateButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm w-100 mt-4',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private sequenceService: SequenceService,
        private dataTableService: DataTableService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        this.store.dispatch([new GetCompanies()]);
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            serviceNumber: [null, Validators.required],
            plannedDate: [null],
            onSiteStartDate: [null],
            onSiteEndDate: [null],
            status: [null, Validators.required],
            description: [null],
        });
    }

    get f() {
        return this.form.controls;
    }

    create() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { id, ...rest } = this.form.getRawValue();

        this.isFormProcessing = true;

        if (!this.serviceType) {
            this.store
                .dispatch(
                    new CreateService({
                        ...rest,
                    }),
                )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        this.isFormProcessing = false;
                        this.sweetAlertService.success('Successfully create the record');
                        this.bsModalRef.hide();
                        this.dataTableService.dataTableRerenderer.next(true);
                    },
                    error: () => {
                        this.isFormProcessing = false;
                    },
                });
        } else {
            switch (this.serviceType) {
                case ServiceType.CONTRACT:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForContract(
                                this.linkedItemId,
                                rest,
                                ServiceType.SERVICE,
                                LinkedEntityType.SERVICES,
                                LinkedCountType.LINKED_SERVICE_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.ISSUE:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForIssue(
                                this.linkedItemId,
                                rest,
                                ServiceType.SERVICE,
                                LinkedEntityType.SERVICES,
                                LinkedCountType.LINKED_SERVICE_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.ITEM:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForItem(
                                this.linkedItemId,
                                rest,
                                ServiceType.SERVICE,
                                LinkedEntityType.SERVICES,
                                LinkedCountType.LINKED_SERVICE_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
            }
        }
    }

    reset() {
        this.form.reset();
    }

    async generate() {
        this.isNumberGenerating = true;

        try {
            const response = await firstValueFrom(this.sequenceService.get(ServiceType.SERVICE));
            this.form.patchValue({
                serviceNumber: response.nextNumber,
            });
        } catch (error) {
            console.error(error);
        }

        this.isNumberGenerating = false;
    }
}
