// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import { ICreateHistoryDTO, IHistory } from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IHistory[]> {
        return this.apiService.get<{ data: IHistory[] }>('/histories').pipe(map((res) => res.data));
    }

    getById(id: string): Observable<IHistory> {
        return this.apiService.get<{ data: IHistory }>(`/histories/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateHistoryDTO): Observable<IHistory> {
        return this.apiService
            .post<{ data: IHistory }, ICreateHistoryDTO>(`/histories`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateHistoryDTO): Observable<IHistory> {
        return this.apiService
            .put<{ data: IHistory }, ICreateHistoryDTO>(`/histories/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IHistory }>(`/histories/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IHistory[] }, { ids: string[] }>(`/histories`, { ids });
    }

    getByEntityId(id: string, skip = 0, limit = 5) {
        return this.apiService
            .get<{ data: IHistory[] }>(`/histories/entity/${id}?skip=${skip}&limit=${limit}`)
            .pipe(map((res) => res.data));
    }
}
