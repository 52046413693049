// core
import { Injectable } from '@angular/core';
import { catchError, Subject, tap } from 'rxjs';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateContract,
    CreateLinkedItemForContract,
    CreateNoteForContract,
    DeleteContract,
    DeleteContracts,
    GenerateService,
    GetContract,
    GetContracts,
    GetLinkCountsForContract,
    GetLinkedDataForContract,
    GetNotesForContract,
    GetTableLayoutForContract,
    LinkNUnlinkIssues,
    LinkNUnlinkItems,
    LinkNUnlinkServices,
    UpdateContract,
    UpdateTableLayoutForContract,
    ResetContract,
    ResetNotesContract,
} from './contract.actions';

// services
import {
    ContractService,
    HistoryService,
    IssueService,
    ItemService,
    LinkContractIssuesService,
    LinkContractItemsService,
    LinkContractServicesService,
    ServiceService,
    TableLayoutService,
} from '@data/service';

// schemas
import { ContractStateModel } from './contract-state.model';
import { LinkedItemsStateModel } from '../linked-items/linked-items-state.model';
import { IIssue, IItem, ILinkModel, IService } from '@data/schema';

// enums
import { ContractDataTableCellType, DataTableCellType, LinkedEntityType } from '@data/enum';

// states
import { ResetService, ResetIssue, ResetItem } from '@data/state';

@State<ContractStateModel>({
    name: 'contracts',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        {
                            data: 'provider.companyName',
                            title: 'Provider',
                            type: ContractDataTableCellType.PROVIDER_CELL,
                        },
                        {
                            data: 'recipient.companyName',
                            title: 'Recipient',
                            type: ContractDataTableCellType.RECIPIENT_CELL,
                        },
                        {
                            data: 'contractNumber',
                            title: 'Contract Number',
                            type: ContractDataTableCellType.CONTRACT_NUMBER_CELL,
                        },
                        { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
                        { data: 'type.name', title: 'Type' },
                        { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                        { data: 'startDate', title: 'Start Date', type: DataTableCellType.DATE_CELL },
                        { data: 'endDate', title: 'End Date', type: DataTableCellType.DATE_CELL },
                    ],
                },
                isDefault: true,
            },
        },
        linkCounts: null,
        linkedItems: null,
    },
})
@Injectable()
export class ContractState {
    constructor(
        private store: Store,
        private contractService: ContractService,
        private serviceService: ServiceService,
        private itemService: ItemService,
        private issueService: IssueService,
        private historyService: HistoryService,
        private linkContractServicesService: LinkContractServicesService,
        private linkContractIssuesService: LinkContractIssuesService,
        private linkContractItemssService: LinkContractItemsService,
        private tableLayoutService: TableLayoutService,
    ) {}

    @Selector()
    static getContractList(state: ContractStateModel) {
        return state.items.data;
    }

    @Selector()
    static getContractListLoading(state: ContractStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentContract(state: ContractStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getLinkCounts(state: ContractStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getTableLayout(state: ContractStateModel) {
        return state.items?.tableLayout?.data?.layout;
    }

    @Selector()
    static getLinkedData(state: ContractStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.data;
        };
    }

    @Selector()
    static getLinkedDataLoading(state: ContractStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.loading;
        };
    }

    @Selector()
    static getNotes(state: ContractStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: ContractStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetContracts)
    getContracts({ getState, patchState }: StateContext<ContractStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.contractService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetContract)
    getContract({ getState, setState, patchState }: StateContext<ContractStateModel>, { id }: GetContract) {
        this.store.dispatch(new ResetContract());
        this.resetLinkedData({ patchState });
        const state = getState();

        return this.contractService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetLinkCountsForContract)
    GetLinkCountsForContract({ patchState }: StateContext<ContractStateModel>, { id }: GetLinkCountsForContract) {
        return this.contractService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetLinkedDataForContract)
    GetLinkedDataForContract(
        { getState, patchState }: StateContext<ContractStateModel>,
        { id, serviceType, dataType, itemType }: GetLinkedDataForContract,
    ) {
        const state = getState();
        if (state?.linkedItems?.[dataType]?.retrievedAt) {
            return state;
        }
        patchState({
            linkedItems: {
                ...state.linkedItems,
                [dataType]: {
                    ...state.linkedItems?.[dataType],
                    loading: true,
                },
            },
        });

        return (
            itemType
                ? this?.[serviceType + 'Service'].getByContractIdNType(id, itemType)
                : this?.[serviceType + 'Service'].getByContractId(id)
        ).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        [dataType]: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetNotesForContract)
    GetNotesForContract(
        { getState, patchState }: StateContext<ContractStateModel>,
        { id, skip, limit }: GetNotesForContract,
    ) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetTableLayoutForContract)
    GetTableLayoutForContract(
        { getState, patchState }: StateContext<ContractStateModel>,
        { page }: GetTableLayoutForContract,
    ) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                {
                                    data: 'provider.companyName',
                                    title: 'Provider',
                                    type: ContractDataTableCellType.PROVIDER_CELL,
                                },
                                {
                                    data: 'recipient.companyName',
                                    title: 'Recipient',
                                    type: ContractDataTableCellType.RECIPIENT_CELL,
                                },
                                {
                                    data: 'contractNumber',
                                    title: 'Contract Number',
                                    type: ContractDataTableCellType.CONTRACT_NUMBER_CELL,
                                },
                                { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
                                { data: 'type.name', title: 'Type' },
                                { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                                { data: 'startDate', title: 'Start Date', type: DataTableCellType.DATE_CELL },
                                { data: 'endDate', title: 'End Date', type: DataTableCellType.DATE_CELL },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(CreateNoteForContract)
    createNoteForContact(
        { getState, patchState }: StateContext<ContractStateModel>,
        { payload }: CreateNoteForContract,
    ) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateContract)
    createContract({ getState, patchState }: StateContext<ContractStateModel>, { payload }: CreateContract) {
        const state = getState();

        return this.contractService.create(payload).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(CreateLinkedItemForContract)
    CreateLinkedItemForContract(
        { getState, patchState }: StateContext<ContractStateModel>,
        { id, payload, serviceType, dataType, countType }: CreateLinkedItemForContract,
    ) {
        const state = getState();

        return this?.[serviceType + 'Service'].createWithContractLink(id, payload).pipe(
            tap((response: any) => {
                patchState({
                    linkCounts: {
                        ...state.linkCounts,
                        [countType]: state.linkCounts?.[countType] + 1,
                    },
                });
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(GenerateService)
    generateService(
        { getState, patchState, setState }: StateContext<ContractStateModel>,
        { payload }: GenerateService,
    ) {
        const state = getState();

        return this.serviceService.generateServices(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        ...state.linkCounts,
                        linkedServiceCount: state.linkCounts?.linkedServiceCount + payload?.plannedDate.length,
                    },
                });

                this.store.dispatch(new ResetService());
            }),
            catchError((error) => {
                patchState({
                    items: {
                        ...state.items,
                        loading: false,
                    },
                });
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkServices)
    LinkNUnlinkServices(
        { getState, patchState }: StateContext<ContractStateModel>,
        { payload, isLink }: LinkNUnlinkServices,
    ) {
        const state = getState();

        return this.linkContractServicesService?.[isLink ? 'linkServicesToContract' : 'unlinkServicesFromContract'](
            payload,
        ).pipe(
            tap((response: IService[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedServiceCount: state.linkCounts?.linkedServiceCount + payload.services.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedServiceCount: state.linkCounts?.linkedServiceCount - payload.services.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkIssues)
    LinkNUnlinkIssues(
        { getState, patchState }: StateContext<ContractStateModel>,
        { payload, isLink }: LinkNUnlinkIssues,
    ) {
        const state = getState();

        return this.linkContractIssuesService?.[isLink ? 'linkIssuesToContract' : 'unlinkIssuesFromContract'](
            payload,
        ).pipe(
            tap((response: IIssue[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedIssueCount: state.linkCounts?.linkedIssueCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedIssueCount: state.linkCounts?.linkedIssueCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkItems)
    LinkNUnlinkItems(
        { getState, patchState }: StateContext<ContractStateModel>,
        { payload, isLink }: LinkNUnlinkItems,
    ) {
        const state = getState();

        return this.linkContractItemssService?.[isLink ? 'linkItemsToContract' : 'unlinkItemsFromContract'](
            payload,
        ).pipe(
            tap((response: IItem[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedItemCount: state.linkCounts?.linkedItemCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedItemCount: state.linkCounts?.linkedItemCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateContract)
    updateContract(
        { getState, setState, patchState }: StateContext<ContractStateModel>,
        { id, payload }: UpdateContract,
    ) {
        return this.contractService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });

                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                });

                this.store.dispatch(new ResetNotesContract());
            }),
        );
    }

    @Action(UpdateTableLayoutForContract)
    updateTableLayoutForContract(
        { getState, patchState }: StateContext<ContractStateModel>,
        { payload, page }: UpdateTableLayoutForContract,
    ) {
        const state = getState();

        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(DeleteContract)
    deleteContract({ getState, setState }: StateContext<ContractStateModel>, { id }: DeleteContract) {
        return this.contractService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteContracts)
    deleteContracts({ getState, setState, patchState }: StateContext<ContractStateModel>, { ids }: DeleteContracts) {
        return this.contractService.deleteBatch(ids);
    }

    @Action(ResetContract)
    resetContract({ getState, patchState }: StateContext<ContractStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    @Action(ResetNotesContract)
    resetNotes({ getState, patchState }: StateContext<ContractStateModel>) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    data: [],
                    loading: true,
                },
            },
        });
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
            linkedItems: null,
        });
    }
}
