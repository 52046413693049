<nav class="custom-breadcrumb" aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li *ngFor="let item of list; last as l" class="breadcrumb-item" [class.active]="l">
            <a [routerLink]="item.route" *ngIf="item.route" class="cursor-pointer"
                ><p class="mb-0">{{ item?.page }}</p></a
            >
            <span *ngIf="!item.route" class="mb-0">{{ item?.page }}</span>
        </li>
    </ol>
</nav>
