// core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, skipWhile, Subject, switchMap, take, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import {
    CompanyState,
    ContractTypeState,
    CreateContract,
    CreateContractType,
    CreateLinkedItemForCompany,
    CreateLinkedItemForIssue,
    CreateLinkedItemForItem,
    CreateLinkedItemForService,
    GetCompanies,
    GetContractTypes,
} from '@data/state';

// schemas
import { IButtonCustomClass, ICompany, IContractType } from '@data/schema';

// services
import { DataTableService, SweetAlertService } from '@app/service';
import { SequenceService } from '@data/service';

// enums
import { LinkedEntityType, ServiceType, LinkedCountType } from '@data/enum';

@Component({
    selector: 'app-contract-create-modal',
    templateUrl: './contract-create-modal.component.html',
    styleUrls: ['./contract-create-modal.component.scss'],
})
export class ContractCreateModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany>;
    @Select(ContractTypeState.getContractTypeList) contractTypes$: Observable<IContractType[]>;

    serviceType: ServiceType;
    linkedItemId: string;
    entityType: LinkedEntityType;

    linkedEntityType = LinkedEntityType;

    contractTypesSource$: Observable<IContractType[]>;

    form: FormGroup;
    isFormProcessing = false;
    isNumberGenerating = false;

    contractStatus = [{ name: 'Active' }, { name: 'Inactive' }];

    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    generateButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm w-100 mt-4',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private sequenceService: SequenceService,
        private dataTableService: DataTableService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        this.store.dispatch([new GetCompanies(), new GetContractTypes()]);

        this.contractTypesSource$ = this.contractTypes$.pipe(
            skipWhile((items) => !items || items.length === 0),
            take(1),
        );

        this.setFormInit();
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            contractNumber: [null, Validators.required],
            status: [null, Validators.required],
            startDate: [null],
            endDate: [null],
            type: [null, Validators.required],
            recipient: [null, Validators.required],
            provider: [null, Validators.required],
            description: [null],
        });
    }

    get f() {
        return this.form.controls;
    }

    create() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { id, ...rest } = this.form.getRawValue();

        this.isFormProcessing = true;

        if (!this.serviceType) {
            this.store
                .dispatch(
                    new CreateContract({
                        ...rest,
                    }),
                )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        this.isFormProcessing = false;
                        this.sweetAlertService.success('Successfully create the record');
                        this.bsModalRef.hide();
                        this.dataTableService.dataTableRerenderer.next(true);
                    },
                    error: () => {
                        this.isFormProcessing = false;
                    },
                });
        } else {
            switch (this.serviceType) {
                case ServiceType.SERVICE:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForService(
                                this.linkedItemId,
                                rest,
                                ServiceType.CONTRACT,
                                LinkedEntityType.CONTRACTS,
                                LinkedCountType.LINKED_CONTRACT_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.ISSUE:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForIssue(
                                this.linkedItemId,
                                rest,
                                ServiceType.CONTRACT,
                                LinkedEntityType.CONTRACTS,
                                LinkedCountType.LINKED_CONTRACT_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.CONTRACT:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForCompany(
                                this.linkedItemId,
                                rest,
                                ServiceType.CONTRACT,
                                this.entityType,
                                LinkedCountType.LINKED_CONTRACT_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
                case ServiceType.ITEM:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForItem(
                                this.linkedItemId,
                                rest,
                                ServiceType.CONTRACT,
                                LinkedEntityType.CONTRACTS,
                                LinkedCountType.LINKED_CONTRACT_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
            }
        }
    }

    reset() {
        this.form.reset();
    }

    async generate() {
        this.isNumberGenerating = true;

        try {
            const response = await firstValueFrom(this.sequenceService.get(ServiceType.CONTRACT));
            this.form.patchValue({
                contractNumber: response.nextNumber,
            });
        } catch (error) {
            console.error(error);
        }

        this.isNumberGenerating = false;
    }

    addType = (name: string) => {
        return this.store
            .dispatch(new CreateContractType({ name }))
            .pipe(switchMap(() => this.store.select(ContractTypeState.getNewContractType)));
    };

    setFormInit() {
        switch (this.entityType) {
            case LinkedEntityType.CONTRACT_AS_PROVIDER:
                this.form.controls['provider'].setValue(this.linkedItemId);
                break;
            case LinkedEntityType.CONTRACT_AS_RECIPIENT:
                this.form.controls['recipient'].setValue(this.linkedItemId);
                break;
        }
    }
}
