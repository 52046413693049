// core
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

// actions
import {
    CreateEquipmentCategory,
    DeleteEquipmentCategory,
    GetEquipmentCategories,
    GetEquipmentCategory,
    UpdateEquipmentCategory,
} from './equipment-category.actions';

// services
import { EquipmentCategoryService } from '@data/service';

// schemas
import { EquipmentCategoryStateModel } from './equipment-category-state.model';

// states
import { ResetItem } from '@data/state';

@State<EquipmentCategoryStateModel>({
    name: 'equipmentCategories',
    defaults: {
        items: {
            data: [],
            loading: true,
        },
    },
})
@Injectable()
export class EquipmentCategoryState {
    constructor(private equipmentService: EquipmentCategoryService, private store: Store) {}

    @Selector()
    static getEquipmentCategoryList(state: EquipmentCategoryStateModel) {
        return state.items.data;
    }

    @Selector()
    static getEquipmentCategoryListLoading(state: EquipmentCategoryStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentEquipmentCategory(state: EquipmentCategoryStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getNewEquipmentCategory(state: EquipmentCategoryStateModel) {
        return state.newItem?.data;
    }

    @Action(GetEquipmentCategories)
    getEquipmentCategories({ getState, setState }: StateContext<EquipmentCategoryStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.equipmentService.get().pipe(
            tap((response) => {
                setState({
                    ...state,
                    items: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetEquipmentCategory)
    getEquipmentCategory(
        { getState, setState }: StateContext<EquipmentCategoryStateModel>,
        { id }: GetEquipmentCategory,
    ) {
        const state = getState();
        const currentItem = state.items.data.find((item) => item._id === id);

        if (currentItem) {
            return setState({
                ...state,
                currentItem: {
                    data: currentItem,
                    loading: false,
                    retrievedAt: Date.now(),
                },
            });
        }

        return this.equipmentService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(CreateEquipmentCategory)
    createEquipmentCategory(
        { getState, patchState }: StateContext<EquipmentCategoryStateModel>,
        { payload }: CreateEquipmentCategory,
    ) {
        return this.equipmentService.create(payload).pipe(
            tap((response) => {
                const state = getState();

                patchState({
                    items: {
                        ...state.items,
                        data: [...state.items.data, response],
                    },
                    newItem: {
                        data: response,
                    },
                });
            }),
        );
    }

    @Action(UpdateEquipmentCategory)
    updateEquipmentCategory(
        { getState, setState }: StateContext<EquipmentCategoryStateModel>,
        { id, payload }: UpdateEquipmentCategory,
    ) {
        return this.equipmentService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });
                this.store.dispatch(new ResetItem());
            }),
        );
    }

    @Action(DeleteEquipmentCategory)
    deleteEquipmentCategory(
        { getState, setState }: StateContext<EquipmentCategoryStateModel>,
        { id }: DeleteEquipmentCategory,
    ) {
        return this.equipmentService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }
}
