// core
import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// schemas
import { IDatePickerQuickRange, IFormCustomClass } from '@data/schema';

// utils
import { currentWeek, fiveYears, nextMonth, nextWeek, thisMonth, thisYear } from '@shared/util';

@Component({
    selector: 'app-date-range-input',
    templateUrl: './date-range-input.component.html',
    styleUrls: ['./date-range-input.component.scss'],
})
export class DateRangeInputComponent implements ControlValueAccessor, OnInit {
    @Output() onValueChange = new EventEmitter();
    @Output() onChangeCustomDate = new EventEmitter();
    @Input() label: string;
    @Input() placeholder: string;
    @Input() maxDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    @Input() minDate: Date = new Date(new Date().getFullYear() - 1, 0, 1);
    @Input() placement: string = 'bottom left';
    @Input() quickSelectRangesEnabled: boolean = false;
    @Input() inputCalendarIconEnabled = false;
    @Input() inputFloatingLabelEnabled = false;
    @Input() ngModel: string;
    @Input() customClasses: IFormCustomClass = {
        formGroup: 'form-group',
        label: 'font-weight-bold text-muted small mb-0',
        input: 'form-control border-0',
    };

    quickSelectRanges: IDatePickerQuickRange[] = [
        {
            value: [new Date(fiveYears().startDate), fiveYears().endDate],
            label: 'All',
        },
        {
            value: [currentWeek().startDate, currentWeek().endDate],
            label: 'This Week',
        },
        {
            value: [nextWeek().startDate, nextWeek().endDate],
            label: 'Next Week',
        },
        {
            value: [thisMonth().startDate, thisMonth().endDate],
            label: 'This Month',
        },
        {
            value: [nextMonth().startDate, nextMonth().endDate],
            label: 'Next Month',
        },
        {
            value: [thisYear().startDate, thisYear().endDate],
            label: 'This Year',
        },
    ];
    bsConfig: Partial<BsDatepickerConfig> = {
        containerClass: 'theme-dark-blue',
        dateInputFormat: 'MMM DD, YYYY',
        rangeInputFormat: 'MMM DD, YYYY',
        showClearButton: true,
        clearPosition: 'bottom right',
    };

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    ngOnInit() {
        if (this.quickSelectRangesEnabled) {
            this.bsConfig = {
                ...this.bsConfig,
                ranges: this.quickSelectRanges,
            };
        }
    }

    writeValue(obj: any): void {}

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}

    onChange($event: any) {
        this.onChangeCustomDate.emit($event);
    }
}
