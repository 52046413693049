// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateItem,
    CreateLinkedItemForItem,
    CreateNoteForItem,
    DeleteItem,
    DeleteItems,
    GetItem,
    GetItems,
    GetLinkCountsForItem,
    GetLinkedDataForItem,
    GetNotesForItem,
    GetTableLayoutForItems,
    LinkNUnlinkIssuesInItem,
    LinkNUnlinkServicesInItem,
    LinkNUnlinkContractsInItem,
    UpdateItem,
    UpdateTableLayoutForItems,
    ResetItem,
    ResetNotesItem,
} from './item.actions';

// services
import {
    ContractService,
    HistoryService,
    InstallationService,
    IssueService,
    ItemService,
    LinkItemIssuesService,
    LinkItemServicesService,
    LinkContractItemsService,
    ServiceService,
    TableLayoutService,
} from '@data/service';

// schemas
import { ItemStateModel } from './item-state.model';
import { LinkedItemsStateModel } from '../linked-items/linked-items-state.model';
import { IIssue, ILinkModel, IService, IContract } from '@data/schema';

// enums
import { DataTableCellType, ItemDataTableCellType, LinkedEntityType } from '@data/enum';

// states
import { ResetService, ResetContract, ResetIssue } from '@data/state';

@State<ItemStateModel>({
    name: 'items',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        { data: 'itemNumber', title: 'Item Number', type: ItemDataTableCellType.ITEM_NUMBER_CELL },
                        {
                            data: 'manufacturer.companyName',
                            title: 'Manufacturer',
                            type: ItemDataTableCellType.MANUFACTURER_NAME_CELL,
                        },
                        {
                            data: 'supplier.companyName',
                            title: 'Supplier',
                            type: ItemDataTableCellType.SUPPLIER_NAME_CELL,
                        },
                        {
                            data: 'purchaser.companyName',
                            title: 'Purchaser',
                            type: ItemDataTableCellType.PURCHASER_NAME_CELL,
                        },
                        { data: 'category.name', title: 'Category' },
                        { data: 'type.name', title: 'Type' },
                        { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                    ],
                },
                isDefault: true,
            },
        },
        linkCounts: null,
        linkedItems: null,
    },
})
@Injectable()
export class ItemState {
    constructor(
        private store: Store,
        private itemService: ItemService,
        private installationService: InstallationService,
        private contractService: ContractService,
        private serviceService: ServiceService,
        private issueService: IssueService,
        private historyService: HistoryService,
        private tableLayoutService: TableLayoutService,
        private linkItemIssuesService: LinkItemIssuesService,
        private linkItemServicesService: LinkItemServicesService,
        private linkContractItemsService: LinkContractItemsService,
    ) {}

    @Selector()
    static getItemList(state: ItemStateModel) {
        return state.items.data;
    }

    @Selector()
    static getItemListLoading(state: ItemStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentItem(state: ItemStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getLinkCounts(state: ItemStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getTableLayout(state: ItemStateModel) {
        return state.items?.tableLayout?.data?.layout;
    }

    @Selector()
    static getLinkedData(state: ItemStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.data;
        };
    }

    @Selector()
    static getLinkedDataLoading(state: ItemStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.loading;
        };
    }

    @Selector()
    static getNotes(state: ItemStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: ItemStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetItems)
    getItems({ getState, patchState }: StateContext<ItemStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.itemService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetItem)
    getItem({ getState, setState, patchState }: StateContext<ItemStateModel>, { id }: GetItem) {
        this.store.dispatch(new ResetItem());
        this.resetLinkedData({ patchState });
        const state = getState();

        return this.itemService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetLinkCountsForItem)
    GetLinkCountsForItem({ patchState }: StateContext<ItemStateModel>, { id }: GetLinkCountsForItem) {
        return this.itemService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetLinkedDataForItem)
    GetLinkedDataForItem(
        { getState, patchState }: StateContext<ItemStateModel>,
        { id, serviceType, dataType, itemType }: GetLinkedDataForItem,
    ) {
        const state = getState();
        if (state?.linkedItems?.[dataType]?.retrievedAt) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                [dataType]: {
                    ...state.linkedItems?.[dataType],
                    loading: true,
                },
            },
        });

        return (
            itemType
                ? this?.[serviceType + 'Service'].getByItemIdNType(id, itemType)
                : this?.[serviceType + 'Service'].getByItemId(id)
        ).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        [dataType]: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetNotesForItem)
    GetNotesForItem({ getState, patchState }: StateContext<ItemStateModel>, { id, skip, limit }: GetNotesForItem) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetTableLayoutForItems)
    GetTableLayoutForItems({ getState, patchState }: StateContext<ItemStateModel>, { page }: GetTableLayoutForItems) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                {
                                    data: 'itemNumber',
                                    title: 'Item Number',
                                    type: ItemDataTableCellType.ITEM_NUMBER_CELL,
                                },
                                {
                                    data: 'manufacturer.companyName',
                                    title: 'Manufacturer',
                                    type: ItemDataTableCellType.MANUFACTURER_NAME_CELL,
                                },
                                {
                                    data: 'supplier.companyName',
                                    title: 'Supplier',
                                    type: ItemDataTableCellType.SUPPLIER_NAME_CELL,
                                },
                                {
                                    data: 'purchaser.companyName',
                                    title: 'Purchaser',
                                    type: ItemDataTableCellType.PURCHASER_NAME_CELL,
                                },
                                { data: 'category.name', title: 'Category' },
                                { data: 'type.name', title: 'Type' },
                                { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(CreateLinkedItemForItem)
    CreateLinkedItemForItem(
        { getState, patchState }: StateContext<ItemStateModel>,
        { id, payload, serviceType, dataType, countType }: CreateLinkedItemForItem,
    ) {
        const state = getState();

        return this?.[serviceType + 'Service'].createWithItemLink(id, payload).pipe(
            tap((response: any) => {
                patchState({
                    linkCounts: {
                        ...state.linkCounts,
                        [countType]: state.linkCounts?.[countType] + 1,
                    },
                });
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(CreateNoteForItem)
    createNoteForItem({ getState, patchState }: StateContext<ItemStateModel>, { payload }: CreateNoteForItem) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateItem)
    createItem({ getState, patchState }: StateContext<ItemStateModel>, { payload }: CreateItem) {
        const state = getState();

        return this.itemService.create(payload).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkIssuesInItem)
    LinkNUnlinkIssues(
        { getState, patchState }: StateContext<ItemStateModel>,
        { payload, isLink }: LinkNUnlinkIssuesInItem,
    ) {
        const state = getState();

        return this.linkItemIssuesService?.[isLink ? 'linkIssuesToItem' : 'unlinkIssuesFromItem'](payload).pipe(
            tap((response: IIssue[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedIssueCount: state.linkCounts?.linkedIssueCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedIssueCount: state.linkCounts.linkedIssueCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkServicesInItem)
    LinkNUnlinkServicesForItem(
        { getState, patchState }: StateContext<ItemStateModel>,
        { payload, isLink }: LinkNUnlinkServicesInItem,
    ) {
        const state = getState();

        return this.linkItemServicesService?.[isLink ? 'linkServicesToItem' : 'unlinkServicesFromItem'](payload).pipe(
            tap((response: IService[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedServiceCount: state.linkCounts?.linkedServiceCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedServiceCount: state.linkCounts.linkedServiceCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateItem)
    updateItem({ getState, setState, patchState }: StateContext<ItemStateModel>, { id, payload }: UpdateItem) {
        return this.itemService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });

                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                });

                this.store.dispatch(new ResetNotesItem());
            }),
        );
    }

    @Action(UpdateTableLayoutForItems)
    updateTableLayoutForItems(
        { getState, patchState }: StateContext<ItemStateModel>,
        { payload, page }: UpdateTableLayoutForItems,
    ) {
        const state = getState();

        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(DeleteItem)
    deleteItem({ getState, setState }: StateContext<ItemStateModel>, { id }: DeleteItem) {
        return this.itemService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteItems)
    deleteItems({ getState, setState, patchState }: StateContext<ItemStateModel>, { ids }: DeleteItems) {
        return this.itemService.deleteBatch(ids);
    }

    @Action(LinkNUnlinkContractsInItem)
    LinkNUnlinkContractsInItem(
        { getState, patchState }: StateContext<ItemStateModel>,
        { payload, isLink }: LinkNUnlinkContractsInItem,
    ) {
        const state = getState();

        return this.linkContractItemsService?.[isLink ? 'linkContractsToItem' : 'unlinkContractsFromItem'](
            payload,
        ).pipe(
            tap((response: IContract[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedContractCount: state.linkCounts?.linkedContractCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedContractCount: state.linkCounts.linkedContractCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(ResetItem)
    resetItem({ getState, patchState }: StateContext<ItemStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    @Action(ResetNotesItem)
    resetNotes({ getState, patchState }: StateContext<ItemStateModel>) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    data: [],
                    loading: true,
                },
            },
        });
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
            linkedItems: null,
        });
    }
}
