<div class="form-check form-switch" [class]="customClasses.formGroup">
    <input
        [class.is-invalid]="ngControl.touched && ngControl.invalid"
        [class]="customClasses.input"
        [formControl]="ngControl.control"
        [checked]="checked"
        [attr.data-qa]="testAutomationSelectorName"
        (change)="handleCheckboxChange($event)"
        (click)="handleCheckboxChange($event)"
        class="form-check-input"
        type="{{ type }}"
        role="switch"
        id="flexSwitchCheck"
    />
    <label class="form-check-label ms-2" for="flexSwitchCheck" [class]="customClasses.label">{{ label }}</label>
</div>
