<div [class]="customClasses.formGroup">
    <div class="checkbox">
        <label [class]="customClasses.label">
            <input
                class="align-middle"
                [class.is-invalid]="ngControl.touched && ngControl.invalid"
                type="{{ type }}"
                [formControl]="ngControl.control"
                [checked]="checked"
                [attr.data-qa]="testAutomationSelectorName"
                (change)="handleCheckboxChange($event)"
            />
            <span class="ps-1">
                {{ label }}
            </span>
        </label>
    </div>
    <div
        *ngIf="ngControl.control.errors?.['required']"
        class="invalid-feedback"
        [attr.data-qa]="testAutomationSelectorName + '_validation_msg_required'"
    >
        Please check the {{ label }}
    </div>
</div>

<!-- (click)="handleCheckboxChange($event)" -->
