<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Contact</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="contact-modal_form_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-12">
                    <!-- form-input: firstName -->
                    <app-text-input
                        [formControl]="f['firstName']"
                        [label]="'First Name'"
                        testAutomationSelectorName="contact-modal_input_first_name"
                    ></app-text-input>
                    <!-- //. form-input: firstName -->
                </div>
                <div class="col-12">
                    <!-- form-input: lastName -->
                    <app-text-input
                        [formControl]="f['lastName']"
                        [label]="'Last Name'"
                        testAutomationSelectorName="contact-modal_input_last_name"
                    ></app-text-input>
                    <!-- //. form-input: lastName -->
                </div>
                <div class="col-12">
                    <!-- form-input: email -->
                    <app-text-input
                        [formControl]="f['email']"
                        [label]="'Email'"
                        testAutomationSelectorName="contact-modal_input_email"
                    ></app-text-input>
                    <!-- //. form-input: email -->
                </div>
                <div class="col-12">
                    <!-- form-select: role -->
                    <app-select-input
                        [formControl]="f['role']"
                        [list]="(tenantId$ | async) !== null ? userRolesTenantAdmin : userRoles"
                        [label]="'Role'"
                        [bindField]="'value'"
                        [labelField]="'name'"
                        testAutomationSelectorName="contact-modal_select_role"
                    ></app-select-input>
                    <!-- //. form-select: role -->
                </div>
                <div class="col-12" *ngIf="parentDisabled">
                    <!-- form-input: parent -->
                    <app-select-input
                        [formControl]="f['parent']"
                        [list]="companies$ | async"
                        [label]="'Parent'"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="contact-modal_select_parent"
                    ></app-select-input>
                    <!-- //. form-input: parent -->
                </div>
                <div
                    class="col-12"
                    *ngIf="
                        (userRole === userRoleType.ADMIN && this.form.get('role').value === 'applicationAdmin') ||
                        this.form.get('role').value === 'applicationViewer' ||
                        (userRole === userRoleType.APPLICATION_ADMIN &&
                            this.form.get('role').value === 'applicationAdmin') ||
                        this.form.get('role').value === 'applicationViewer'
                    "
                >
                    <!-- form-select: access companies -->
                    <app-select-input
                        [formControl]="f['tenants']"
                        [list]="userRole === userRoleType.ADMIN ? (companies$ | async) : currentUser.user.tenants"
                        [label]="'Access companies'"
                        [multiple]="true"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="contact-modal_select_role"
                    ></app-select-input>
                    <!-- //. form-select: access companies -->
                </div>
                <div class="col-12">
                    <!-- form-input: telMobile -->
                    <app-text-input
                        [formControl]="f['telMobile']"
                        [label]="'Tel Mobile'"
                        testAutomationSelectorName="contact-modal_input_tel_mobile"
                    ></app-text-input>
                    <!-- //. form-input: telMobile -->
                </div>
                <div class="col-12">
                    <!-- form-input: telWork -->
                    <app-text-input
                        [formControl]="f['telWork']"
                        [label]="'Tel Work'"
                        testAutomationSelectorName="contact-modal_input_tel_work"
                    ></app-text-input>
                    <!-- //. form-input: telWork -->
                </div>
                <!--
                <div class="col-12 mt-3">
                     form-input: Partner Login Enabled
                    <app-toggle-input
                        [label]="'Partner Login Enabled'"
                        [formControl]="f['isPartnerLoginEnable']"
                        testAutomationSelectorName="contact-modal_check_is_partner_login_enabled"
                    ></app-toggle-input>
                    //. form-input: Partner Login Enabled 
                </div>
                <div class="col-12 mt-3">
                    form-input: Partner Login View Only 
                    <app-toggle-input
                        [label]="'Partner Login View Only'"
                        [formControl]="f['isPartnerLoginViewOnly']"
                        testAutomationSelectorName="contact-modal_check_is_partner_login_view_only"
                    ></app-toggle-input>
                    //. form-input: Partner Login View Only
                </div>
                 -->
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>
<div class="modal-footer">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
