// schemas
import {
    ICreateHistoryDTO,
    ICreateServiceDTO,
    ICreateTableLayoutDTO,
    ILinkContractsToService,
    ILinkIssuesToService,
    ILinkItemsToService,
} from '@data/schema';

// enums
import { ServiceType, LinkedEntityType, LinkedCountType } from '@data/enum';

export class GetServices {
    static readonly type = '[SERVICE] GET SERVICES';
}

export class GetService {
    static readonly type = '[SERVICE] GET SERVICE';
    constructor(public id: string) {}
}

export class GetLinkCountsForService {
    static readonly type = '[SERVICE] GET LINK COUNT';
    constructor(public id: string) {}
}

export class GetLinkedDataForService {
    static readonly type = '[SERVICE] GET LINKED DATA';
    constructor(
        public id: string,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public itemType?: string,
    ) {}
}

export class GetNotesForService {
    static readonly type = '[SERVICE] GET NOTES';
    constructor(public id: string, public skip?: number, public limit?: number) {}
}

export class GetTableLayoutForService {
    static readonly type = '[SERVICE] GET TABLE LAYOUT';
    constructor(public page: string) {}
}

export class CreateNoteForService {
    static readonly type = '[SERVICE] CREATE NOTE';
    constructor(public payload: ICreateHistoryDTO) {}
}

export class CreateService {
    static readonly type = '[SERVICE] CREATE SERVICE';
    constructor(public payload: ICreateServiceDTO) {}
}

export class LinkNUnlinkContracts {
    static readonly type = '[SERVICE] LINK/UNLINK  CONTRACTS';
    constructor(public payload: ILinkContractsToService, public isLink: boolean) {}
}

export class LinkNUnlinkIssuesForService {
    static readonly type = '[SERVICE] LINK/UNLINK  ISSUES';
    constructor(public payload: ILinkIssuesToService, public isLink: boolean) {}
}

export class LinkNUnlinkItemsInService {
    static readonly type = '[SERVICE] LINK/UNLINK ITEMS';
    constructor(public payload: ILinkItemsToService, public isLink: boolean) {}
}

export class CreateLinkedItemForService {
    static readonly type = '[SERVICE] CREATE LINKED ITEM';
    constructor(
        public id: string,
        public payload: any,
        public serviceType: ServiceType,
        public dataType: LinkedEntityType,
        public countType: LinkedCountType,
    ) {}
}

export class UpdateService {
    static readonly type = '[SERVICE] UPDATE SERVICE';
    constructor(public id: string, public payload: ICreateServiceDTO) {}
}

export class UpdateTableLayoutForService {
    static readonly type = '[SERVICE] UPDATE TABLE LAYOUT';
    constructor(public payload: ICreateTableLayoutDTO, public page: string) {}
}

export class DeleteService {
    static readonly type = '[SERVICE] DELETE SERVICE';
    constructor(public id: string) {}
}

export class DeleteServices {
    static readonly type = '[SERVICE] DELETE SERVICES';
    constructor(public ids: string[]) {}
}

export class ResetService {
    static readonly type = '[SERVICE] RESET SERVICE';
    constructor() {}
}

export class GetCurrentCheckList {
    static readonly type = '[SERVICE] GET CURRENT CHECKLIST';
    constructor(public id: string) {}
}

export class ChangeCheckListFieldValue {
    static readonly type = '[SERVICE] CHANGE CHECKLIST FIELD VALUE';
    constructor(
        public id: string,
        public value: string,
        public file?: any,
        public isImage?: string,
        public userName?: string,
    ) {}
}

export class ResetNotesService {
    static readonly type = '[SERVICE] RESET NOTES';
    constructor() {}
}
