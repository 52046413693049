<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css" />
<div class="row mx-0" *ngIf="!dataTableTemplateSearch || dataTableActionButtonEnabled">
    <div class="col my-auto g-0">
        <div class="row py-3 px-4">
            <!-- DataTable Search -->
            <div class="col-2 col-sm-3 my-auto" *ngIf="dataTableEnableSearch && !dataTableTemplateSearch">
                <div class="form-group has-search">
                    <input
                        #dataTableSearchInput
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search"
                        [attr.data-qa]="testAutomationSelectorName + '_input_search'"
                        (search)="onDataTableSearch(dataTableSearchInput.value)"
                        (keyup)="onDataTableSearch(dataTableSearchInput.value)"
                    />
                    <i class="bi bi-search"></i>
                </div>
            </div>
            <!-- //. DataTable Search -->
            <!-- DataTable date range filter -->
            <div class="col-3 col-sm-5 my-auto" *ngIf="dataTableEnableFilters">
                <div class="form-group has-date-filter">
                    <app-date-range-input
                        [ngModel]="dateRange"
                        class="w-min-280 date-range-wrapper"
                        [quickSelectRangesEnabled]="true"
                        [inputCalendarIconEnabled]="true"
                        [customClasses]="dateRangeInputCustomClasses"
                        (onChangeCustomDate)="onChangeCustomDate($event)"
                    ></app-date-range-input>
                </div>
            </div>
            <!-- //. DataTable date range filter -->
            <div class="col-2 col-sm-3 my-auto" *ngIf="dataTableEnableFilters">
                <div class="form-group">
                    <button
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-primary border border-primary"
                        (click)="filterButtonEvent()"
                        [ngClass]="this.filterCount > 0 ? 'bg-primary text-white' : ''"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-filter-left"></i>&nbsp;&nbsp; Filters
                        <span class="badge rounded-pill bg-danger fw-medium fs-10 align-middle">{{ filterCount }}</span>
                    </button>
                </div>
            </div>
            <!-- DataTable Action Button -->
            <div
                class="col my-auto d-flex"
                [ngClass]="
                    dataTableInfo.buttonTitle === 'Remove Checklist Template' ||
                    dataTableInfo.buttonTitle === 'Add Checklist Template'
                        ? ''
                        : 'col-sm-9'
                "
            >
                <div class="ms-auto">
                    <button
                        *ngIf="
                            dataTableActionButtonEnabled &&
                            userRole !== userRoleType.APPLICATION_VIEWER &&
                            userRole !== userRoleType.TENANT_VIEWER &&
                            userRole !== userRoleType.COMPANY_APPLICATION_ADMIN &&
                            userRole !== userRoleType.COMPANY_APPLICATION_VIEWER &&
                            userRole !== userRoleType.COMPANY_TENANT_VIEWER
                        "
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-danger"
                        (click)="outputActionButtonEvent(dataTableActionType.DELETE)"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-trash"></i>
                        Delete
                    </button>

                    <button
                        *ngIf="
                            dataTableActionButtonEnabled &&
                            userRole !== userRoleType.APPLICATION_VIEWER &&
                            userRole !== userRoleType.TENANT_VIEWER &&
                            userRole !== userRoleType.COMPANY_APPLICATION_ADMIN &&
                            userRole !== userRoleType.COMPANY_APPLICATION_VIEWER &&
                            userRole !== userRoleType.COMPANY_TENANT_VIEWER
                        "
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2"
                        (click)="exportButtonEvent()"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-file-arrow-down"></i>
                        Export List
                    </button>

                    <button
                        *ngIf="dataTableActionButtonEnabled"
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2"
                        (click)="showAllButtonEvent()"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-eye"></i>
                        Show All
                    </button>

                    <button
                        *ngIf="dataTableActionButtonEnabled"
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2"
                        (click)="editLayoutButtonEvent()"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-pencil"></i>
                        Edit Layout
                    </button>

                    <button
                        *ngIf="
                            linkedDataTableActionButtonEnabled &&
                            linkedDataTableActionButtons.includes(dataTableActionType.UNLINK) &&
                            userRole !== userRoleType.APPLICATION_VIEWER &&
                            userRole !== userRoleType.TENANT_VIEWER
                        "
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-primary"
                        (click)="outputActionButtonEvent(dataTableActionType.UNLINK)"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-bookmark-dash"></i>
                        Unlink
                    </button>

                    <button
                        *ngIf="
                            linkedDataTableActionButtonEnabled &&
                            linkedDataTableActionButtons.includes(dataTableActionType.LINK) &&
                            userRole !== userRoleType.APPLICATION_VIEWER &&
                            userRole !== userRoleType.TENANT_VIEWER
                        "
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-primary"
                        (click)="outputActionButtonEvent(dataTableActionType.LINK)"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-bookmark-star"></i>
                        Link Existing
                    </button>

                    <!-- <button
                        *ngIf="linkItemDataTableActionButtonEnabled"
                        class="btn btn-link btn-sm shadow-none text-decoration-none me-2 text-primary"
                        (click)="outputActionButtonEvent(dataTableActionType.RESET_FILTERS)"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <i class="bi bi-arrow-clockwise"></i>
                        Reset All Filters
                    </button> -->

                    <button
                        *ngIf="
                            linkItemDataTableActionButtonEnabled &&
                            userRole !== userRoleType.APPLICATION_VIEWER &&
                            userRole !== userRoleType.TENANT_VIEWER
                        "
                        class="btn btn-primary btn-sm"
                        (click)="outputActionButtonEvent(dataTableActionType.LINK_ITEMS)"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <span
                            *ngIf="dataTableActionButtonProcessing"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Link Item
                    </button>

                    <button
                        *ngIf="
                            (dataTableActionButtonEnabled &&
                                userRole !== userRoleType.APPLICATION_VIEWER &&
                                userRole !== userRoleType.TENANT_VIEWER &&
                                userRole !== userRoleType.COMPANY_APPLICATION_ADMIN &&
                                userRole !== userRoleType.COMPANY_APPLICATION_VIEWER &&
                                userRole !== userRoleType.COMPANY_TENANT_VIEWER) ||
                            (linkedDataTableActionButtonEnabled &&
                                !linkedDataTableActionButtons.includes(dataTableActionType.NO_CREATE) &&
                                userRole !== userRoleType.APPLICATION_VIEWER &&
                                userRole !== userRoleType.TENANT_VIEWER)
                        "
                        class="btn btn-primary btn-sm"
                        [ngClass]="
                            dataTableInfo.buttonTitle === 'Remove Checklist Template'
                                ? 'btn-danger text-white'
                                : 'btn-primary'
                        "
                        (click)="outputActionButtonEvent(dataTableActionButtonType)"
                        [disabled]="dataTableActionButtonProcessing || dataTableActionButtonDisabledState"
                        [attr.data-qa]="testAutomationSelectorName + '_btn_default_action'"
                    >
                        <span
                            *ngIf="dataTableActionButtonProcessing"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        {{ dataTableInfo.buttonTitle }}
                    </button>
                </div>
            </div>
            <!-- //. DataTable Action Button -->
        </div>
    </div>
</div>

<div class="row" [class.min-vh-50]="dataTableProcessing">
    <div class="col overflow-auto mx-2">
        <table
            *ngIf="!dataTableSourceLoading"
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            [class]="customClasses.table"
            [class.d-none]="dataTableProcessing && !dataTableEnableServerSideImmutable"
            [class.non-empty]="dataTableSource?.length > 0 && dataTableEnableServerSideImmutable"
            [attr.data-qa]="testAutomationSelectorName"
        >
            <thead>
                <tr>
                    <th *ngFor="let column of dataTableCols" class="text-wrap text-justify">
                        <ng-container
                            *ngIf="
                                dataTableSelectAllCheckBoxEnabled && column.type === dataTableCellType.CHECKBOX_INPUT;
                                else defaultCellHeader
                            "
                        >
                            <form [formGroup]="dataTableForm">
                                <div>
                                    <app-checkbox-input
                                        [formControl]="f['selectAll']"
                                        [label]=""
                                        [customClasses]="checkboxCustomClasses"
                                    ></app-checkbox-input>
                                </div>
                            </form>
                        </ng-container>
                        <ng-template #defaultCellHeader>{{ column.title }}</ng-template>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="dataTableSource?.length > 0 && !dataTableProcessing">
                <ng-container
                    *ngIf="rowTemplate"
                    [ngTemplateOutlet]="rowTemplate"
                    [ngTemplateOutletContext]="{
                        columns: dataTableCols,
                        items: dataTableSource,
                        currentColumns: dataTableColsCurrent
                    }"
                ></ng-container>
                <ng-container *ngIf="!rowTemplate">
                    <tr
                        *ngFor="let sourceItem of dataTableSource"
                        [attr.data-qa]="testAutomationSelectorName + '_table_row'"
                    >
                        <ng-container
                            *ngIf="cellTemplate"
                            [ngTemplateOutlet]="cellTemplate"
                            [ngTemplateOutletContext]="{
                                columns: dataTableCols,
                                currentColumns: dataTableColsCurrent,
                                item: sourceItem
                            }"
                        ></ng-container>
                        <ng-container *ngIf="!cellTemplate">
                            <td
                                *ngFor="let column of dataTableCols"
                                class="row-click"
                                (click)="outputTableEvent(dataTableActionType.ROW_CLICK_NON_BOUND, sourceItem)"
                            >
                                {{ getColumnData(sourceItem, column) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <app-loading-grow *ngIf="dataTableProcessing"></app-loading-grow>
    </div>
</div>

<!-- Custom Footer Template -->
<ng-container [ngTemplateOutlet]="footerTemplate" *ngIf="footerTemplate"></ng-container>
<!-- //. Custom Footer Template -->
