<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Export List</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <div class="m-4">
        <p class="fs-18">This Function will export {{ filteredRecordsCount }} of {{ totalRecordsCount }} records</p>
        <div class="form-check form-switch form-group ps-0">
            <label class="form-check-label text-secondary fs-18" for="flexSwitchCheck"> Include header rows </label>
            <input
                [checked]="isIncludeHeader"
                (change)="onIncludeHeaders($event)"
                class="form-check-input float-end"
                type="checkbox"
                role="switch"
                id="flexSwitchCheck"
            />
        </div>
        <div class="form-check form-switch form-group ps-0">
            <label class="form-check-label text-secondary fs-18" for="flexSwitchCheck"> Include object uuids </label>
            <input
                [checked]="isIncludeUuid"
                (change)="onIncludeUuids($event)"
                class="form-check-input float-end"
                type="checkbox"
                role="switch"
                id="flexSwitchCheck"
            />
        </div>
    </div>
</div>

<div class="modal-footer">
    <hr class="m-0" />
    <div class="row w-100">
        <div class="col-12 p-0">
            <button (click)="export(exportType.PDF)" class="btn btn-primary btn-sm">
                <i class="bi bi-arrow-down-square me-2"></i>Export as pdf
            </button>
            <button (click)="export(exportType.EXCEL)" class="btn btn-outline-primary btn-sm mx-2">
                <i class="bi bi-arrow-down-square me-2"></i>Export as excel
            </button>
            <button (click)="export(exportType.CSV)" class="btn btn-outline-primary btn-sm">
                <i class="bi bi-arrow-down-square me-2"></i>Export as csv
            </button>
        </div>
    </div>
</div>
