import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[ng-datatable-dropdown-tmp]' })
export class NgDataTableDropdownTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ng-datatable-footer-tmp]' })
export class NgDataTableFooterTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ng-datatable-cell-tmp]' })
export class NgDataTableCellTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ng-datatable-row-tmp]' })
export class NgDataTableRowTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ng-datatable-subtitle-tmp]' })
export class NgDataTableSubtitleTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ng-datatable-inner-footer-tmp]' })
export class NgDataTableInnerFooterTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ng-datatable-export-tmp]' })
export class NgDataTableExportTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}
