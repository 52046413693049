// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    ChangeCheckListFieldValue,
    CreateLinkedItemForService,
    CreateNoteForService,
    CreateService,
    DeleteService,
    DeleteServices,
    GetCurrentCheckList,
    GetLinkCountsForService,
    GetLinkedDataForService,
    GetNotesForService,
    GetService,
    GetServices,
    GetTableLayoutForService,
    LinkNUnlinkContracts,
    LinkNUnlinkIssuesForService,
    LinkNUnlinkItemsInService,
    ResetNotesService,
    ResetService,
    UpdateService,
    UpdateTableLayoutForService,
} from './service.actions';

// services
import {
    ContractService,
    HistoryService,
    IssueService,
    ItemService,
    LinkContractServicesService,
    LinkServiceIssuesService,
    LinkItemServicesService,
    ServiceService,
    TableLayoutService,
} from '@data/service';

// schemas
import { ServiceStateModel } from './service-state.model';
import { GetLinkCountsForContract, LinkNUnlinkIssues, ResetContract } from '../contract/contract.actions';
import { IContract, IIssue, IItem, ILinkModel, IService } from '@data/schema';
import { LinkedItemsStateModel } from '../linked-items/linked-items-state.model';

// enums
import { DataTableCellType, LinkedEntityType, ServiceDataTableCellType } from '@data/enum';

// states
import { ResetItem, ResetIssue, ResetNotesItem } from '@data/state';

@State<ServiceStateModel>({
    name: 'services',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        { data: 'serviceNumber', title: 'Service Number', type: DataTableCellType.LINK_CELL },
                        { data: 'plannedDate', title: 'Planned Date', type: DataTableCellType.DATE_CELL },
                        { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
                        { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                        {
                            data: 'checkList.checkListName',
                            title: 'Checklist Template',
                            type: ServiceDataTableCellType.SERVICE_CHECKLIST_NAME,
                        },
                        { data: 'onSiteStartDate', title: 'Start Date', type: DataTableCellType.DATE_CELL },
                        { data: 'onSiteEndDate', title: 'End Date', type: DataTableCellType.DATE_CELL },
                    ],
                },
                isDefault: true,
            },
        },
        linkCounts: null,
        linkedItems: null,
        checkList: {
            data: [],
        },
    },
})
@Injectable()
export class ServiceState {
    constructor(
        private store: Store,
        private serviceService: ServiceService,
        private contractService: ContractService,
        private itemService: ItemService,
        private issueService: IssueService,
        private historyService: HistoryService,
        private linkContractServicesService: LinkContractServicesService,
        private linkServiceIssuesService: LinkServiceIssuesService,
        private linkItemServicesService: LinkItemServicesService,
        private tableLayoutService: TableLayoutService,
    ) {}

    @Selector()
    static getServiceList(state: ServiceStateModel) {
        return state.items.data;
    }

    @Selector()
    static getServiceListLoading(state: ServiceStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentServiceCheckList(state: ServiceStateModel) {
        return state.checkList.data;
    }

    @Selector()
    static getCurrentService(state: ServiceStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getLinkCounts(state: ServiceStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getLinkedData(state: ServiceStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.data;
        };
    }

    @Selector()
    static getLinkedDataLoading(state: ServiceStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.loading;
        };
    }

    @Selector()
    static getNotes(state: ServiceStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: ServiceStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Selector()
    static getTableLayout(state: ServiceStateModel) {
        return state.items?.tableLayout?.data?.layout;
    }

    @Action(GetServices)
    getServices({ getState, patchState }: StateContext<ServiceStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.serviceService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetService)
    getService({ getState, setState, patchState }: StateContext<ServiceStateModel>, { id }: GetService) {
        this.store.dispatch(new ResetService());
        this.resetLinkedData({ patchState });
        const state = getState();

        return this.serviceService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetCurrentCheckList)
    getCurrentCheckList(
        { getState, setState, patchState }: StateContext<ServiceStateModel>,
        { id }: GetCurrentCheckList,
    ) {
        const state = getState();

        return this.serviceService.getCurrentCheckListById(id).pipe(
            tap((response) => {
                const Response: any = response;
                const newKey = 'name';
                Response?.map((data) => {
                    if (data.checklistField?.inputType === 'multiSelect') {
                        if (data.values !== '') {
                            const arr = data.values.split(',');
                            data.values = arr;
                        }
                    }
                    if (data.checklistField?.options.length > 0) {
                        data.checklistField?.options.forEach((obj) => {
                            obj[newKey] = obj.value;
                        });
                    }
                });

                setState({
                    ...state,
                    checkList: {
                        data: response,
                    },
                });
            }),
        );
    }

    @Action(GetLinkCountsForService)
    GetLinkCountsForService({ patchState }: StateContext<ServiceStateModel>, { id }: GetLinkCountsForContract) {
        return this.serviceService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetLinkedDataForService)
    GetLinkedDataForService(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { id, serviceType, dataType, itemType }: GetLinkedDataForService,
    ) {
        const state = getState();

        if (state?.linkedItems?.[dataType]?.retrievedAt) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                [dataType]: {
                    ...state.linkedItems?.[dataType],
                    loading: true,
                },
            },
        });

        return (
            itemType
                ? this?.[serviceType + 'Service'].getByServiceIdNType(id, itemType)
                : this?.[serviceType + 'Service'].getByServiceId(id)
        ).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        [dataType]: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetTableLayoutForService)
    GetTableLayoutForService(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { page }: GetTableLayoutForService,
    ) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                { data: 'serviceNumber', title: 'Service Number', type: DataTableCellType.LINK_CELL },
                                { data: 'plannedDate', title: 'Planned Date', type: DataTableCellType.DATE_CELL },
                                { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
                                { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                                {
                                    data: 'checkList.checkListName',
                                    title: 'Checklist Template',
                                    type: ServiceDataTableCellType.SERVICE_CHECKLIST_NAME,
                                },
                                { data: 'onSiteStartDate', title: 'Start Date', type: DataTableCellType.DATE_CELL },
                                { data: 'onSiteEndDate', title: 'End Date', type: DataTableCellType.DATE_CELL },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(GetNotesForService)
    GetNotesForService(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { id, skip, limit }: GetNotesForService,
    ) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(CreateNoteForService)
    createNoteForService({ getState, patchState }: StateContext<ServiceStateModel>, { payload }: CreateNoteForService) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateService)
    createService({ getState, patchState }: StateContext<ServiceStateModel>, { payload }: CreateService) {
        const state = getState();

        return this.serviceService.create(payload).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(CreateLinkedItemForService)
    CreateLinkedItemForService(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { id, payload, serviceType, dataType, countType }: CreateLinkedItemForService,
    ) {
        const state = getState();

        return this?.[serviceType + 'Service'].createWithServiceLink(id, payload).pipe(
            tap((response: any) => {
                patchState({
                    linkCounts: {
                        ...state.linkCounts,
                        [countType]: state.linkCounts?.[countType] + 1,
                    },
                });
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkContracts)
    LinkNUnlinkServices(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { payload, isLink }: LinkNUnlinkContracts,
    ) {
        const state = getState();

        return this.linkContractServicesService?.[isLink ? 'linkContractsToService' : 'unlinkContractsFromService'](
            payload,
        ).pipe(
            tap((response: IContract[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedContractCount: state.linkCounts?.linkedContractCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedContractCount: state.linkCounts.linkedContractCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkIssuesForService)
    LinkNUnlinkIssuesForService(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { payload, isLink }: LinkNUnlinkIssuesForService,
    ) {
        const state = getState();

        return this.linkServiceIssuesService?.[isLink ? 'linkIssuesToService' : 'unlinkIssuesFromService'](
            payload,
        ).pipe(
            tap((response: IIssue[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedIssueCount: state.linkCounts?.linkedIssueCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedIssueCount: state.linkCounts.linkedIssueCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkItemsInService)
    LinkNUnlinkItemsForService(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { payload, isLink }: LinkNUnlinkItemsInService,
    ) {
        const state = getState();

        return this.linkItemServicesService?.[isLink ? 'linkItemsToService' : 'unlinkItemsFromService'](payload).pipe(
            tap((response: IItem[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedItemCount: state.linkCounts?.linkedItemCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedItemCount: state.linkCounts.linkedItemCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateService)
    updateService({ getState, setState, patchState }: StateContext<ServiceStateModel>, { id, payload }: UpdateService) {
        return this.serviceService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });

                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                });

                this.store.dispatch(new ResetNotesItem());
            }),
        );
    }

    @Action(UpdateTableLayoutForService)
    updateTableLayoutForContact(
        { getState, patchState }: StateContext<ServiceStateModel>,
        { payload, page }: UpdateTableLayoutForService,
    ) {
        const state = getState();

        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(ChangeCheckListFieldValue)
    changeCheckListFieldValue(
        { getState, setState }: StateContext<ServiceStateModel>,
        { id, value, file, isImage, userName }: ChangeCheckListFieldValue,
    ) {
        let FieldValue;
        const state = getState();
        let currentList = [...state.checkList.data];
        const index = currentList.findIndex((item) => item._id === id);
        if (value === 'undefined' || value === 'null') {
            FieldValue = '';
        } else {
            FieldValue = value;
        }
        return this.serviceService.updateCheckListFieldValue('Checklist', id, FieldValue, file, isImage, userName).pipe(
            tap((response) => {
                if (isImage === 'true') {
                    const updatedItem = { ...currentList[index], values: response.values, signedBy: response.signedBy };
                    currentList[index] = updatedItem;
                } else {
                    const updatedItem = { ...currentList[index], values: value };
                    currentList[index] = updatedItem;
                }
                setState({
                    ...state,
                    checkList: {
                        ...state.checkList,
                        data: currentList,
                    },
                });
            }),
        );
    }

    @Action(DeleteService)
    deleteService({ getState, setState }: StateContext<ServiceStateModel>, { id }: DeleteService) {
        return this.serviceService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteServices)
    deleteServices({ getState, setState, patchState }: StateContext<ServiceStateModel>, { ids }: DeleteServices) {
        return this.serviceService.deleteBatch(ids);
    }

    @Action(ResetService)
    resetService({ getState, patchState }: StateContext<ServiceStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    @Action(ResetNotesService)
    resetNotes({ getState, patchState }: StateContext<ServiceStateModel>) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    data: [],
                    loading: true,
                },
            },
        });
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
            linkedItems: null,
        });
    }
}
