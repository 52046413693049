<div
    class="mobile-sidebar d-lg-none d-block"
    [class.mobile-expanded]="isSidebarExpanded"
    [class.mobile-collapsed]="!isSidebarExpanded"
>
    <div class="row py-3">
        <!-- logo -->
        <div class="col d-flex ms-4 my-auto">
            <img
                *ngIf="isSidebarExpanded && (tenantId$ | async) === null"
                alt=""
                class="logo-expanded"
                src="/assets/img/common/logo-lg.png"
            />
        </div>
        <div *ngIf="logoUrl">
            <img *ngIf="isSidebarExpanded && (tenantId$ | async)" alt="" class="logo-expanded" [src]="logoUrl" />
        </div>
        <div *ngIf="!logoUrl">
            <span
                *ngIf="isSidebarExpanded && (tenantId$ | async)"
                class="logo-expanded text-white fw-medium text-uppercase h6"
                >{{ companyName }}</span
            >
            <span
                *ngIf="!isSidebarExpanded && (tenantId$ | async)"
                class="logo-collapsed text-white fw-medium text-uppercase text-small"
                >{{ companyName.substring(0, 2) }}</span
            >
        </div>
        <!-- //logo -->

        <div class="col">
            <a (click)="sideNavClose()" class="text-white fs-24 float-end me-3 my-auto cursor-pointer"
                ><i class="bi bi-x"></i
            ></a>
        </div>
    </div>

    <!-- List Group -->
    <ul class="sidebar list-group mobile" [class.fade-in]="isSidebarExpanded" [class.fade-out]="!isSidebarExpanded">
        <a
            *ngFor="
                let item of (userRole$ | async) === userRoleType.APPLICATION_VIEWER ||
                (userRole$ | async) === userRoleType.TENANT_VIEWER
                    ? viewRoutes
                    : (tenantId$ | async) === null
                    ? tenantManageRoutes
                    : adminRoutes
            "
            [routerLink]="item.route"
            class="list-group-item mobile"
            [routerLinkActive]="!item.hasSubMenu ? 'active' : ''"
            (click)="sideNavClose()"
        >
            <div class="d-flex w-100 justify-content-start align-items-center ps-2">
                <i class="bi" [ngClass]="item.icon"></i>
                <span class="menu-collapsed">{{ item.name }}</span>
            </div>
            <!-- //List Group - subMenu -->
            <div *ngIf="item.hasSubMenu" class="dropdown-expanded mt-2">
                <a
                    *ngFor="let items of item.subMenu"
                    class="list-group-item"
                    routerLinkActive="active"
                    [routerLink]="items.route"
                >
                    <div
                        [ngClass]="{ 'ps-2': isSidebarExpanded, 'justify-content-center': !isSidebarExpanded }"
                        class="d-flex align-self-center"
                    >
                        <i [ngClass]="items.icon" class="bi"></i>
                        <span class="menu-collapsed">{{ items.name }}</span>
                    </div>
                </a>
            </div>
            <!-- //List Group - subMenu-->
        </a>
        <!-- //List Group -->
    </ul>
</div>
