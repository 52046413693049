// core
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import Swal from 'sweetalert2';

// states
import { Logout } from '@data/state';

// services
import { AuthService } from './auth.service';

// schemas

// components

@Injectable({
    providedIn: 'root',
})
export class SweetAlertService {
    toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: false,
    });

    constructor(private zone: NgZone, private router: Router, private store: Store, private authService: AuthService) {}

    success(message: string) {
        this.toast.fire({
            icon: 'success',
            iconColor: '#12b76a',
            title: message,
            customClass: {
                container: 'toast-success',
            },
        });
    }

    error(message: string) {
        this.toast.fire({
            icon: 'error',
            title: message,
            customClass: {
                container: 'toast-error',
            },
        });
    }

    warning(message: string) {
        this.toast.fire({
            icon: 'warning',
            title: message,
        });
    }

    message(message: string) {
        this.toast.fire({
            icon: 'info',
            title: message,
        });
    }

    unauthorized(message: string, buttonText: string) {
        this.store.dispatch(new Logout());

        this.toast.fire({
            icon: 'warning',
            title: message,
        });
    }

    processing(message: string = 'Processing') {
        Swal.fire({
            html: message,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    }

    validationError(message: string) {
        this.toast.fire({
            icon: 'error',
            title: message,
        });
    }

    async delete(title: string, text: string): Promise<boolean> {
        const { isConfirmed } = await Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
            reverseButtons: true,
        });

        return isConfirmed;
    }
}
