// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, tap } from 'rxjs';

//states
import { GetIssues, GetServices, IssueState } from '@data/state';

// enums
import { DataTableActionType, DataTableCellType, IssueDataTableCellType } from '@data/enum';

// schemas
import {
    IFormCustomClass,
    IButtonCustomClass,
    IDataTableInfo,
    IService,
    IIssue,
    IDataTablePaginationDTO,
} from '@data/schema';
import { IssueService } from '@data/service';

@Component({
    selector: 'app-link-issue-modal',
    templateUrl: './link-issue-modal.component.html',
    styleUrls: ['./link-issue-modal.component.scss'],
})
export class LinkIssueModalComponent implements OnInit {
    dataTableSource$: Observable<IIssue[]>;
    isDataLoading$ = new BehaviorSubject<boolean>(false);

    dataTableCols: DataTables.ColumnSettings[] = [
        { data: 'id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
        { data: '_id', title: 'System UUID', visible: false },
        { data: 'issueNumber', title: 'Issue Number', type: DataTableCellType.LINK_CELL },
        {
            data: 'itemCompany.companyName',
            title: 'Item Company ',
            type: IssueDataTableCellType.ITEM_COMPANY_NAME_CELL,
        },
        { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
        { data: 'reportedDate', title: 'Reported Date', type: DataTableCellType.DATE_CELL },
        { data: 'resolvedDate', title: 'Resolved Date', type: DataTableCellType.DATE_CELL },
    ];

    dataTableLengthMenu = [
        [5, 10, 20, 50, 100],
        ['5 / page', '10 / page', '20 / page', '50 / page', '100 / page'],
    ];

    dataTableForm: FormGroup;

    dataTableCellType = DataTableCellType;
    dataTableActionType = DataTableActionType;

    isFormProcessing = false;

    dataTableInfo: IDataTableInfo = {
        buttonTitle: 'Create New',
        infoLabel: 'items',
    };

    checkboxCustomClasses: IFormCustomClass = {
        formGroup: 'form-group m-0',
        label: 'font-weight-bold text-muted small mb-0',
        input: '',
    };

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    @Output() actionButtonEvent = new EventEmitter();

    constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private issueService: IssueService) {
        this.initializeForm();
    }

    ngOnInit() {}

    dataTableServerSideCallbackFn = (pagination: IDataTablePaginationDTO) => {
        this.isDataLoading$.next(true);
        return this.issueService.getSSR(pagination).pipe(
            tap((response) => {
                this.addFormControls(response.data);
                this.isDataLoading$.next(false);
                this.isDataLoading$.complete();
            }),
        );
    };

    private initializeForm(): void {
        this.dataTableForm = this.formBuilder.group({
            items: this.formBuilder.array([]),
        });
    }

    private addFormControls(items: IIssue[]): void {
        this.p.clear();

        items.forEach((item) => {
            this.p.push(this.createItemField(item));
        });
    }

    private createItemField(item: IIssue): FormGroup {
        const itemField = this.formBuilder.group({
            _id: item._id,
            isSelected: false,
        });

        return itemField;
    }

    get f() {
        return this.dataTableForm.controls;
    }

    get p() {
        return this.f['items'] as FormArray;
    }

    onSelectAllCheckBoxValueChanges(flag: boolean) {
        const { items } = this.dataTableForm.getRawValue();

        items.forEach((formField, i) => {
            if (flag && !this.p.controls[i].get('isSelected').disabled) {
                this.p.controls[i].patchValue({ isSelected: true });
            } else {
                this.p.controls[i].patchValue({ isSelected: false });
            }
        });
    }

    onActionButtonClick($event) {
        const { items } = this.dataTableForm.getRawValue();
        const selectedItems = items.filter((item) => item.isSelected).map((item) => item._id);
        this.actionButtonEvent.emit({
            selectedIds: selectedItems,
            ...$event,
        });
    }
}
