// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateIssue,
    DeleteIssue,
    GetIssues,
    GetIssue,
    UpdateIssue,
    DeleteIssues,
    GetLinkCountsForIssue,
    GetLinkedDataForIssue,
    CreateNoteForIssue,
    GetNotesForIssue,
    GetTableLayoutForIssue,
    UpdateTableLayoutForIssue,
    LinkNUnlinkContractsInIssues,
    CreateLinkedItemForIssue,
    LinkNUnlinkServicesInIssues,
    LinkNUnlinkItemsInIssues,
    ResetIssue,
    ResetNotesIssue,
} from './issue.actions';

// services
import {
    ContractService,
    HistoryService,
    IssueService,
    ItemService,
    LinkContractIssuesService,
    LinkServiceIssuesService,
    LinkItemIssuesService,
    ServiceService,
    TableLayoutService,
} from '@data/service';

// schemas
import { IssueStateModel } from './issue-state.model';
import { IContract, IIssue, ILinkModel, IService, IItem } from '@data/schema';
import { LinkedItemsStateModel } from '../linked-items/linked-items-state.model';

// enums
import { DataTableCellType, IssueDataTableCellType, LinkedEntityType } from '@data/enum';

// states
import { ResetService, ResetContract, ResetItem } from '@data/state';

@State<IssueStateModel>({
    name: 'issue',
    defaults: {
        items: {
            data: [],
            loading: true,
            tableLayout: {
                data: {
                    layout: [
                        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                        { data: '_id', title: 'System UUID', visible: false },
                        { data: 'issueNumber', title: 'Issue Number', type: IssueDataTableCellType.ISSUE_NUMBER_CELL },
                        {
                            data: 'itemCompany.companyName',
                            title: 'Item Company ',
                            type: IssueDataTableCellType.ITEM_COMPANY_NAME_CELL,
                        },
                        {
                            data: 'contractCompany.companyName',
                            title: 'Contract Company ',
                            type: IssueDataTableCellType.CONTRACT_COMPANY_NAME_CELL,
                        },
                        { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                        { data: 'reportedDate', title: 'Reported Date', type: DataTableCellType.DATE_CELL },
                        { data: 'resolvedDate', title: 'Resolved Date', type: DataTableCellType.DATE_CELL },
                        { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
                    ],
                },
                isDefault: true,
            },
        },
        linkCounts: null,
        linkedItems: null,
    },
})
@Injectable()
export class IssueState {
    constructor(
        private store: Store,
        private issueService: IssueService,
        private contractService: ContractService,
        private serviceService: ServiceService,
        private itemService: ItemService,
        private historyService: HistoryService,
        private tableLayoutService: TableLayoutService,
        private linkContractIssuesService: LinkContractIssuesService,
        private linkServiceIssuesService: LinkServiceIssuesService,
        private linkItemIssuesService: LinkItemIssuesService,
    ) {}

    @Selector()
    static getIssueList(state: IssueStateModel) {
        return state.items.data;
    }

    @Selector()
    static getIssueListLoading(state: IssueStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentIssue(state: IssueStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getTableLayout(state: IssueStateModel) {
        return state.items?.tableLayout?.data?.layout;
    }

    @Selector()
    static getLinkCounts(state: IssueStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getLinkedData(state: IssueStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.data;
        };
    }

    @Selector()
    static getLinkedDataLoading(state: IssueStateModel) {
        return (linkType: LinkedEntityType) => {
            return (state?.linkedItems?.[linkType] as LinkedItemsStateModel<ILinkModel>)?.loading;
        };
    }

    @Selector()
    static getNotes(state: IssueStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: IssueStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetIssues)
    getIssues({ getState, patchState }: StateContext<IssueStateModel>) {
        const state = getState();

        if (state?.items?.retrievedAt) {
            return state;
        }

        return this.issueService.get().pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetIssue)
    getIssue({ getState, setState, patchState }: StateContext<IssueStateModel>, { id }: GetIssue) {
        this.store.dispatch(new ResetIssue());
        this.resetLinkedData({ patchState });
        const state = getState();

        return this.issueService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    currentItem: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetLinkCountsForIssue)
    GetLinkCountsForIssue({ patchState }: StateContext<IssueStateModel>, { id }: GetLinkCountsForIssue) {
        return this.issueService.getLinkCounts(id).pipe(
            tap((response) => {
                patchState({
                    linkCounts: response,
                });
            }),
        );
    }

    @Action(GetLinkedDataForIssue)
    GetLinkedDataForIssue(
        { getState, patchState }: StateContext<IssueStateModel>,
        { id, serviceType, dataType, itemType }: GetLinkedDataForIssue,
    ) {
        const state = getState();

        if (state?.linkedItems?.[dataType]?.retrievedAt) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                [dataType]: {
                    ...state.linkedItems?.[dataType],
                    loading: true,
                },
            },
        });

        return (
            itemType
                ? this?.[serviceType + 'Service'].getByIssueIdNType(id, itemType)
                : this?.[serviceType + 'Service'].getByIssueId(id)
        ).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        [dataType]: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetNotesForIssue)
    GetNotesForIssue({ getState, patchState }: StateContext<IssueStateModel>, { id, skip, limit }: GetNotesForIssue) {
        const state = getState();

        if ((state?.linkedItems?.notes?.data.length && !skip) || state?.linkCounts.linkedNotesCount <= (skip ?? 0)) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id, skip, limit).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                                ...response,
                            ],
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(GetTableLayoutForIssue)
    GetTableLayoutForIssue({ getState, patchState }: StateContext<IssueStateModel>, { page }: GetTableLayoutForIssue) {
        const state = getState();

        if (!state?.items?.tableLayout) {
            patchState({
                items: {
                    ...state.items,
                    tableLayout: {
                        data: {
                            layout: [
                                { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
                                { data: '_id', title: 'System UUID', visible: false },
                                {
                                    data: 'issueNumber',
                                    title: 'Issue Number',
                                    type: IssueDataTableCellType.ISSUE_NUMBER_CELL,
                                },
                                {
                                    data: 'itemCompany.companyName',
                                    title: 'Item Company ',
                                    type: IssueDataTableCellType.ITEM_COMPANY_NAME_CELL,
                                },
                                {
                                    data: 'contractCompany.companyName',
                                    title: 'Contract Company ',
                                    type: IssueDataTableCellType.CONTRACT_COMPANY_NAME_CELL,
                                },
                                { data: 'description', title: 'Description', type: DataTableCellType.DESCRIPTION_CELL },
                                { data: 'reportedDate', title: 'Reported Date', type: DataTableCellType.DATE_CELL },
                                { data: 'resolvedDate', title: 'Resolved Date', type: DataTableCellType.DATE_CELL },
                                { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
                            ],
                        },
                        isDefault: true,
                    },
                },
            });
        }

        if (state?.items?.tableLayout?.isDefault === false) {
            return state;
        }

        return this.tableLayoutService.getByPage(page).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        items: {
                            ...state.items,
                            tableLayout: {
                                data: response,
                                isDefault: false,
                            },
                        },
                    });
                }
            }),
        );
    }

    @Action(CreateNoteForIssue)
    createNoteForIssue({ getState, patchState }: StateContext<IssueStateModel>, { payload }: CreateNoteForIssue) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [
                                response,
                                ...(state?.linkedItems?.notes?.data ? state?.linkedItems?.notes?.data : []),
                            ],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(CreateIssue)
    createIssue({ getState, patchState }: StateContext<IssueStateModel>, { payload }: CreateIssue) {
        const state = getState();

        return this.issueService.create(payload).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(CreateLinkedItemForIssue)
    CreateLinkedItemForIssue(
        { getState, patchState }: StateContext<IssueStateModel>,
        { id, payload, serviceType, dataType, countType }: CreateLinkedItemForIssue,
    ) {
        const state = getState();

        return this?.[serviceType + 'Service'].createWithIssueLink(id, payload).pipe(
            tap((response: any) => {
                patchState({
                    linkCounts: {
                        ...state.linkCounts,
                        [countType]: state.linkCounts?.[countType] + 1,
                    },
                });
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkItemsInIssues)
    LinkNUnlinkItems(
        { getState, patchState }: StateContext<IssueStateModel>,
        { payload, isLink }: LinkNUnlinkItemsInIssues,
    ) {
        const state = getState();

        return this.linkItemIssuesService?.[isLink ? 'linkItemsToIssue' : 'unlinkItemsFromIssue'](payload).pipe(
            tap((response: IItem[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedItemCount: state.linkCounts?.linkedItemCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedItemCount: state.linkCounts.linkedItemCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkContractsInIssues)
    LinkNUnlinkContracts(
        { getState, patchState }: StateContext<IssueStateModel>,
        { payload, isLink }: LinkNUnlinkContractsInIssues,
    ) {
        const state = getState();

        return this.linkContractIssuesService?.[isLink ? 'linkContractsToIssue' : 'unlinkContractsFromIssue'](
            payload,
        ).pipe(
            tap((response: IContract[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedContractCount: state.linkCounts?.linkedContractCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedContractCount: state.linkCounts.linkedContractCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(LinkNUnlinkServicesInIssues)
    LinkNUnlinkServices(
        { getState, patchState }: StateContext<IssueStateModel>,
        { payload, isLink }: LinkNUnlinkServicesInIssues,
    ) {
        const state = getState();

        return this.linkServiceIssuesService?.[isLink ? 'linkServicesToIssue' : 'unlinkServicesFromIssue'](
            payload,
        ).pipe(
            tap((response: IService[]) => {
                if (response.length < 0) {
                    return;
                }
                if (isLink) {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedServiceCount: state.linkCounts?.linkedServiceCount + response.length,
                        },
                    });
                } else {
                    patchState({
                        linkCounts: {
                            ...state.linkCounts,
                            linkedServiceCount: state.linkCounts.linkedServiceCount - response.length,
                        },
                    });
                }
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    @Action(UpdateIssue)
    updateIssue({ getState, setState, patchState }: StateContext<IssueStateModel>, { id, payload }: UpdateIssue) {
        return this.issueService.edit(id, payload).pipe(
            tap((response) => {
                const state = getState();
                const currentList = [...state.items.data];
                const index = currentList.findIndex((item) => item._id === id);
                currentList[index] = response;

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: currentList,
                    },
                });

                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                });

                this.store.dispatch(new ResetNotesIssue());
            }),
        );
    }

    @Action(UpdateTableLayoutForIssue)
    updateTableLayoutForContact(
        { getState, patchState }: StateContext<IssueStateModel>,
        { payload, page }: UpdateTableLayoutForIssue,
    ) {
        const state = getState();

        return this.tableLayoutService.update(payload, page).pipe(
            tap((response) => {
                patchState({
                    items: {
                        ...state.items,
                        tableLayout: {
                            ...state.items?.tableLayout,
                            data: response,
                            isDefault: false,
                        },
                    },
                });
            }),
        );
    }

    @Action(DeleteIssue)
    deleteIssue({ getState, setState }: StateContext<IssueStateModel>, { id }: DeleteIssue) {
        return this.issueService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);

                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(DeleteIssues)
    deleteIssues({ getState, setState, patchState }: StateContext<IssueStateModel>, { ids }: DeleteIssues) {
        return this.issueService.deleteBatch(ids);
    }

    @Action(ResetIssue)
    resetIssue({ getState, patchState }: StateContext<IssueStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
            currentItem: {
                ...state.currentItem,
                data: null,
                loading: true,
                retrievedAt: null,
            },
        });
    }

    @Action(ResetNotesIssue)
    resetNotes({ getState, patchState }: StateContext<IssueStateModel>) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    data: [],
                    loading: true,
                },
            },
        });
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
            linkedItems: null,
        });
    }
}
