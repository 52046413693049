// core
import { ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

export class CustomValidators {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);

            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }

    static passwordMatchValidator() {
        return (control: AbstractControl) => {
            // get password from our password form control
            const password: string = control.get('password').value;

            // get password from our confirmPassword form control
            const confirmPassword: string = control.get('confirmPassword').value;

            // compare is the password math
            if (password !== confirmPassword) {
                // if they don't match, set an error in our confirmPassword form control
                control.get('confirmPassword').setErrors({ NoPasswordMatch: true });

                return { NoPasswordMatch: true };
            }
            return null;
        };
    }

    static referenceMatchValidator(firstControl, secondControl, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const firstRef = control.get(firstControl).value;
            const secondRef = control.get(secondControl).value;

            let references = [];

            if (!Array.isArray(firstRef)) {
                references.push(firstRef);
            } else {
                references = firstRef;
            }

            const matchingReference = references.find((ref) => ref === secondRef);

            if (matchingReference) {
                return error;
            }

            return null;
        };
    }

    static multiOptionalRequiredValidator(controllers: string[], error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let isFilledAtLeastOne = false;

            controllers.forEach((cnt) => {
                if (control.get(cnt).value) {
                    isFilledAtLeastOne = true;
                }
            });

            if (!isFilledAtLeastOne) {
                return error;
            }

            return null;
        };
    }
}
