// core
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// guards
import { AuthGuard } from '@app/guard/auth.guard';
import { RoleGuard } from './guard/role.guard';

// interceptors
import { BaseHttpInterceptor } from '@app/interceptor/base-http.interceptor';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        AuthGuard,
        RoleGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseHttpInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {}
