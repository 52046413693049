// core
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';

// actions
import {
    CreateNoteForUpload,
    DeleteUpload,
    GetUploads,
    GetNotesForUpload,
    CreateUpload,
    ResetUploads,
} from './upload.actions';

// services
import { FileService, HistoryService } from '@data/service';

// schemas
import { UploadStateModel } from './upload-state.model';

@State<UploadStateModel>({
    name: 'uploads',
    defaults: {
        items: {
            data: [],
            loading: true,
        },
        linkCounts: null,
    },
})
@Injectable()
export class UploadState {
    constructor(private uploadService: FileService, private store: Store, private historyService: HistoryService) {}
    @Selector()
    static getUploadList(state: UploadStateModel) {
        return state.items.data;
    }

    @Selector()
    static getUploadListLoading(state: UploadStateModel) {
        return state.items.loading;
    }

    @Selector()
    static getCurrentUpload(state: UploadStateModel) {
        return state.currentItem?.data;
    }

    @Selector()
    static getLinkCounts(state: UploadStateModel) {
        return state.linkCounts;
    }

    @Selector()
    static getNotes(state: UploadStateModel) {
        return state.linkedItems?.notes.data;
    }

    @Selector()
    static getNotesLoading(state: UploadStateModel) {
        return state.linkedItems?.notes.loading;
    }

    @Action(GetUploads)
    getUploads({ getState, setState, patchState }: StateContext<UploadStateModel>, { id }: GetUploads) {
        this.store.dispatch(new ResetUploads());
        const state = getState();
        // const currentItem = state.items?.data?.find((item) => item?._id === id);

        // if (currentItem) {
        //     return setState({
        //         ...state,
        //         currentItem: {
        //             data: currentItem,
        //             loading: false,
        //             retrievedAt: Date.now(),
        //         },
        //     });
        // }

        return this.uploadService.getById(id).pipe(
            tap((response) => {
                setState({
                    ...state,
                    items: {
                        data: response,
                        loading: false,
                        retrievedAt: Date.now(),
                    },
                });
            }),
        );
    }

    @Action(GetNotesForUpload)
    GetNotesForUpload({ getState, patchState }: StateContext<UploadStateModel>, { id }: GetNotesForUpload) {
        const state = getState();

        if (state?.linkedItems?.notes?.retrievedAt) {
            return state;
        }

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.getByEntityId(id).pipe(
            tap((response) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            data: response,
                            loading: false,
                            retrievedAt: Date.now(),
                        },
                    },
                });
            }),
        );
    }

    @Action(CreateNoteForUpload)
    createNoteForUpload({ getState, patchState }: StateContext<UploadStateModel>, { payload }: CreateNoteForUpload) {
        const state = getState();

        patchState({
            linkedItems: {
                ...state.linkedItems,
                notes: {
                    ...state.linkedItems?.notes,
                    loading: true,
                },
            },
        });

        return this.historyService.create(payload).pipe(
            tap((response) => {
                patchState({
                    linkCounts: {
                        linkedNotesCount: state.linkCounts.linkedNotesCount + 1,
                    },
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            data: [response, ...state.linkedItems?.notes.data],
                            loading: false,
                        },
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    linkedItems: {
                        ...state.linkedItems,
                        notes: {
                            ...state.linkedItems?.notes,
                            loading: false,
                        },
                    },
                });
                throw error;
            }),
        );
    }

    @Action(DeleteUpload)
    deleteUpload({ getState, setState }: StateContext<UploadStateModel>, { id }: DeleteUpload) {
        return this.uploadService.delete(id).pipe(
            tap(() => {
                const state = getState();
                const filteredList = state.items.data.filter((item) => item._id !== id);
                setState({
                    ...state,
                    items: {
                        ...state.items,
                        data: filteredList,
                    },
                });
            }),
        );
    }

    @Action(CreateUpload)
    createItem({ getState, patchState }: StateContext<UploadStateModel>, { entity, id, file }: CreateUpload) {
        let state = getState();

        patchState({
            items: {
                ...state?.items,
                loading: true,
            },
        });
        return this.uploadService.create(entity, id, file).pipe(
            tap((response) => {
                state = getState();
                patchState({
                    items: {
                        ...state.items,
                        data: [...state.items.data, response],
                        loading: false,
                    },
                });
            }),
            catchError((error) => {
                patchState({
                    items: {
                        ...state.items,
                        loading: false,
                    },
                });
                throw error;
            }),
        );
    }

    private resetLinkedData({ patchState }) {
        patchState({
            linkCounts: null,
        });
    }

    @Action(ResetUploads)
    resetUploads({ getState, patchState }: StateContext<UploadStateModel>) {
        const state = getState();

        patchState({
            items: {
                ...state.items,
                data: [],
                loading: true,
                retrievedAt: null,
            },
        });
    }
}
