// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IContract,
    IIssue,
    IItem,
    ILinkContractsToIssue,
    ILinkContractsToService,
    ILinkIssuesToContract,
    ILinkIssuesToItem,
    ILinkItemsToIssue,
    ILinkItemsToService,
    ILinkServicesToContract,
    ILinkServicesToItem,
    IService,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class LinkItemServicesService {
    constructor(private apiService: ApiService) {}

    linkServicesToItem(context: ILinkServicesToItem): Observable<IService[]> {
        return this.apiService
            .post<{ data: IService[] }, ILinkServicesToItem>('/linkServiceItems/link/services', context)
            .pipe(map((res) => res.data));
    }

    linkItemsToService(context: ILinkItemsToService): Observable<IItem[]> {
        return this.apiService
            .post<{ data: IItem[] }, ILinkItemsToService>('/linkServiceItems/link/items', context)
            .pipe(map((res) => res.data));
    }

    unlinkServicesFromItem(context: ILinkServicesToItem): Observable<IService[]> {
        return this.apiService
            .deleteBatch<{ data: IService[] }, ILinkServicesToItem>('/linkServiceItems/unlink/services', context)
            .pipe(map((res) => res.data));
    }

    unlinkItemsFromService(context: ILinkItemsToService): Observable<IItem[]> {
        return this.apiService
            .deleteBatch<{ data: IItem[] }, ILinkItemsToService>('/linkServiceItems/unlink/items', context)
            .pipe(map((res) => res.data));
    }
}
