// core
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
    catchError,
    concat,
    debounceTime,
    distinctUntilChanged,
    filter,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

// states
import { AuthState, GetProfileImage, Logout, ProfileState } from '@data/state';

// service
import { LayoutService } from '@app/service';

// schemas
import { IUser } from '@data/schema';
import { UserRole } from '@data/enum';

@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit {
    @Select(AuthState.currentUser) currentUser$: Observable<IUser>;
    @Select(ProfileState.getProfileImage) avatarUrl$: Observable<any>;
    @Select(AuthState.userRole) userRole$: Observable<string>;
    @Select(AuthState.tenantId) tenantId$: Observable<string>;
    @Select(AuthState.tenant) companyName$: Observable<string>;

    isSidebarExpanded = true;
    list$: Observable<any>;
    isSearchDataLoading = false;
    searchInput$ = new Subject<string>();
    minLengthTerm = 0;

    tenantId: string;
    onDestroy$: Subject<void> = new Subject();
    userRoleType = UserRole;

    companyName: string;
    logoUrl: string;

    //TODO: should remove with integration
    searchData = [
        { type: 'Company', name: 'Butzbach Kuwait' },
        { type: 'Company', name: 'Bluetech Dryers' },
        { type: 'Company', name: 'Kaeser Maintenance Kuwait' },
        { type: 'Company', name: 'HA2 Engineering' },
        { type: 'Company', name: 'HA Brothers' },
        { type: 'Company', name: 'BMC - Parts Department' },
        { type: 'Company', name: 'GREENTECH DRYERS' },
        { type: 'Contact', name: 'Harry Potter' },
        { type: 'Contact', name: 'Ron Weasley' },
        { type: 'Contact', name: 'Hermione Granger' },
        { type: 'Contact', name: 'Albus Dumbledore' },
    ];

    constructor(private store: Store, public layoutService: LayoutService) {}

    ngOnInit() {
        this.loadItems();
        this.store.dispatch(new GetProfileImage());
        this.tenantId = this.store.selectSnapshot(AuthState.tenantId);
        this.companyName$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => ((this.companyName = res?.['companyName']), (this.logoUrl = res?.['url'])));
    }

    loadItems() {
        this.list$ = concat(
            of([]),
            this.searchInput$.pipe(
                filter((res) => {
                    return res !== null && res.length >= this.minLengthTerm;
                }),
                distinctUntilChanged(),
                debounceTime(100),
                tap(() => (this.isSearchDataLoading = true)),
                switchMap((term) => {
                    return this.getItems(term).pipe(
                        catchError(() => of([])),
                        tap(() => (this.isSearchDataLoading = false)),
                    );
                }),
            ),
        );
    }

    //TODO: should change with integration
    getItems(term: string = null): Observable<any> {
        let data = this.searchData.filter((x) => {
            return x.name.toLowerCase().startsWith(term.toLowerCase());
        });
        return of(data);
    }

    onNavToggleClick() {
        this.layoutService.toggleSidebarExpandedState(!this.layoutService.isSidebarExpanded);
    }

    onOverlayClose() {
        this.layoutService.toggleSidebarExpandedState(!this.layoutService.isSidebarExpanded);
    }

    onSearchBoxClear() {
        this.searchInput$.next(' ');
    }

    separateRole(role: string): string {
        if (role) {
            const separatedRole = role.replace(/([a-z])([A-Z])/g, '$1 $2');
            return separatedRole;
        }
        return '';
    }

    logout() {
        const currentUser = this.store.selectSnapshot(AuthState.currentUser);
        console.log(currentUser);
        this.store.dispatch(new Logout(currentUser.user._id));
    }
}
