import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-sign-pad',
    templateUrl: './sign-pad.component.html',
    styleUrls: ['./sign-pad.component.scss'],
})
export class SignPadComponent implements OnInit {
    @Output() actionButtonEvent = new EventEmitter();

    @ViewChild('signature')
    public signaturePad: SignaturePadComponent;

    public signaturePadOptions: NgSignaturePadOptions = {
        // passed through to szimek/signature_pad constructor
        minWidth: 5,
        canvasWidth: 500,
        canvasHeight: 500,
    };

    constructor() {}

    ngOnInit() {}

    // ngAfterViewInit() {
    //     // this.signaturePad is now available
    //     this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    //     this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    // }

    drawComplete(event: MouseEvent | Touch) {
        // will be notified of szimek/signature_pad's onEnd event
        console.log('Completed drawing', event);
        console.log(this.signaturePad.toDataURL());
    }

    drawStart(event: MouseEvent | Touch) {
        // will be notified of szimek/signature_pad's onBegin event
        console.log('Start drawing', event);
    }

    clear() {
        this.signaturePad.clear();
    }

    submit() {
        this.actionButtonEvent.emit(this.signaturePad.toDataURL());
    }
}
