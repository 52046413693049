<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Apply Filters</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="service_modal_form_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-12">
                    <!-- form-input: provider -->
                    <app-select-input
                        [formControl]="f['provider']"
                        [label]="'Provider'"
                        [list]="companies$ | async"
                        [bindField]="'companyName'"
                        [labelField]="'companyName'"
                        [multiple]="true"
                        testAutomationSelectorName="filter_layout_modal_select_provider"
                    ></app-select-input>
                    <!-- //. form-input: provider -->
                </div>
                <div class="col-12">
                    <!-- form-input: recipient -->
                    <app-select-input
                        [formControl]="f['recipient']"
                        [label]="'Recipient'"
                        [list]="companies$ | async"
                        [bindField]="'companyName'"
                        [labelField]="'companyName'"
                        [multiple]="true"
                        testAutomationSelectorName="filter_layout_modal_select_provider"
                    ></app-select-input>
                    <!-- //. form-input: recipient -->
                </div>
                <div class="col-12">
                    <!-- form-input: service-status -->
                    <app-select-input
                        [formControl]="f['serviceStatus']"
                        [label]="'Service Status'"
                        [list]="serviceStatus"
                        [bindField]="'value'"
                        [labelField]="'name'"
                        [multiple]="true"
                        testAutomationSelectorName="filter_layout_modal_select_provider"
                    ></app-select-input>
                    <!-- //. form-input: service-status -->
                </div>
                <div class="col-12">
                    <!-- form-input: contract-status -->
                    <app-select-input
                        [formControl]="f['contractStatus']"
                        [label]="'Contract Status'"
                        [list]="contractStatus"
                        [bindField]="'value'"
                        [labelField]="'name'"
                        [multiple]="true"
                        testAutomationSelectorName="filter_layout_modal_select_provider"
                    ></app-select-input>
                    <!-- //. form-input: contract-status -->
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Apply"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="apply()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
                <!-- form-button: reset-default -->
                <button class="btn btn-outline-primary btn-sm me-3" (click)="resetToDefault()">
                    <i class="bi bi-bootstrap-reboot"></i>&nbsp;&nbsp; Reset to Default
                </button>
                <!-- //. form-button: reset-default -->
            </div>
        </div>
    </div>
</div>
