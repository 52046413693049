<accordion-group
    #element
    class="accordion-item shadow-sm my-4 border-5 border-start"
    [isOpen]="isOpen"
    [ngClass]="borderClass"
    (isOpenChange)="onChange($event)"
>
    <div accordion-heading>
        <h6 class="mb-0">
            {{ title }}
            <span class="badge rounded-pill bg-danger fw-medium fs-10 align-middle">{{ itemCount }}</span>
            <span class="float-end">
                <i
                    class="toggle-icon bi"
                    [ngClass]="{ 'bi-chevron-up': element.isOpen, 'bi-chevron-down': !element.isOpen }"
                ></i>
            </span>
        </h6>
    </div>

    <div class="border-1 border-top p-3">
        <form
            [formGroup]="form"
            *ngIf="
                userRole !== userRoleType.APPLICATION_VIEWER &&
                userRole !== userRoleType.TENANT_VIEWER &&
                userRole !== userRoleType.COMPANY_APPLICATION_ADMIN &&
                userRole !== userRoleType.COMPANY_APPLICATION_VIEWER &&
                userRole !== userRoleType.COMPANY_TENANT_VIEWER
            "
        >
            <div class="row" *ngIf="currentUser$ | async as currentUser">
                <div class="col-12 d-flex flex-row">
                    <ngx-avatars
                        [name]="currentUser?.user?.firstName + ' ' + currentUser?.user?.lastName"
                        [initialsSize]="2"
                        src="{{ avatarUrl$ | async }}"
                        size="40"
                        class="me-3 mt-4"
                    ></ngx-avatars>
                    <!-- input: new note -->
                    <app-text-area-input
                        [formControl]="f['note']"
                        [label]="''"
                        placeholder="Add note..."
                        maxLength="250"
                        testAutomationSelectorName="contract_details_input_description"
                        class="w-100"
                    ></app-text-area-input>
                    <!-- //. input: new note -->
                </div>
                <div class="col-12">
                    <app-spinner-button
                        buttonText="Add Note"
                        [isProcessing]="isFormProcessing"
                        [customClasses]="buttonCustomClasses"
                        (onButtonClick)="add()"
                    ></app-spinner-button>
                </div>
            </div>
        </form>
        <div *ngIf="data$ | async as notes">
            <div class="row mt-4" *ngFor="let note of notes">
                <div class="col-12 d-flex flex-row">
                    <ngx-avatars
                        [name]="note?.createdBy?.firstName + ' ' + note?.createdBy?.lastName"
                        [initialsSize]="2"
                        [src]="note?.links[0]?.link"
                        size="40"
                        class="me-3"
                    ></ngx-avatars>

                    <div class="border-start border-3 border-secondary ps-2 py-2">
                        <p class="text-secondary fs-10 mb-0">
                            {{ note?.createdBy?.firstName + ' ' + note?.createdBy?.lastName }} @
                            {{ note?.createdDate | date : 'yyyy/MM/dd, hh:mm a' }}
                        </p>
                        <p class="text-secondary mb-0">
                            {{ note?.message }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <app-spinner-button
                        buttonText="show more <i class='bi bi-arrow-down-circle'></i>"
                        [isProcessing]="dataLoading$ | async"
                        [isDisabled]="itemCount <= notes.length"
                        [customClasses]="secondaryButtonCustomClasses"
                        (onButtonClick)="showMoreRecords(notes.length)"
                    ></app-spinner-button>
                </div>
            </div>
        </div>
    </div>
</accordion-group>
