<div class="sidebar">
    <div
        [class.sidebar-collapsed]="!isSidebarExpanded"
        [class.sidebar-expanded]="isSidebarExpanded"
        class="sidebar-container d-none d-lg-block py-3"
        id="sidebar-container"
    >
        <!-- logo -->
        <div
            [ngClass]="{ 'ms-4': isSidebarExpanded, 'justify-content-center': !isSidebarExpanded }"
            class="d-flex mb-4 mt-1"
        >
            <img
                *ngIf="isSidebarExpanded && (tenantId$ | async) === null"
                alt=""
                class="logo-expanded"
                src="/assets/img/common/logo-lg.png"
            />
            <img
                *ngIf="!isSidebarExpanded && (tenantId$ | async) === null"
                alt=""
                class="logo-collapsed"
                src="/assets/img/common/logo-sm.png"
            />
            <div *ngIf="logoUrl">
                <img *ngIf="isSidebarExpanded && (tenantId$ | async)" alt="" class="logo-expanded" [src]="logoUrl" />
                <img *ngIf="!isSidebarExpanded && (tenantId$ | async)" alt="" class="logo-collapsed" [src]="logoUrl" />
            </div>
            <div *ngIf="!logoUrl">
                <span
                    *ngIf="isSidebarExpanded && (tenantId$ | async)"
                    class="logo-expanded text-white fw-medium text-uppercase h6"
                    >{{ companyName }}</span
                >
                <span
                    *ngIf="!isSidebarExpanded && (tenantId$ | async)"
                    class="logo-collapsed text-white fw-medium text-uppercase text-small"
                    >{{ companyName.substring(0, 2) }}</span
                >
            </div>
        </div>
        <!-- //logo -->

        <!-- List Group -->
        <ul class="list-group mt-2">
            <div
                *ngFor="
                    let item of (userRole$ | async) === userRoleType.APPLICATION_VIEWER ||
                    (userRole$ | async) === userRoleType.TENANT_VIEWER
                        ? viewRoutes
                        : (tenantId$ | async) === null
                        ? tenantManageRoutes
                        : adminRoutes
                "
            >
                <a
                    [routerLink]="item.route"
                    class="list-group-item"
                    [ngClass]="{ 'list-group dropdown': item.hasSubMenu }"
                    routerLinkActive="active"
                >
                    <div
                        [ngClass]="{ 'ps-2': isSidebarExpanded, 'justify-content-center': !isSidebarExpanded }"
                        class="d-flex align-self-center"
                    >
                        <i [ngClass]="item.icon" class="bi"></i>
                        <span class="menu-collapsed">{{ item.name }}</span>
                    </div>
                </a>
                <!-- //List Group - subMenu -->
                <div *ngIf="item.hasSubMenu" class="mt-0">
                    <a
                        *ngFor="let items of item.subMenu"
                        class="sub-item"
                        routerLinkActive="active"
                        [routerLink]="items.route"
                    >
                        <div class="box" routerLinkActive="active">
                            <div
                                [ngClass]="{ 'ps-2': isSidebarExpanded, 'justify-content-center': !isSidebarExpanded }"
                                class="d-flex align-self-center items"
                            >
                                <i [ngClass]="items.icon" class="bi"></i>
                                <span class="menu-collapsed">{{ items.name }}</span>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- //List Group - subMenu-->
            </div>
            <!-- //List Group -->
        </ul>
    </div>
</div>
