// core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, skipWhile, Subject, take, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import {
    CompanyState,
    CreateInstallation,
    EquipmentTypeState,
    GetCompanies,
    GetEquipmentTypes,
    GetItems,
    ItemState,
} from '@data/state';

// schemas
import { IButtonCustomClass, ICompany, IEquipmentType, IItem } from '@data/schema';

// services
import { DataTableService, SweetAlertService } from '@app/service';
import { SequenceService } from '@data/service';

// enums
import { ServiceType } from '@data/enum';

@Component({
    selector: 'app-installation-create-modal',
    templateUrl: './installation-create-modal.component.html',
    styleUrls: ['./installation-create-modal.component.scss'],
})
export class InstallationCreateModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany>;
    @Select(ItemState.getItemList) items$: Observable<IItem>;
    @Select(EquipmentTypeState.getEquipmentTypeList) equipmentTypes$: Observable<IEquipmentType[]>;
    equipmentTypesSource$: Observable<IEquipmentType[]>;

    form: FormGroup;
    isFormProcessing = false;
    isNumberGenerating = false;

    installationStatus = [{ name: 'Active' }, { name: 'Inactive' }];

    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    generateButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm w-100 mt-4',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private sequenceService: SequenceService,
        private dataTableService: DataTableService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        this.store.dispatch([new GetCompanies(), new GetItems(), new GetEquipmentTypes()]);

        this.equipmentTypesSource$ = this.equipmentTypes$.pipe(
            skipWhile((items) => !items || items.length === 0),
            take(1),
        );
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            installationNumber: [null, Validators.required],
            itemType: [{ value: null, disabled: true }],
            item: [null],
            itemManufacturer: [{ value: null, disabled: true }],
            itemSupplier: [{ value: null, disabled: true }],
            itemPurchaser: [{ value: null, disabled: true }],
            startDate: [null],
            endDate: [null],
        });
    }

    get f() {
        return this.form.controls;
    }

    create() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { id, ...rest } = this.form.getRawValue();

        this.isFormProcessing = true;

        this.store
            .dispatch(
                new CreateInstallation({
                    ...rest,
                }),
            )
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.isFormProcessing = false;
                    this.sweetAlertService.success('Successfully create the record');
                    this.bsModalRef.hide();
                    this.dataTableService.dataTableRerenderer.next(true);
                },
                error: () => {
                    this.isFormProcessing = false;
                },
            });
    }

    reset() {
        this.form.reset();
    }

    async generate() {
        this.isNumberGenerating = true;

        try {
            const response = await firstValueFrom(this.sequenceService.get(ServiceType.INSTALLATION));
            this.form.patchValue({
                installationNumber: response.nextNumber,
            });
        } catch (error) {
            console.error(error);
        }

        this.isNumberGenerating = false;
    }

    onItemChange($event) {
        this.form.patchValue({
            itemType: $event?.type?._id,
            itemManufacturer: $event?.manufacturer?._id,
            itemSupplier: $event?.supplier?._id,
            itemPurchaser: $event?.purchaser?._id,
        });
    }
}
