// core
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

// services
import { ApiService } from '@app/service';

// schemas
import {
    IInstallation,
    ICreateInstallationDTO,
    ILinkCounts,
    IDataTablePaginationDTO,
    IDataTablePaginationResponse,
} from '@data/schema';

@Injectable({
    providedIn: 'root',
})
export class InstallationService {
    constructor(private apiService: ApiService) {}

    get(): Observable<IInstallation[]> {
        return this.apiService.get<{ data: IInstallation[] }>('/installations').pipe(map((res) => res.data));
    }

    getSSR(context: IDataTablePaginationDTO): Observable<IDataTablePaginationResponse<IInstallation>> {
        let params = new HttpParams();
        params = params.append('limit', `${context.limit}`);
        params = params.append('skip', `${context.skip}`);
        params = params.append('sort', `${context.order}`);
        params = params.append('search', `${context.search}`);
        return this.apiService.get<{ data: IInstallation[]; totalItems: number }>('/installations', params).pipe(
            map((res) => ({
                data: !res.data[0]?.['data'].length ? [] : res.data[0]?.['data'],
                totalItems: res.data[0]?.['data'].length,
                totalFilteredItems: !res.data[0]?.['data'].length ? 0 : res.data[0]?.['total'],
            })),
        );
    }

    getById(id: string): Observable<IInstallation> {
        return this.apiService.get<{ data: IInstallation }>(`/installations/${id}`).pipe(map((res) => res.data));
    }

    create(context: ICreateInstallationDTO): Observable<IInstallation> {
        return this.apiService
            .post<{ data: IInstallation }, ICreateInstallationDTO>(`/installations`, context)
            .pipe(map((res) => res.data));
    }

    edit(id: string, context: ICreateInstallationDTO): Observable<IInstallation> {
        return this.apiService
            .put<{ data: IInstallation }, ICreateInstallationDTO>(`/installations/${id}`, context)
            .pipe(map((res) => res.data));
    }

    delete(id: string) {
        return this.apiService.delete<{ data: IInstallation }>(`/installations/${id}`);
    }

    deleteBatch(ids: string[]) {
        return this.apiService.deleteBatch<{ data: IInstallation[] }, { ids: string[] }>(`/installations`, { ids });
    }

    getLinkCounts(id: string): Observable<ILinkCounts> {
        return this.apiService
            .get<{ data: ILinkCounts }>(`/installations/${id}/linkCounts`)
            .pipe(map((res) => res.data));
    }
}
