// core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { getNames } from 'country-list';
import { BsModalRef } from 'ngx-bootstrap/modal';

// states
import {
    AuthState,
    CompanyState,
    CreateCompany,
    CreateLinkedItemForCompany,
    GetAllIsTenantCompanies,
    GetCompanies,
} from '@data/state';

// services
import { DataTableService, SweetAlertService } from '@app/service';

// schemas
import { IButtonCustomClass, ICompany } from '@data/schema';

// enums
import { LinkedCountType, LinkedEntityType, ServiceType } from '@data/enum';

@Component({
    selector: 'app-company-create-modal',
    templateUrl: './company-create-modal.component.html',
    styleUrls: ['./company-create-modal.component.scss'],
})
export class CompanyCreateModalComponent implements OnInit {
    @Select(CompanyState.getCompanyList) companies$: Observable<ICompany>;

    tenantId = this.store.selectSnapshot(AuthState.tenantId);

    serviceType: ServiceType;
    linkedItemId: string;
    parentDisabled: boolean;

    selectedFile: File;

    form: FormGroup;
    countries: string[] = getNames();
    isFormProcessing = false;

    onDestroy$: Subject<void> = new Subject();

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    secondaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-outline-primary btn-sm float-end me-3',
        spinner: 'spinner-border spinner-border-sm',
    };

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private store: Store,
        private sweetAlertService: SweetAlertService,
        private dataTableService: DataTableService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        if (this.tenantId == null) {
            this.store.dispatch(new GetAllIsTenantCompanies());
        } else {
            this.store.dispatch(new GetCompanies());
        }
        this.form.controls['parent'].setValue(this.linkedItemId);
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            companyName: [null, Validators.required],
            address1: [null],
            address2: [null],
            cityOrTown: [null],
            stateOrRegion: [null],
            country: [null],
            parent: [null],
            isSupplier: [false],
            isManufacturer: [false],
            files: [null],
            isTenant: [this.tenantId ? false : true],
        });
    }

    get f() {
        return this.form.controls;
    }

    onFileSelected(event): void {
        this.selectedFile = event.target.files[0];
    }

    create() {
        this.form.markAllAsTouched();
        if (!this.form.valid || this.isFormProcessing) {
            return;
        }

        const { id, ...rest } = this.form.getRawValue();

        this.isFormProcessing = true;
        if (!this.serviceType) {
            this.store
                .dispatch(
                    new CreateCompany(
                        {
                            ...rest,
                        },
                        this.selectedFile,
                    ),
                )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        this.isFormProcessing = false;
                        this.sweetAlertService.success('Company created successfully');
                        this.bsModalRef.hide();
                        this.dataTableService.dataTableRerenderer.next(true);
                    },
                    error: () => {
                        this.isFormProcessing = false;
                    },
                });
        } else {
            switch (this.serviceType) {
                case ServiceType.COMPANY:
                    this.store
                        .dispatch(
                            new CreateLinkedItemForCompany(
                                this.linkedItemId,
                                rest,
                                ServiceType.COMPANY,
                                LinkedEntityType.COMPANIES,
                                LinkedCountType.LINKED_COMPANY_COUNT,
                            ),
                        )
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: () => {
                                this.isFormProcessing = false;
                                this.sweetAlertService.success('Successfully create the record');
                                this.bsModalRef.hide();
                                this.dataTableService.dataTableRerenderer.next(true);
                            },
                            error: () => {
                                this.isFormProcessing = false;
                            },
                        });
                    break;
            }
        }
    }

    reset() {
        this.form.reset();
    }
}
