// core
import { NgModule } from '@angular/core';

// 3rd party
// --ngxs
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// env
import { environment } from '@env';

// states
import {
    AuthState,
    CompanyState,
    ContractState,
    ContactState,
    ContractTypeState,
    ItemState,
    EquipmentTypeState,
    EquipmentCategoryState,
    InstallationState,
    ServiceState,
    IssueTypeState,
    IssueState,
    DashboardState,
    UploadState,
    CheckListState,
    ProfileState,
    LocationState,
} from './state';

// meta-reducers
import { logoutPlugin } from './state/plugin/state-reset.plugin';

// utils
import { isTokenExpired } from '@shared/util';

@NgModule({
    declarations: [],
    imports: [
        NgxsModule.forRoot(
            [
                AuthState,
                CompanyState,
                ContactState,
                ContractState,
                ContractTypeState,
                ItemState,
                EquipmentTypeState,
                EquipmentCategoryState,
                InstallationState,
                ServiceState,
                IssueState,
                IssueTypeState,
                DashboardState,
                UploadState,
                CheckListState,
                ProfileState,
                LocationState,
            ],
            {
                developmentMode: !environment.production,
            },
        ),
        NgxsStoragePluginModule.forRoot({
            key: AuthState,
            afterDeserialize: (obj, key) => {
                if (key === 'auth' && obj?.token && isTokenExpired(obj?.token)) {
                    return obj;
                }
                return obj;
            },
        }),
        NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    ],
    exports: [],
    providers: [
        {
            provide: NGXS_PLUGINS,
            useValue: logoutPlugin,
            multi: true,
        },
        {
            provide: 'sss',
            useValue: logoutPlugin,
            multi: true,
        },
    ],
})
export class DataModule {}
