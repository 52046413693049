// core
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guards
import { AuthGuard } from '@app/guard/auth.guard';
import { RoleGuard } from '@app/guard/role.guard';

// layouts
import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from '@layout/content-layout/content-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        loadChildren: () => import('./module/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '',
        component: ContentLayoutComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./module/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./module/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'companies',
                loadChildren: () => import('./module/company/company.module').then((m) => m.CompanyModule),
            },
            {
                path: 'contacts',
                loadChildren: () => import('./module/contact/contact.module').then((m) => m.ContactModule),
            },
            {
                path: 'contracts',
                loadChildren: () => import('./module/contract/contract.module').then((m) => m.ContractModule),
            },
            {
                path: 'installation-records',
                loadChildren: () =>
                    import('./module/installation/installation.module').then((m) => m.InstallationModule),
            },
            {
                path: 'items',
                loadChildren: () => import('./module/item/item.module').then((m) => m.ItemModule),
            },
            {
                path: 'service-records',
                loadChildren: () => import('./module/service/service.module').then((m) => m.ServiceModule),
            },
            {
                path: 'issue-records',
                loadChildren: () => import('./module/issue/issue.module').then((m) => m.IssueModule),
            },
            {
                path: 'settings/manage-list-values',
                loadChildren: () => import('./module/list-value/list-value.module').then((m) => m.ListValueModule),
            },
            {
                path: 'settings/check-list',
                loadChildren: () => import('./module/check-list/check-list.module').then((m) => m.CheckListModule),
            },
            {
                path: 'manage/tenant/company',
                loadChildren: () =>
                    import('./module/tenant-company/tenant-company.module').then((m) => m.TenantCompanyModule),
            },
            {
                path: 'manage/tenant/contact',
                loadChildren: () =>
                    import('./module/tenant-contact/tenant-contact.module').then((m) => m.TenantContactModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
