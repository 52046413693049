<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Create New Contract</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>
<div class="modal-body">
    <ng-scrollbar class="modal-form">
        <form [formGroup]="form" data-qa="contract_modal_form_default">
            <!--  row: form -->
            <div class="row mx-2">
                <div class="col-8">
                    <!-- form-input: contractNumber -->
                    <app-text-input
                        [formControl]="f['contractNumber']"
                        [label]="'Contract#'"
                        testAutomationSelectorName="contract_modal_input_contract_number"
                    ></app-text-input>
                    <!-- //. form-input: contractNumber -->
                </div>
                <div class="col-4 ps-0 pt-1">
                    <!-- form-button: generate -->
                    <app-spinner-button
                        buttonText="Generate"
                        [isProcessing]="isNumberGenerating"
                        [customClasses]="generateButtonCustomClasses"
                        (onButtonClick)="generate()"
                    ></app-spinner-button>
                    <!-- //. form-button: generate -->
                </div>
                <div class="col-12">
                    <!-- form-input: status -->
                    <app-select-input
                        [list]="contractStatus"
                        labelField="name"
                        bindField="name"
                        [formControl]="f['status']"
                        [label]="'Status'"
                        testAutomationSelectorName="contract_modal_select_status"
                    ></app-select-input>
                    <!-- //. form-input: status -->
                </div>
                <div class="col-12">
                    <!-- form-input: startDate -->
                    <app-text-input
                        [formControl]="f['startDate']"
                        [label]="'Start Date'"
                        type="date"
                        testAutomationSelectorName="contract_modal_input_start_date"
                    ></app-text-input>
                    <!-- //. form-input: startDate -->
                </div>
                <div class="col-12">
                    <!-- form-input: endDate -->
                    <app-text-input
                        [formControl]="f['endDate']"
                        [label]="'End Date'"
                        type="date"
                        testAutomationSelectorName="contract_modal_input_end_date"
                    ></app-text-input>
                    <!-- //. form-input: endDate -->
                </div>
                <div class="col-12">
                    <!-- form-input: type -->
                    <app-select-input
                        [list]="contractTypesSource$ | async"
                        [bindField]="'_id'"
                        [labelField]="'name'"
                        [addTag]="true"
                        [addTagCallback]="addType"
                        [formControl]="f['type']"
                        [label]="'Type'"
                        testAutomationSelectorName="contract_modal_select_type"
                    ></app-select-input>
                    <!-- //. form-input: type -->
                </div>
                <div class="col-12" *ngIf="this.entityType !== linkedEntityType.CONTRACT_AS_PROVIDER">
                    <!-- form-input: provider -->
                    <app-select-input
                        [formControl]="f['provider']"
                        [label]="'Provider'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="contract_modal_select_provider"
                    ></app-select-input>
                    <!-- //. form-input: provider -->
                </div>
                <div class="col-12" *ngIf="this.entityType !== linkedEntityType.CONTRACT_AS_RECIPIENT">
                    <!-- form-input: recipient -->
                    <app-select-input
                        [formControl]="f['recipient']"
                        [label]="'Recipient'"
                        [list]="companies$ | async"
                        [bindField]="'_id'"
                        [labelField]="'companyName'"
                        testAutomationSelectorName="contract_modal_select_recipient"
                    ></app-select-input>
                    <!-- //. form-input: recipient -->
                </div>
                <div class="col-12">
                    <!-- form-input: description -->
                    <app-text-area-input
                        [formControl]="f['description']"
                        [label]="'Description'"
                        testAutomationSelectorName="contract_modal_input_description"
                    ></app-text-area-input>
                    <!-- //. form-input: description -->
                </div>
            </div>
            <!-- //. row: form -->
        </form>
    </ng-scrollbar>
</div>

<div class="modal-footer fixed-bottom">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: create-new -->
                <app-spinner-button
                    buttonText="Create New"
                    [isProcessing]="isFormProcessing"
                    [customClasses]="primaryButtonCustomClasses"
                    [type]="'submit'"
                    (onButtonClick)="create()"
                ></app-spinner-button>
                <!-- //. form-button: create-new -->
                <!-- form-button: reset -->
                <app-spinner-button
                    buttonText="Clear"
                    [customClasses]="secondaryButtonCustomClasses"
                    (onButtonClick)="reset()"
                ></app-spinner-button>
                <!-- //. form-button: reset -->
            </div>
        </div>
    </div>
</div>
