// core
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, tap } from 'rxjs';

//states
import { ContractState, GetContracts } from '@data/state';

// enums
import { ContractDataTableCellType, DataTableActionType, DataTableCellType } from '@data/enum';

// schemas
import { IFormCustomClass, IButtonCustomClass, IDataTableInfo, IContract, IDataTablePaginationDTO } from '@data/schema';
import { ContractService } from '@data/service';

@Component({
    selector: 'app-link-contract-modal',
    templateUrl: './link-contract-modal.component.html',
    styleUrls: ['./link-contract-modal.component.scss'],
})
export class LinkContractModalComponent implements OnInit {
    dataTableSource$: Observable<IContract[]>;
    isDataLoading$ = new BehaviorSubject<boolean>(false);

    dataTableCols: DataTables.ColumnSettings[] = [
        { data: '_id', title: '', orderable: false, type: DataTableCellType.CHECKBOX_INPUT },
        { data: '_id', title: 'System UUID', visible: false },
        { data: 'contractNumber', title: 'Contract Number', type: ContractDataTableCellType.CONTRACT_NUMBER_CELL },
        { data: 'status', title: 'Status', type: DataTableCellType.STATUS_CELL },
        { data: 'provider.companyName', title: 'Provider', type: ContractDataTableCellType.PROVIDER_CELL },
        { data: 'recipient.companyName', title: 'Recipient', type: ContractDataTableCellType.RECIPIENT_CELL },
    ];

    dataTableLengthMenu = [
        [5, 10, 20, 50, 100],
        ['5 / page', '10 / page', '20 / page', '50 / page', '100 / page'],
    ];

    dataTableForm: FormGroup;

    dataTableCellType = DataTableCellType;
    dataTableActionType = DataTableActionType;
    contractDataTableCellType = ContractDataTableCellType;

    isFormProcessing = false;

    dataTableInfo: IDataTableInfo = {
        buttonTitle: 'Create New',
        infoLabel: 'items',
    };

    checkboxCustomClasses: IFormCustomClass = {
        formGroup: 'form-group m-0',
        label: 'font-weight-bold text-muted small mb-0',
        input: '',
    };

    primaryButtonCustomClasses: IButtonCustomClass = {
        button: 'btn btn-primary btn-sm float-end',
        spinner: 'spinner-border spinner-border-sm',
    };

    @Output() actionButtonEvent = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private contractService: ContractService,
    ) {
        this.initializeForm();
    }

    ngOnInit() {}

    dataTableServerSideCallbackFn = (pagination: IDataTablePaginationDTO) => {
        this.isDataLoading$.next(true);
        return this.contractService.getSSR(pagination).pipe(
            tap((response) => {
                this.addFormControls(response.data);
                this.isDataLoading$.next(false);
                this.isDataLoading$.complete();
            }),
        );
    };

    private initializeForm(): void {
        this.dataTableForm = this.formBuilder.group({
            items: this.formBuilder.array([]),
        });
    }

    private addFormControls(items: IContract[]): void {
        this.p.clear();

        items.forEach((item) => {
            this.p.push(this.createItemField(item));
        });
    }

    private createItemField(item: IContract): FormGroup {
        const itemField = this.formBuilder.group({
            _id: item._id,
            isSelected: false,
        });

        return itemField;
    }

    get f() {
        return this.dataTableForm.controls;
    }

    get p() {
        return this.f['items'] as FormArray;
    }

    onSelectAllCheckBoxValueChanges(flag: boolean) {
        const { items } = this.dataTableForm.getRawValue();

        items.forEach((formField, i) => {
            if (flag && !this.p.controls[i].get('isSelected').disabled) {
                this.p.controls[i].patchValue({ isSelected: true });
            } else {
                this.p.controls[i].patchValue({ isSelected: false });
            }
        });
    }

    onActionButtonClick($event) {
        const { items } = this.dataTableForm.getRawValue();
        const selectedItems = items.filter((item) => item.isSelected).map((item) => item._id);
        this.actionButtonEvent.emit({
            selectedIds: selectedItems,
            ...$event,
        });
    }
}
