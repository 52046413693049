<div class="modal-header fixed-top py-2 border-0 row flex-row justify-content-between align-items-center">
    <div class="col-9 d-flex justify-content-start">
        <h5 class="mb-0">Select from the following</h5>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <button type="button" class="btn btn-transparent" (click)="bsModalRef.hide()">
            <span class="bi bi-x-lg"></span>
        </button>
    </div>
    <hr class="m-0 mt-2" />
</div>

<div class="modal-body my-5 pt-4">
    <app-data-table
        *ngIf="(isDataLoading$ | async) === false"
        #dataTable
        [dataTableInfo]="dataTableInfo"
        [dataTableCols]="dataTableCols"
        [dataTableEnableServerSide]="true"
        [dataTableServerSideCallbackFn]="dataTableServerSideCallbackFn"
        [dataTableSourceLoading]="isDataLoading$ | async"
        [dataTableSelectAllCheckBoxEnabled]="true"
        [dataTableActionButtonEnabled]="false"
        [dataTableActionButtonProcessing]="isFormProcessing"
        [linkItemDataTableActionButtonEnabled]="true"
        [dataTableLengthMenu]="dataTableLengthMenu"
        testAutomationSelectorName="linked_item_table"
        (actionButtonEvent)="onActionButtonClick($event)"
        (selectAllCheckBoxEvent)="onSelectAllCheckBoxValueChanges($event)"
    >
        <!-- Cell override -->
        <ng-template ng-datatable-cell-tmp let-item="item" let-currentColumns="currentColumns">
            <td *ngFor="let column of currentColumns">
                <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="itemDataTableCellType.ITEM_NUMBER_CELL">
                        <a
                            class="mb-0 text-primary fw-medium cursor-pointer text-decoration-none"
                            [routerLink]="'/items/' + item?._id"
                            (click)="bsModalRef.hide()"
                        >
                            {{ dataTable.getColumnData(item, column) }}
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="itemDataTableCellType.MANUFACTURER_NAME_CELL">
                        <a
                            class="mb-0 text-primary fw-medium cursor-pointer text-decoration-none"
                            [routerLink]="'/companies/' + item?.manufacturer?._id"
                            (click)="bsModalRef.hide()"
                        >
                            {{ dataTable.getColumnData(item, column) }}
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="itemDataTableCellType.PURCHASER_NAME_CELL">
                        <a
                            class="mb-0 text-primary fw-medium cursor-pointer text-decoration-none"
                            [routerLink]="'/companies/' + item?.purchaser?._id"
                            (click)="bsModalRef.hide()"
                        >
                            {{ dataTable.getColumnData(item, column) }}
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="itemDataTableCellType.SUPPLIER_NAME_CELL">
                        <a
                            class="mb-0 text-primary fw-medium cursor-pointer text-decoration-none"
                            [routerLink]="'/companies/' + item?.supplier?._id"
                            (click)="bsModalRef.hide()"
                        >
                            {{ dataTable.getColumnData(item, column) }}
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="dataTableCellType.DATE_CELL">
                        <span class="text-nowrap">{{
                            dataTable.getColumnData(item, column) | date : 'yyyy-MM-dd'
                        }}</span>
                    </ng-container>

                    <ng-container *ngSwitchCase="dataTableCellType.CHECKBOX_INPUT">
                        <form [formGroup]="dataTableForm">
                            <div formArrayName="items" *ngFor="let bi of f['items']['controls']; let i = index">
                                <div [formGroupName]="i" *ngIf="p.controls[i]['controls']._id.value === item._id">
                                    <app-checkbox-input
                                        formControlName="isSelected"
                                        [label]=""
                                        [customClasses]="checkboxCustomClasses"
                                    ></app-checkbox-input>
                                </div>
                            </div>
                        </form>
                    </ng-container>

                    <ng-container *ngSwitchCase="dataTableCellType.DESCRIPTION_CELL">
                        <span
                            class="cursor-pointer"
                            [tooltip]="dataTable.getColumnData(item, column)"
                            placement="bottom"
                            triggers="click"
                            *ngIf="
                                dataTable.getColumnData(item, column).length > 30 &&
                                    (resizeService.onResize$ | async) < 4;
                                else defaultContentSm
                            "
                        >
                            {{ (dataTable.getColumnData(item, column) | slice : 0 : 30) + '&hellip;' }}
                        </span>

                        <ng-template #defaultContentSm>
                            <span *ngIf="(resizeService.onResize$ | async) < 4">
                                {{ dataTable.getColumnData(item, column) }}</span
                            >
                        </ng-template>

                        <span
                            class="cursor-pointer"
                            [tooltip]="dataTable.getColumnData(item, column)"
                            placement="bottom"
                            containerClass="tooltip-wide"
                            *ngIf="
                                dataTable.getColumnData(item, column).length > 60 &&
                                    (resizeService.onResize$ | async) > 3;
                                else defaultContentLg
                            "
                        >
                            {{ (dataTable.getColumnData(item, column) | slice : 0 : 60) + '&hellip;' }}
                        </span>

                        <ng-template #defaultContentLg>
                            <span *ngIf="(resizeService.onResize$ | async) > 3">
                                {{ dataTable.getColumnData(item, column) }}</span
                            >
                        </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{ dataTable.getColumnData(item, column) }}
                    </ng-container>
                </ng-container>
            </td>
        </ng-template>
        <!-- //. Cell override -->
    </app-data-table>
</div>

<div class="modal-footer">
    <hr class="m-0" />
    <div class="row">
        <div class="col-12 p-0">
            <div class="form-group">
                <!-- form-button: close -->
                <app-spinner-button
                    buttonText="Close"
                    [customClasses]="primaryButtonCustomClasses"
                    (onButtonClick)="bsModalRef.hide()"
                ></app-spinner-button>
                <!-- //. form-button: close -->
            </div>
        </div>
    </div>
</div>
